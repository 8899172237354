import { SearchOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { Badge } from '@mui/material';
import { Button } from 'app/components/Buttons/Button';
import { PAGE_URL_ECommerce } from 'app/util/constants/pageUrls/ecommerce';
import useWindowDimensions from 'app/util/useWindowDimensions';
import { getAuth } from 'firebase/auth';
import React from 'react';
import { useHistory } from 'react-router-dom';

interface IHeaderProps {
  className?: string;
  showSearchBar?: boolean;
  cartContent: number;
}

interface IHeaderButtonProps {
  name?: string;
  className: string;
  inverted?: boolean;
  children: React.ReactNode;
  onClick?: () => void;
}

const HeaderButton: React.FC<IHeaderButtonProps> = ({
  name,
  children,
  className,
  onClick,
}) => {
  return (
    <Button
      className={className}
      children={
        <div className="flex flex-row gap-6 items-center justify-around h-10 p-2">
          {children}
          {name && <span className="font-poppins text-lg">{name}</span>}
        </div>
      }
      type={'button'}
      onClick={onClick}
    />
  );
};

export const Header: React.FC<IHeaderProps> = ({
  className,
  showSearchBar,
  cartContent,
}) => {
  const { width } = useWindowDimensions();
  const history = useHistory();
  const auth = getAuth();
  const user = auth.currentUser;

  return (
    <div className="z-[90] left-0 right-0 fixed top-0 bg-white drop-shadow-md">
      <div className="container mx-auto flex flex-row justify-between items-center">
        <div className="gap-2 lg:gap-4 flex flex-row items-center">
          <div className="flex lg:gap-1">
            <div
              className="cursor-pointer ml-3 md:ml-5"
              onClick={() =>
                history.push({
                  pathname: PAGE_URL_ECommerce.landingPage,
                  state: {
                    from: history.location.pathname,
                  },
                })
              }
            >
              <img
                src="/images/logo.svg"
                alt=""
                className="scale-[0.6] md:scale-[0.8]"
              />
            </div>
            <div
              className="cursor-pointer"
              onClick={() => window.open('https://foodbank.sg', '_blank')}
            >
              {width >= 550 && width < 1000 ? (
                <img
                  src="images/foodbankLogo.png"
                  alt="absolute food bank logo"
                  className="scale-[0.7]"
                />
              ) : null}
              {width >= 1000 ? (
                <img
                  src="images/foodbankLogo.png"
                  alt="absolute food bank logo"
                  className="md:p-1 scale-[0.7] md:scale-y-[0.8] md:scale-x-[0.8]"
                />
              ) : null}
            </div>
          </div>
          {/* {showSearchBar ? (
            <div className="rounded-3xl w-96 h-12 gap-5 px-4 border-[0.7px] text-white border-dark-green flex flex-row items-center justify-left right-28">
              <SearchOutlined className="text-[30px] text-primary" />
              <input
                placeholder="Search for products..."
                className="text-primary outline-0 placeholder-primary"
              />
            </div>
          ) : (
            <></>
          )} */}
        </div>
        <div className="flex flex-row gap-[10px] sm:gap-[20px] lg:gap-[30px] mr-12 xs:mr-20 sm:mr-23 md:mr-20">
          {/* {(user === null && (
            <HeaderButton
              className="rounded-full lg:rounded-3xl !bg-primary text-white"
              name={width <= 640 ? undefined : 'Login'}
              onClick={() => {
                history.push({
                  pathname: PAGE_URL_ECommerce.loginPage,
                  state: {
                    from: history.location.pathname,
                  },
                });
              }}
            >
              <UserOutlined className="text-[20px] lg:text-[30px]" />
            </HeaderButton>
          )) || (
            <HeaderButton
              className="rounded-full lg:rounded-3xl !bg-primary text-white"
              name={width <= 640 ? undefined : 'Logout'}
              onClick={() => {
                history.push({
                  pathname: PAGE_URL_ECommerce.logoutPage,
                  state: {
                    from: history.location.pathname,
                  },
                });
              }}
            >
              <UserOutlined className="text-[20px] lg:text-[30px]" />
            </HeaderButton>
          )} */}
          {showSearchBar ? (
            <div className="rounded-3xl w-96 h-10 gap-5 px-4 border-[0.7px] text-white border-dark-green flex flex-row items-center justify-left right-28">
              <SearchOutlined className="text-[30px] text-primary" />
              <input
                placeholder="Search for products..."
                className="text-primary outline-0 placeholder-primary"
              />
            </div>
          ) : (
            <></>
          )}
          <Badge
            invisible={cartContent === 0}
            badgeContent={cartContent}
            sx={{
              '& .MuiBadge-badge': {
                color: 'white',
                backgroundColor: '#80B519',
                right: 6,
                top: 3,
              },
            }}
          >
            <HeaderButton
              className="text-primary border-primary border rounded-full lg:rounded-3xl !bg-white w-20 sm:w-36"
              name={width <= 629 ? undefined : 'Cart'}
              onClick={() => {
                history.push({
                  pathname: PAGE_URL_ECommerce.shoppingCartPage,
                  state: {
                    from: history.location.pathname,
                  },
                });
              }}
            >
              <ShoppingCartOutlined className="text-[20px] lg:text-[30px]" />
            </HeaderButton>
          </Badge>
        </div>
      </div>
    </div>
  );
};
