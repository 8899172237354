import React from 'react';

/**
 * this component is meant to be extensible and forms a basic skeleton for other info boxes
 * the background hex, speech bubble text and text are customizable
 */
interface IDidYouKnowProps {
  backgroundHex?: string;
  bubbleText?: string;
  text?: string;
}

const DEFAULT_BUBBLE_TEXT = 'Did you know?';
const DEFAULT_TEXT =
  '1 in 10 Singaporeans face food insecurity, and are unable to afford their basic three males at any point of their lives.';
const DEFAULT_BACKGROUND_HEX = 'FF9431';

export const DidYouKnow: React.FC<IDidYouKnowProps> = props => {
  return (
    <div
      className={`z-50 absolute w-[274px] h-[211px] rounded-[30px] bg-[#${
        props.backgroundHex || DEFAULT_BACKGROUND_HEX
      }]`}
    >
      <div className="z-60 absolute w-[15px] h-[15px] mt-[60px] ml-[13px] rounded-[25px] bg-white" />
      <div
        className={`z-70 absolute w-[15px] h-[15px] mt-[60px] ml-[5px] rounded-[5px] bg-[#${
          props.backgroundHex || DEFAULT_BACKGROUND_HEX
        }]`}
      />
      <div className="z-80 w-[224px] h-[52px] ml-[12px] mt-[18px] mb-[8px] rounded-[40px] bg-white">
        <p className="text-black font-semibold text-[24px] font-roboto ml-[28px] pt-[4px]">
          {props.bubbleText || DEFAULT_BUBBLE_TEXT}
        </p>
      </div>
      <p className="w-[242px] h-[112px] font-bond text-[14px] text-white ml-[16px]">
        {props.text || DEFAULT_TEXT}
      </p>
    </div>
  );
};
