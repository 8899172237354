import { Button } from 'app/components/Buttons/Button';
import { Input } from 'app/components/Forms/Input';
import { ConfirmationModalComponent } from 'app/components/Modals/ConfirmationModal/Loadable';
import {
  hasSpecialCharacters,
  isEmptyField,
} from 'app/util/validators/FormValidators';
import { isFormValid } from 'app/util/validators/ValidatorHelper';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from 'utils/axios';

interface IAdminUpdateVariantFormProps {
  className?: string;
}

type Variant = {
  name: string;
};

export const AdminUpdateVariantForm: React.FC<
  IAdminUpdateVariantFormProps
> = props => {
  const params: { id: string } = useParams();
  const cancelButtonRef = useRef(null);

  const [updatedVariant, setUpdatedVariant] = useState<Variant>({ name: '' });
  const [errorMessages, setErrorMessages] = useState<any>({});
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);

  //   const API_ENDPOINT = `${process.env.REACT_APP_PROXY_BACKEND_API_URL}/variant`;

  useEffect(() => {
    axiosInstance.get(`/variant/${params.id}`).then(res => {
      // food

      setUpdatedVariant({ ...updatedVariant, ...res.data.data });
    });
  }, []);

  const handleOnSubmit = async event => {
    event.preventDefault();
    const errros = {
      nameError:
        isEmptyField('Please Enter a Name', updatedVariant.name) ||
        hasSpecialCharacters(
          'Please Remove Any Special Characters',
          updatedVariant.name,
        ),
    };
    setErrorMessages(errros);

    if (!isFormValid(errros)) {
      return;
    }

    let reqBody = updatedVariant;

    Object.keys(reqBody).forEach(key => {
      if (reqBody[key] === '') {
        reqBody[key] = undefined;
      }
    });

    await axiosInstance.patch(`/variant/${params.id}`, reqBody).then(res => {
      setIsUpdateModalOpen(true);
    });
  };

  const handleChange = e => {
    const { value, name } = e.target;
    setUpdatedVariant(prev => ({ ...prev, [name]: value }));
  };

  return (
    <div className="flex flex-col items-center">
      <form
        className="flex flex-col gap-8 w-[50%] m-8"
        onSubmit={handleOnSubmit}
      >
        <Input
          title="Product Name"
          type="text"
          name="name"
          id="name"
          value={updatedVariant?.name}
          onChange={handleChange}
          required={true}
          error={errorMessages.nameError}
          isLabelHorizontal={true}
        />
        <div className="grid grid-cols-[1fr_2fr]">
          <div className="col-start-2 flex flex-row gap-1">
            <Button
              type="submit"
              className="items-center inline-block bg-primary h-[32px] rounded-[20px] text-[12px] text-white whitespace-nowrap mr-2 "
            >
              Submit
            </Button>
          </div>
        </div>
      </form>
      <ConfirmationModalComponent
        showState={isUpdateModalOpen}
        showStateController={setIsUpdateModalOpen}
        cancelButtonRef={cancelButtonRef}
        header={'You have successfully updated the variant name!'}
        iconStyles={'w-[22px] h-[22px] text-[#80B519] my-1 mr-3'}
        primaryButtonText={'OK'}
        primaryButtonStyles={
          'items-end bg-[#80B519] w-[72px] h-[32px] rounded-[20px] text-[12px] whitespace-nowrap'
        }
        buttonFunction={() => setIsUpdateModalOpen(false)}
        secondaryButtonStyles={
          'items-end bg-neutral-500 w-[72px] h-[32px] rounded-[20px] text-[12px] whitespace-nowrap mr-2'
        }
      />
    </div>
  );
};
