import { Button, Checkbox } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import * as React from 'react';

interface IInputDropdownMultiSelectProps {
  title: string;
  options: { label: string; value: any }[];
  onChange: (values: any[]) => void;
  values: any[];
}

export function InputDropdownMultiselect({
  title,
  onChange,
  options,
  values,
}: IInputDropdownMultiSelectProps) {
  const allValues = options.map(option => option.value);
  const [isSelectAllChecked, setIsSelectAllChecked] = React.useState(false);
  const handleChange = (event: SelectChangeEvent<typeof values>) => {
    const {
      target: { value },
    } = event;
    const selected = typeof value === 'string' ? value.split(',') : value;
    onChange(selected);
  };
  const handleSelectAllClicked = () => {
    if (isSelectAllChecked) {
      // deselect all
      onChange([]);
      setIsSelectAllChecked(false);
    } else {
      onChange(allValues);
      setIsSelectAllChecked(true);
    }
  };
  return (
    <div className="w-5/6 sm:w-3/6 grid grid-cols-4 gap-4 py-4">
      <div className="text-right col-span-1">{title}</div>
      <div className="col-span-3">
        <Select
          id="multi-select"
          multiple
          onChange={handleChange}
          value={values}
          style={{ width: '100%', height: '40px' }}
          input={<OutlinedInput />}
          renderValue={selected =>
            selected
              .map(val => options.find(item => item.value === val)?.label)
              .join(', ')
          }
        >
          <div style={{ textAlign: 'right' }}>
            <Button
              color="primary"
              size="small"
              onClick={handleSelectAllClicked}
            >
              {isSelectAllChecked ? 'Deselect All' : 'Select All'}
            </Button>
          </div>
          {options.map(option => {
            return (
              <MenuItem key={option.value} value={option.value}>
                <Checkbox checked={values.indexOf(option.value) > -1} />
                <ListItemText primary={option.label} />
              </MenuItem>
            );
          })}
        </Select>
      </div>
    </div>
  );
}
