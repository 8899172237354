import { useAuthValue } from 'app/AuthContext';
import { CheckoutStepComponent } from 'app/components/Ecommerce/Payment/CheckoutStep/Loadable';
import { CheckoutComponent } from 'app/pages/ECommerce/Payment/CheckoutPage/components/Checkout/Loadable';
import { CartSummaryComponent } from 'app/pages/ECommerce/Shop/ShoppingCartPage/components/ShoppingCart/CartSummary/Loadable';
import { cartDefaultKey, cartState } from 'app/state/shoppingCart/atoms';
import { PAGE_TITLES_ECommerce } from 'app/util/constants/pageTitles/ecommerce';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

export type CurrentStep = 1 | 2 | 3;
export function CheckoutPage() {
  const params: { step: string } = useParams();
  const { currentUserId } = useAuthValue()!;
  const cartItems = useRecoilValue(cartState(currentUserId || cartDefaultKey));
  const [currentStep, setCurrentStep] = React.useState<CurrentStep>(
    params.step === '2' ? 2 : params.step === '3' ? 3 : 1,
  );
  const [total, setTotal] = React.useState<number>(0);
  React.useEffect(() => {
    if (cartItems) {
      let sumTotal = 0;
      cartItems.forEach(cartItem => {
        sumTotal += cartItem.cartQuantity * cartItem.price;
      });
      setTotal(sumTotal);
    }
  }, [cartItems]);
  const stage = currentStep === 3 ? 'Payment' : 'Checkout';
  return (
    <>
      <Helmet>
        <title>{PAGE_TITLES_ECommerce.checkoutPage}</title>
        <meta name="description" content={PAGE_TITLES_ECommerce.checkoutPage} />
      </Helmet>
      <div
        className="flex flex-col justify-center min-h-screen p-2 font-poppins bg-repeat md:p-4 lg:p-8 xl:p-12"
        style={{ backgroundColor: '#009100' }}
      >
        <div className="h-full p-2 m-0 md:p-4 md:m-5 bg-white/80 rounded-xl lg:p-8 xl:p-12">
          <div className="grid grid-cols-12 gap-2 lg:gap-4 xl:gap-8">
            <div className="order-1 col-span-12 md:col-span-4 lg:col-span-3 xl:col-span-2">
              <CheckoutStepComponent stage={stage} />
            </div>
            <>
              {' '}
              <div className="order-2 col-span-12 md:order-3 lg:order-2 lg:col-span-5 xl:col-span-6">
                <CheckoutComponent
                  total={total}
                  items={cartItems}
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                />
              </div>
              <div className="order-3 col-span-12 md:order-2 lg:order-3 md:col-span-5 lg:col-span-4 xl:col-span-4">
                <CartSummaryComponent total={total} cartItems={cartItems} />
              </div>
            </>
          </div>
        </div>
      </div>
      {/* <Prompt message="Return to shopping cart? Changes done in checkout will be lost." /> */}
    </>
  );
}
