import { Fab } from '@mui/material';
import { AccordionComponent } from 'app/components/Accordion/Loadable';
import { LandingModalComponent } from 'app/components/Modals/LandingModal/Loadable';
import { FilteredCarousel } from 'app/pages/ECommerce/Landing/HomePage/components/ItemSectionComponent/components/ItemDisplayComponent';
import { PAGE_TITLES_ECommerce } from 'app/util/constants/pageTitles/ecommerce';
import React from 'react';
import { Helmet } from 'react-helmet-async';

export const template = (
  title,
  body,
  headingNumber,
  collapseNumber,
) => `<div className="bg-white border border-l-0 border-r-0 border-gray-200 rounded-none accordion-item">
    <h2 className="mb-0 accordion-header" id="flush-${headingNumber}">
      <button
        className="relative flex items-center w-full px-5 py-4 text-base text-left text-gray-800 transition bg-white border-0 rounded-none accordion-button  collapsed !text-black focus:outline-none"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#flush-${collapseNumber}"
        aria-expanded="false"
        aria-controls="flush-${collapseNumber}"
      >
        ${title}
      </button>
    </h2>
    <div
      id="flush-${collapseNumber}"
      className="border-0 accordion-collapse collapse"
      aria-labelledby="flush-${headingNumber}"
      data-bs-parent="#accordionFlushExample"
    >
      <div className="px-5 py-4 text-sm accordion-body text-justify">
        ${body}
      </div>
    </div>
  </div>`;

export const EcommerceLandingPage = () => {
  const [firstTime, setFirstTime] = React.useState(false);
  React.useEffect(() => {
    const checkFirstTime = localStorage.getItem('firstTime');
    if (checkFirstTime == null) {
      setFirstTime(true);
      localStorage.setItem('firstTime', 'false');
    }
  }, []);
  const text1 = `<p>1. Choose the items you want to donate<br/>2. Put them in your cart<br/>3. Check out and finish your donation<br/>4. You will receive an email with a receipt for your donation</p>`;
  const text2 = `Your donations are received by the <a href="https://foodbank.sg/">Food Bank Singapore (FBSG)</a>,
    a registered charity that serves as the coordinating organization for
      food donations in Singapore. Established in 2012, FBSG serves more than
      100,000 families and over 300,000 individuals in Singapore today. FBSG
      will use your donations to source the items of your choice wherever
      possible and deliver them to the local families in need.`;
  const text3 = `As Food Bank SG regularly distributes the food, beverages, and household
      necessities shown on this page to the local families in Singapore, your
      donations will be used toward the same goals—feeding the food-insecure
      individuals and families in Singapore. We will try our best to accommodate
      your unique donation preferences. However, occasionally, when the local
      families need resources that differ from those selected by our donors, we
      will prioritize helping people in the most needed ways.`;

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLES_ECommerce.landingPage}</title>
        <meta name="description" content={PAGE_TITLES_ECommerce.landingPage} />
      </Helmet>
      <LandingModalComponent
        showState={firstTime}
        showStateController={setFirstTime}
      />
      <div className="flex flex-col items-center relative">
        <img src="hero_1.svg" className="w-full"></img>
        <div className="w-[200px] sm:w-auto absolute bottom-1/2 right-1/2 translate-x-1/2 translate-y-1/2">
          <h1 className="font-bold text-xl md:text-3xl text-center">
            WELCOME TO FBSG MART FOR GOOD!
          </h1>
        </div>
      </div>
      <h2 className="m-6 text-xl sm:text-2xl md:text-3xl leading-8 text-center text-slate-700 font-semibold">
        GET STARTED
      </h2>
      <div className="container mx-auto">
        <AccordionComponent
          title="Terms and Conditions"
          content={[
            [template('How do I donate?', text1, 'headingTwo', 'collapseTwo')],
            [
              template(
                'Where will my donations go?',
                text2,
                'headingThree',
                'collapseThree',
              ),
            ],
            [
              template(
                'How will my donations be used?',
                text3,
                'headingFour',
                'collapseFour',
              ),
            ],
          ]}
        />
      </div>
      {/* <CarouselBanner />
      <HowWeWork /> */}
      <FilteredCarousel />
    </>
  );
};
