import { SearchOutlined } from '@ant-design/icons';
import useWindowDimensions from 'app/util/useWindowDimensions';
import { getProducts } from 'app/api/ProductService';
import React, { FC, SyntheticEvent, useEffect, useRef, useState } from 'react';
// import { Item } from 'app/models/Item';
import { Product } from 'app/models/Product';
import { useClickOutside } from 'utils/refs/useClickOutside';
interface ISearchBarProps {
  variantId: string;
  onSearch: (search: string) => void;
}
export const EcommerceSearchBar: React.FC<ISearchBarProps> = ({
  onSearch,
  variantId,
}) => {
  const [isResultsOpen, setIsResultsOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  // const [items, setItems] = useState<Product[]>();
  const resultRef = useRef(null);
  const { width } = useWindowDimensions();

  const handleSearch = (e: SyntheticEvent) => {
    e.preventDefault();
    let target = e.target as HTMLInputElement;
    setSearch(target.value);
  };
  useClickOutside(resultRef, () => {
    setIsResultsOpen(false);
  });

  useEffect(() => {
    // if (!search) {
    //   setIsResultsOpen(false);
    //   return;
    // }

    // Add a delay of 0.5s to prevent excessive calls
    const timeOut = setTimeout(() => {
      // getProducts({
      //   status: 'ACTIVE',
      //   variant_ids: [variantId],
      //   name: search,
      // }).then(res => {
      //   setItems(res.data);
      //   setIsResultsOpen(true);
      // });
      onSearch(search);
    }, 500);
    return () => clearTimeout(timeOut);
  }, [search]);

  return (
    <>
      <div className="relative w-full xl:w-1/3">
        <div className="flex flex-row justify-between items-center">
          {width > 350 ? (
            <input
              type={'text'}
              onChange={handleSearch}
              placeholder="Search for products"
              className="h-10 flex-grow border border-slate-100 w-20 xxs:w-24 md:w-52 relative text-primary border-primary rounded-full pl-10 drop-shadow"
            ></input>
          ) : (
            <input
              type={'text'}
              onChange={handleSearch}
              placeholder=""
              className="h-10 flex-grow border border-slate-100 w-14 relative text-primary border-primary rounded-full drop-shadow"
            ></input>
          )}
          <SearchOutlined
            className="absolute left-4 text-primary"
            onClick={() => setIsResultsOpen(true)}
          ></SearchOutlined>
        </div>
        {/* <div
          ref={resultRef}
          className={
            'absolute bg-white w-full border-x border-slate ' +
            (isResultsOpen ? 'block' : 'hidden')
          }
        >
          <div className="text-bold bg-slate-100 p-1">Products</div>
          <div>
            {items?.length ? (
              items.map(item => <Result item={item} search={search!}></Result>)
            ) : (
              <div className="p-4">No Results</div>
            )}
          </div>
          <div className="bg-slate-100 text-blue p-1 border-b border-slate">
            View all search results for {search}
          </div>
        </div> */}
      </div>
    </>
  );
};

const Result: FC<{ item: Product; search: string }> = ({ item, search }) => {
  const [price, setPrice] = useState<string>();

  useEffect(() => {
    // convert item price to a string
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'SGD',
    });

    setPrice(formatter.format(item.price!));
  }, [item]);

  return (
    <div className="grid grid-cols-[1fr_2fr_1fr] gap-4 border-b">
      <div className="w-full aspect-[1/1]">
        {item.images?.length ? (
          <img
            className="w-full h-full object-cover"
            src={`https://nus-gdsc-vs-dev.s3.ap-southeast-1.amazonaws.com/uploads/item/${item.id}/${item.images[0]}`}
          ></img>
        ) : (
          <>No img</>
        )}
      </div>
      <div className="py-4">{item.name}</div>
      <div className="flex flex-row justify-end items-center text-bold text-lg pr-4">
        {price}
      </div>
    </div>
  );
};
