import { UserOutlined } from '@ant-design/icons';
import {
  Button,
  CANCEL_BUTTON_CLASSES,
  CONFIRM_BUTTON_CLASSES,
} from 'app/components/Buttons/Button';
import { InputWithIcon } from 'app/components/Forms/InputWithIcon';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ConfirmationModalComponent } from 'app/components/Modals/ConfirmationModal/Loadable';
import { PAGE_URL_ADMIN_PORTAL } from 'app/util/constants/pageUrls/adminPortal';

type ForgotPasswordFormProps = {
  className?: string;
};

export const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({
  className,
}) => {
  const [email, setEmail] = React.useState<string>('');
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [errorMessages, setErrorMessages] = React.useState<any>({});
  const history = useHistory();

  const validateInputs = async () => {
    const auth = getAuth();
    console.error(errorMessages);
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(email)) {
      setErrorMessages({
        emailError: 'The email you have entered is not valid.',
        setEmailError: 'Invalid Email',
      });
      return;
    } else {
      await sendPasswordResetEmail(auth, email)
        .then(() => {
          setIsModalOpen(true);
        })
        .catch(() => {
          setErrorMessages({
            emailError: 'The email you have entered is not registered.',
            setEmailError: 'Invalid Email',
          });
          console.error(errorMessages);
          return;
        });
    }
  };

  const closeModal = () => {
    setErrorMessages({
      emailError: '',
      setEmailError: '',
    });
    setIsModalOpen(false);
    history.push({
      pathname: PAGE_URL_ADMIN_PORTAL.accountManagement.loginPage,
      state: {
        from: history.location.pathname,
      },
    });
  };
  return (
    <div className="h-[100vh] font-medium tracking-wide flex flex-col items-center justify-center">
      <div className="w-96 gap-6 font-medium tracking-wide text-black flex flex-col items-center justify-center">
        <div className="flex border-b-1 border-black/[.06] py-2 w-full">
          <span className="text-primary text-sm py-1 border-b-2 border-primary">
            Forgot Password
          </span>
        </div>
        <InputWithIcon
          icon={UserOutlined}
          errorText={errorMessages.emailError}
          type="text"
          placeholder="johnsnow@got.com"
          onChange={e => {
            setEmail(e.target.value);
          }}
        />
        <div className="flex w-full gap-6">
          <Button
            children={<>Verify</>}
            onClick={() => validateInputs()}
            className={CONFIRM_BUTTON_CLASSES}
          />
          <Button
            children={<div className="text-white">Back</div>}
            onClick={() =>
              history.push({
                pathname: PAGE_URL_ADMIN_PORTAL.accountManagement.loginPage,
                state: {
                  from: history.location.pathname,
                },
              })
            }
            type={'button'}
            className={CANCEL_BUTTON_CLASSES}
          />
        </div>
      </div>
      <ConfirmationModalComponent
        showState={isModalOpen}
        showStateController={setIsModalOpen}
        header={'Check your email'}
        iconStyles={'w-[30px] h-[30px] style={{color: "#19C200"}} my-1 mr-3'}
        description={
          'An email with your new password has been sent to the verified email. You may want to change your password after logging in.'
        }
        primaryButtonText={'OK'}
        primaryButtonStyles={
          'items-end bg-primary w-[72px] h-[32px] rounded-[20px] text-[12px] whitespace-nowrap'
        }
        buttonFunction={() => closeModal()}
        secondaryButtonStyles={
          'items-end bg-neutral-500 w-[72px] h-[32px] rounded-[20px] text-[12px] whitespace-nowrap mr-2'
        }
      />
    </div>
  );
};
