export const isEmptyField = (
  errorMessage: string,
  value?: string,
): string | undefined => {
  return value === '' || value === undefined ? errorMessage : undefined;
};

export const hasSpecialCharacters = (errorMessage: string, value?: string) => {
  if (!value) return undefined;
  return /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(value)
    ? errorMessage
    : undefined;
};

export const hasLessThanMin = (
  errorMessage: string,
  min: number,
  value?: number,
) => {
  if (value == undefined) return undefined;
  console.log(value);
  return value < min ? errorMessage : undefined;
};

export const hasAlphbetCharacters = (errorMessage: string, value?: string) => {
  if (!value) return undefined;
  return /^[a-zA-Z]+$/.test(value) ? errorMessage : undefined;
};

export const hasFiles = (errorMessage: string, files?: File[]) => {
  if (!files) return undefined;
  return files.length === 0 ? errorMessage : undefined;
};
export const validateEmail = (errorMessage: string, input: string) => {
  var validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  if (input.match(validRegex)) {
    return undefined;
  } else {
    return errorMessage;
  }
};

export const nricValidator = (errorMessage: string, nricInput: string) => {
  // validation rules
  const nricRegex = /(\D)(\d{7})(\D)/;
  const nricTypeRegex = /S|T|F|G/;
  const weightArr = [2, 7, 6, 5, 4, 3, 2];
  const nricLetterST = ['J', 'Z', 'I', 'H', 'G', 'F', 'E', 'D', 'C', 'B', 'A'];
  const nricLetterFG = ['X', 'W', 'U', 'T', 'R', 'Q', 'P', 'N', 'M', 'L', 'K'];
  if (!nricInput) {
    return errorMessage;
  }
  // set nric to all uppercase to remove case sensitivity
  const nric = nricInput.toUpperCase();

  // returns false if it false basic validation
  if (!nricRegex.exec(nric)) {
    return errorMessage;
  }

  // get nric type
  const nricArr = nric.match(nricRegex);
  if (!nricArr) {
    return errorMessage;
  }
  const nricType = nricArr[1];

  // returns false if it false basic validation
  if (!nricTypeRegex.exec(nricType)) {
    return false;
  }

  // multiply each digit in the nric number by it's weight in order
  const nricDigitsArr = nricArr[2].split('');
  let total = 0;
  for (let i = 0; i < nricDigitsArr.length; i++) {
    total += parseInt(nricDigitsArr[i]) * weightArr[i];
  }

  // if the nric type is T or G, add 4 to the total
  if (['T', 'G'].indexOf(nricType) >= 0) {
    total += 4;
  }

  // check last letter of nric for local
  const letterIndex = total % 11;
  const nricLetter = nricArr[3];
  if (['S', 'T'].indexOf(nricType) >= 0) {
    return nricLetterST[letterIndex] === nricLetter ? undefined : errorMessage;
  }

  // check last letter of nric for foreigners
  return nricLetterFG[letterIndex] === nricLetter ? undefined : errorMessage;
};
