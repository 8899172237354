import { CustomBreadcrumb } from 'app/components/Navigation/BreadCrumbs';
import { AdminCreateFoodForm } from 'app/pages/Admin/Product/Create/CreateProductPage/components/AdminCreateFoodForm';
import { PAGE_TITLES_ADMIN_PORTAL } from 'app/util/constants/pageTitles/adminPortal';
import { PAGE_URL_ADMIN_PORTAL } from 'app/util/constants/pageUrls/adminPortal';
import { normaliseVariantId, VariantIds } from 'app/VariantContext';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

export function CreateFoodPage() {
  const params: { variantId: VariantIds } = useParams();

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLES_ADMIN_PORTAL.productManagement.createPage}</title>
        <meta
          name="description"
          content={PAGE_TITLES_ADMIN_PORTAL.productManagement.createPage}
        />
      </Helmet>
      <CustomBreadcrumb
        data={[
          { label: `Variant ${normaliseVariantId(params.variantId)}` },
          {
            label: `Product`,
            link: PAGE_URL_ADMIN_PORTAL.productManagement.viewPage(
              params.variantId,
            ),
          },
          {
            label: `Add Product`,
            link: PAGE_URL_ADMIN_PORTAL.productManagement.createPage(
              params.variantId,
            ),
            isCurrentPage: true,
          },
        ]}
      />
      <AdminCreateFoodForm></AdminCreateFoodForm>
    </>
  );
}
