import { FailurePaymentBox } from 'app/components/Ecommerce/PaymentBox/Failure';
import { PAGE_TITLES_ECommerce } from 'app/util/constants/pageTitles/ecommerce';
import React from 'react';
import { Helmet } from 'react-helmet-async';

export function ECommercePaymentFailurePage() {
  return (
    <>
      <Helmet>
        <title>{PAGE_TITLES_ECommerce.paymentFailurePage}</title>
        <meta
          name="description"
          content={PAGE_TITLES_ECommerce.paymentFailurePage}
        />
      </Helmet>
      <div className="grid justify-items-center min-h-screen py-4 md:py-12 lg:py-16 xl:py-24 bg-neutral-500">
        <FailurePaymentBox />
      </div>
    </>
  );
}
