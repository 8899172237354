import { CustomBreadcrumb } from 'app/components/Navigation/BreadCrumbs';
import { ImportProductComponent } from 'app/pages/Admin/Product/Create/ImportProductPage/components/ImportProduct/Loadable';
import { PAGE_TITLES_ADMIN_PORTAL } from 'app/util/constants/pageTitles/adminPortal';
import { normaliseVariantId, VariantIds } from 'app/VariantContext';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { PAGE_URL_ADMIN_PORTAL } from 'app/util/constants/pageUrls/adminPortal';
export function ImportProductPage() {
  let { variantId } = useParams<{ variantId: VariantIds }>();
  return (
    <>
      <Helmet>
        <title>{PAGE_TITLES_ADMIN_PORTAL.productManagement.importPage}</title>
        <meta
          name="description"
          content={PAGE_TITLES_ADMIN_PORTAL.productManagement.importPage}
        />
      </Helmet>
      <CustomBreadcrumb
        data={[
          {
            label: `Variant ${normaliseVariantId(variantId)}`,
          },
          {
            label: 'Import Data',
            link: PAGE_URL_ADMIN_PORTAL.productManagement.uploadPage(variantId),
            isCurrentPage: true,
          },
        ]}
      />
      <div className="p-4">
        <ImportProductComponent />
      </div>
    </>
  );
}
