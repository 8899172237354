/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */
import '../tailwind.css';

import { FirebaseApp, initializeApp } from 'firebase/app';
import { Auth, User, getAuth, onAuthStateChanged } from 'firebase/auth';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import { AuthProvider } from './AuthContext';
import { MainSwitch } from './components/Switches';
import ReactGA4 from 'react-ga4';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app: FirebaseApp = initializeApp(firebaseConfig);
const auth: Auth = getAuth(app);

// debug mode enabled, get the chrome extension to debug if you want to
// top level initialization, tried abstracting to a separate file and exporting but could not work
ReactGA4.initialize(process.env.REACT_APP_GA_ID!, {
  gaOptions: {
    debug_mode: true,
  },
  gtagOptions: {
    debug_mode: true,
  },
});

export function App() {
  const { i18n } = useTranslation();
  const [currentUser, setCurrentUser] = React.useState<User | null>(null);
  const [currentUserId, setCurrentUserId] = React.useState<string | null>(null);

  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      if (user) {
        setCurrentUser(user);
        user.getIdToken().then(idToken => setCurrentUserId(idToken));
      }
    });

    return () => unsubscribe();
  }, []);
  return (
    <BrowserRouter>
      <React.StrictMode>
        <RecoilRoot>
          <AuthProvider value={{ currentUser, currentUserId, auth }}>
            <Helmet
              titleTemplate="%s - Virtual Supermarket"
              defaultTitle="Virtual Supermarket"
              htmlAttributes={{ lang: i18n.language }}
            >
              <meta name="description" content="Virtual Supermarket" />
              <meta name="version" content="1.3.4" />
              <meta
                name="google-site-verification"
                content="xSidsx4UniXW14Rh0Z45Hvkscb1eLCl29LGE2Tahne0"
              />
            </Helmet>
            <MainSwitch />
            {/* <Switch>
              <Route
                exact
                path={PAGE_URL_ADMIN_PORTAL.accountManagement.loginPage}
                component={LoginUserPage}
              />
              <Route
                exact
                path={PAGE_URL_ADMIN_PORTAL.accountManagement.firstLoginPage}
                component={FirstLoginUserPage}
              />
              <AuthGuard
                path="/admin"
                Component={AdminPortalSwitch}
                userTypesRequired={[userTypes.FOODBANK, userTypes.RESEARCH]}
              ></AuthGuard>
              <Route path="" component={ECommerceSwitch} />
            </Switch> */}
          </AuthProvider>
        </RecoilRoot>
      </React.StrictMode>
    </BrowserRouter>
  );
}
