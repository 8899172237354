import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { deleteObject, uploadObject } from 'app/api/AWSService';
import { getCategories } from 'app/api/CategoryService';
import { getProduct, updateProduct } from 'app/api/ProductService';
import { getVariant } from 'app/api/VariantService';
import { Button } from 'app/components/Buttons/Button';
import { Input } from 'app/components/Forms/Input';
import { InputSelectComponent } from 'app/components/Forms/InputSelect';
import { InputTextAreaComponent } from 'app/components/Forms/InputTextArea';
import { ConfirmationModalComponent } from 'app/components/Modals/ConfirmationModal/Loadable';
import { Product } from 'app/models/Product';
import { PAGE_URL_ADMIN_PORTAL } from 'app/util/constants/pageUrls/adminPortal';
import {
  isFileSizeValid,
  isImageBigger,
} from 'app/util/validators/FileValidators';
import {
  hasAlphbetCharacters,
  hasLessThanMin,
  hasSpecialCharacters,
  isEmptyField,
} from 'app/util/validators/FormValidators';
import { isFormValid } from 'app/util/validators/ValidatorHelper';
import { VariantIds } from 'app/VariantContext';
import React, { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

interface IAdminEditProductFormProps {
  className?: string;
}

type Variant = {
  name: string;
};

type Category = {
  code: number;
  name: string;
  nature: 'FOOD' | 'NONFOOD';
};

type Item = {
  name?: string;
  description?: string;
  price: string;
  status?: 'ACTIVE' | 'INACTIVE';
  weight: string;
  categoryCode?: string;
  targetQuantity: string;
  productCode?: string;
  images?: string[];
  skuCode?: string;
};

export const AdminEditProductForm: React.FC<IAdminEditProductFormProps> = ({
  className,
}) => {
  const params: { variantId?: VariantIds; itemId?: string } = useParams();
  const history = useHistory();

  const [newFood, setNewFood] = React.useState<Product | any>({
    name: '',
    skuCode: '',
    description: '',
    price: '1',
    targetQuantity: '0',
    images: [],
    status: 'ACTIVE',
    weight: '0',
    rank: '1',
    categoryCode: undefined,
  });
  const [categories, setCategories] = React.useState<Category[]>([]);
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
  const [variant, setVariant] = useState<Variant>();
  const [selectedCategory, setSelectedCategory] = useState<Category>();
  const [errorMessages, setErrorMessages] = React.useState<any>({});
  const [imageFiles, setImageFiles] = React.useState<File[]>([]);
  const [existingImagesUrl, setExistingImagesUrl] = React.useState<string[]>(
    [],
  );
  const [deletedExistingImagesUrl, setDeletedExistingImagesUrl] =
    React.useState<string[]>([]);

  // modals
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState<boolean>(false);
  const [isCancelModalOpen, setIsCancelModalOpen] =
    React.useState<boolean>(false);
  const cancelButtonRef = useRef(null);

  useEffect(() => {
    getVariant(params.variantId!)
      .then(res => {
        setVariant(res);
      })
      .catch(() => {
        history.push({
          pathname: PAGE_URL_ADMIN_PORTAL.errorPage,
          state: {
            from: history.location.pathname,
          },
        });
      });

    const fetchCategories = async () => {
      const categories = (
        await getCategories({
          variantIds: [params.variantId!],
        })
      ).data.map(cat => {
        return {
          code: cat.categoryCode,
          name: cat.name,
          nature: cat.nature,
        };
      });
      setCategories(categories);
    };

    const fetchProduct = async () => {
      try {
        const product = await getProduct(+params.itemId!);
        setNewFood(product);
        let imgData: string[] = [];
        if (!product || !product.images) return;
        for (let imgName of product.images) {
          const imgUrl = `${process.env.REACT_APP_S3_URL}uploads/product/${
            params.itemId
          }/${imgName.replace(' ', '+')}`;
          imgData.push(imgUrl);
        }
        setExistingImagesUrl(imgData);
      } catch (e) {
        history.push({
          pathname: PAGE_URL_ADMIN_PORTAL.errorPage,
          state: {
            from: history.location.pathname,
          },
        });
      }
    };
    fetchProduct();
    fetchCategories();
  }, []);

  useEffect(() => {
    for (let cat of categories) {
      if (cat.code === newFood.categoryCode) {
        setSelectedCategory(cat);
      }
    }
  }, [categories, newFood]);

  const onFieldChangeHandler = e => {
    let { value, name } = e.target;
    if (name === 'categoryCode') {
      for (let category of categories) {
        if (category.code === +value) {
          setSelectedCategory(category);
        }
      }
    }

    setNewFood(prev => ({ ...prev, [name]: value }));
  };

  const onImageFilesChangeHandler = (files: any[]) => {
    setImageFiles(files);
  };

  const onExistingImagesUrlChange = (urls: any[]) => {
    setExistingImagesUrl(urls);
  };

  const onDeletedExistingImagesUrlChange = (deletedUrls: any[]) => {
    setDeletedExistingImagesUrl(deletedUrls);
  };

  const onFormSubmitHandler = e => {
    e.preventDefault();

    setIsFormDirty(true);

    // check for invalid inputs
    setErrorMessages({
      categoryCodeError: isEmptyField(
        'Please Select a Category',
        newFood.categoryCode,
      ),
      nameError: isEmptyField('Please Enter a Name', newFood.name),
      descriptionError: isEmptyField(
        'Please Enter a Description',
        newFood.description,
      ),
      skuCodeError:
        hasSpecialCharacters(
          'Please Remove Any Special Characters',
          newFood.skuCode,
        ) ||
        hasAlphbetCharacters('Please Remove Any Alphabets', newFood.skuCode),
      priceError:
        isEmptyField('Please Enter a Price', newFood.price) ||
        hasLessThanMin('Please input a positive number', 0, +newFood.price),
      quantityError:
        hasSpecialCharacters(
          'Please enter a whole number',
          newFood.targetQuantity,
        ) ||
        hasAlphbetCharacters(
          'Please remove any alphabets',
          newFood.targetQuantity,
        ) ||
        hasLessThanMin(
          'Please enter a positive target quantity',
          0,
          +(newFood?.targetQuantity ?? 0),
        ),
      rankError:
        hasLessThanMin(
          'Please enter a 0 or a positive number',
          0,
          +newFood.rank!,
        ) || hasAlphbetCharacters('Please remove any alphabets', newFood.rank),
      imageError:
        imageFiles.length + existingImagesUrl.length <= 0
          ? 'Please upload at least one image'
          : undefined,
    });
  };

  const uploadAndUpdate = async (newItemId: number) => {
    const _imageFileNames: string[] = [];
    // add existing images to image array
    for (let imgUrl of existingImagesUrl) {
      const imgUrlArray = imgUrl.split('/');
      const imgName = imgUrlArray[6].replace('+', ' ');
      _imageFileNames.push(imgName);
    }
    // upload each file and update the item
    for (let image of imageFiles) {
      try {
        uploadObject(`uploads/product/${newItemId}/`, image);
        _imageFileNames.push(image.name);
      } catch (e) {
        console.error('An error occurred uploading file', e);
      }
    }

    try {
      await updateProduct(+newItemId, { images: _imageFileNames });
    } catch (e) {
      console.error("An error occurred updating the item's images array", e);
    }
  };

  const deleteExistingImagesThatAreRemoved = async (newItemId: number) => {
    for (let imgUrl of deletedExistingImagesUrl) {
      const imgUrlArray = imgUrl.split('/');
      try {
        await deleteObject(
          `uploads/product/${newItemId}/${imgUrlArray[6].replace('+', ' ')}`,
        );
      } catch (e) {
        console.error('An error occurred deleting existing files', e);
      } finally {
        let dlt = deletedExistingImagesUrl.splice(
          0,
          deletedExistingImagesUrl.length,
        );
        setDeletedExistingImagesUrl(dlt);
      }
    }
  };

  const updateItem = async () => {
    let request = {
      ...newFood,
      category: undefined,
      name: newFood.name,
      skuCode: newFood.skuCode,
      description: newFood.description,
      price: Number(newFood.price),
      targetQuantity: Number(newFood.targetQuantity),
      status: newFood.status,
      weight: newFood.weight,
      images: [],
      rank: +newFood.rank,
      categoryCode: Number(newFood.categoryCode),
    };

    const _productId = (await updateProduct(+params.itemId!, request)).id;

    deleteExistingImagesThatAreRemoved(_productId).then(() => {
      uploadAndUpdate(_productId).then(() => {
        history.push({
          pathname: PAGE_URL_ADMIN_PORTAL.productManagement.viewPage(
            params.variantId!,
          ),
          state: {
            from: history.location.pathname,
          },
        });
      });
    });
  };
  useEffect(() => {
    if (isFormValid(errorMessages) && isFormDirty) {
      // submit to backend
      setIsUpdateModalOpen(true);
    } else {
      console.error(errorMessages);
    }
  }, [errorMessages]);

  return (
    <>
      <div className="flex flex-col items-center">
        {/* <div className="border-solid border-b w-full border-gray-100 ">
          <div className="m-4 text-gray font-medium text-lg">
            {variant?.name} / Products /
            <span className="text-primary"> Edit Product</span>
          </div>
        </div> */}
        <form className="flex flex-col gap-8 w-[50%] m-8">
          <ImageSectionComponent
            onImageFilesChange={onImageFilesChangeHandler}
            onExistingImagesUrlChange={onExistingImagesUrlChange}
            onDeletedExistingImagesUrlChange={onDeletedExistingImagesUrlChange}
            images={existingImagesUrl}
            deletedImagesUrl={deletedExistingImagesUrl}
            params={params}
            formErrorMessage={errorMessages.imageError}
          ></ImageSectionComponent>
          <div className="grid grid-cols-[1fr_2fr] gap-x-4 items-center">
            <div className="justify-self-end">Nature:</div>
            <div>
              {selectedCategory?.nature === undefined
                ? ''
                : selectedCategory?.nature === 'FOOD'
                ? 'Food'
                : 'Non-Food'}
            </div>
          </div>

          <InputSelectComponent
            name="categoryCode"
            id="categoryCode"
            label="Category:"
            required={true}
            value={newFood.categoryCode}
            isLabelHorizontal={true}
            onChange={onFieldChangeHandler}
            options={categories.map(cat => {
              return { value: cat.code, label: cat.name };
            })}
            error={errorMessages.categoryCodeError}
          ></InputSelectComponent>
          <Input
            title="Catalog Code"
            type="text"
            name="catalogCode"
            id="catalogCode"
            value={selectedCategory?.code}
            placeholder="F1001"
            required={false}
            disabled={true}
            isLabelHorizontal={true}
            maxLength={40}
          />
          <Input
            title="Product Name"
            type="text"
            name="name"
            id="name"
            value={newFood.name}
            onChange={onFieldChangeHandler}
            placeholder="Tasty Rice"
            required={true}
            error={errorMessages.nameError}
            showError={errorMessages.nameError !== ''}
            isLabelHorizontal={true}
            maxLength={40}
          />
          <Input
            title="Warehouse SKU Code"
            type="number"
            name="skuCode"
            id="skuCode"
            value={newFood.skuCode}
            onChange={onFieldChangeHandler}
            placeholder="SKU Code"
            required={false}
            error={errorMessages.skuCodeError}
            showError={errorMessages.skuCodeError !== ''}
            isLabelHorizontal={true}
            maxLength={40}
          />
          <InputTextAreaComponent
            value={newFood.description}
            label={'Description'}
            required={true}
            onChange={onFieldChangeHandler}
            error={errorMessages.descriptionError}
            isLabelHorizontal={true}
          ></InputTextAreaComponent>
          <Input
            title="Set Price "
            type="number"
            name="price"
            id="price"
            min={1}
            required={true}
            value={newFood.price}
            onChange={onFieldChangeHandler}
            placeholder="1"
            error={errorMessages.priceError}
            showError={errorMessages.priceError !== ''}
            isLabelHorizontal={true}
          />
          <Input
            title="Rank "
            type="number"
            name="rank"
            id="rank"
            min={0}
            required={true}
            value={newFood.rank}
            onChange={onFieldChangeHandler}
            placeholder="0"
            error={errorMessages.rankError}
            showError={errorMessages.rankError !== ''}
            isLabelHorizontal={true}
          />
          <Input
            title="Target Quantity "
            type="number"
            name="targetQuantity"
            id="targetQuantity"
            min={0}
            value={newFood.targetQuantity}
            onChange={onFieldChangeHandler}
            placeholder="1"
            error={errorMessages.quantityError}
            showError={errorMessages.quantityError !== ''}
            isLabelHorizontal={true}
          />
          <InputSelectComponent
            name="status"
            id="status"
            label="Status :"
            value={newFood.status}
            onChange={onFieldChangeHandler}
            isLabelHorizontal={true}
            options={[
              { label: 'Active', value: 'ACTIVE' },
              { label: 'Inactive', value: 'INACTIVE' },
            ]}
            required={true}
          ></InputSelectComponent>
          <div className="grid grid-cols-[1fr_2fr]">
            <div className="col-start-2 flex flex-row gap-1">
              <Button
                type="submit"
                onClick={onFormSubmitHandler}
                className="inline-block items-end bg-primary w-[72px] h-[32px] rounded-[20px] text-[12px] whitespace-nowrap mr-2 "
              >
                Save
              </Button>
              <Button
                type="button"
                onClick={() => setIsCancelModalOpen(true)}
                className="inline-block items-end bg-neutral-500 w h-[32px] rounded-[20px] text-[12px] whitespace-nowrap mr-2"
              >
                Cancel
              </Button>
            </div>
          </div>
        </form>
      </div>
      <ConfirmationModalComponent
        showState={isUpdateModalOpen}
        showStateController={setIsUpdateModalOpen}
        cancelButtonRef={cancelButtonRef}
        header={'Would you like to save your changes?'}
        iconStyles={'w-[22px] h-[22px] text-primary my-1 mr-3'}
        primaryButtonText={'Save'}
        secondaryButtonText="Back"
        primaryButtonStyles={
          'items-end bg-primary w-[72px] h-[32px] rounded-[20px] text-[12px] text-white whitespace-nowrap'
        }
        buttonFunction={() => updateItem()}
        secondaryButtonStyles={
          'items-end bg-neutral-500 w-[72px] h-[32px] rounded-[20px] text-[12px] text-white whitespace-nowrap mr-2'
        }
      />
      <ConfirmationModalComponent
        showState={isCancelModalOpen}
        showStateController={setIsCancelModalOpen}
        cancelButtonRef={cancelButtonRef}
        header={'You are cancelling your current edits.'}
        description="Changes you've made will not be saved"
        iconStyles={'w-[22px] h-[22px] text-slate my-1 mr-3'}
        primaryButtonText={'Cancel'}
        primaryButtonStyles={
          'items-end bg-neutral-600 w-[72px] h-[32px] rounded-[20px] text-[12px] text-white whitespace-nowrap'
        }
        buttonFunction={() => {
          history.push({
            pathname: PAGE_URL_ADMIN_PORTAL.productManagement.viewPage(
              params.variantId!,
            ),
            state: {
              from: history.location.pathname,
            },
          });
        }}
        secondaryButtonText="Back"
        secondaryButtonStyles={
          'items-end bg-neutral-500 w-[72px] h-[32px] rounded-[20px] text-[12px] text-white whitespace-nowrap mr-2'
        }
      />
    </>
  );
};

interface IImageSectionProps {
  onImageFilesChange: Function;
  onExistingImagesUrlChange: Function;
  onDeletedExistingImagesUrlChange: Function;
  images: string[];
  deletedImagesUrl: string[];
  params: { variantId?: string; itemId?: string };
  formErrorMessage: string;
}

const ImageSectionComponent: React.FC<IImageSectionProps> = ({
  onImageFilesChange,
  onExistingImagesUrlChange,
  onDeletedExistingImagesUrlChange,
  images,
  deletedImagesUrl,
  formErrorMessage,
}) => {
  const [mainErrorMessage, setMainErrorMessage] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [imageFiles, setImageFiles] = useState<any[]>([]);
  const [existingImagesUrl, setExistingImagesUrl] = React.useState<string[]>(
    [],
  );
  const [deletedExistingImagesUrl, setDeletedExistingImagesUrl] =
    React.useState<string[]>([]);

  const onImageUploadHandler = async e => {
    setExistingImagesUrl(images);
    const file = e.target.files[0];
    // check if the image is at least 100 x 100 pixels and Max File Size is 16mb

    if (!isFileSizeValid(file, 1600000)) {
      setErrorMessage('Choose an image that is smaller than 16mb');
      return;
    }

    if (!(await isImageBigger(file, 100, 100))) {
      setErrorMessage('Choose a larger image than 100px * 100px');
      return;
    }
    setMainErrorMessage('');
    setImageFiles([...imageFiles, e.target.files[0]]);
  };

  const onExistingImageDeleteHandler = indexDeleted => {
    let resultImages: any[] = [];
    let deletedImages = deletedImagesUrl;
    // find the index of the image that has been removed
    for (let i = 0; i < images.length; i++) {
      if (i !== indexDeleted) {
        resultImages.push(images[i]);
      } else {
        deletedImages.push(images[i]);
      }
    }

    if (imageFiles.length + resultImages.length === 0) {
      setMainErrorMessage('Please upload at least one image');
    }
    setExistingImagesUrl(resultImages);
    setDeletedExistingImagesUrl(deletedImages);
  };

  const onImageDeleteHandler = indexDeleted => {
    const resultImages: any[] = [];
    // find the index of the image that has been removed
    for (let i = 0; i < imageFiles.length; i++) {
      if (i !== indexDeleted) {
        resultImages.push(imageFiles[i]);
      }
    }
    if (existingImagesUrl.length + resultImages.length === 0) {
      setMainErrorMessage('Please upload at least one image');
    }
    setImageFiles(resultImages);
  };

  useEffect(() => {
    // propagate all the files upwards
    onImageFilesChange(imageFiles);
    onExistingImagesUrlChange(existingImagesUrl);
    onDeletedExistingImagesUrlChange(deletedExistingImagesUrl);
  }, [imageFiles, existingImagesUrl, deletedExistingImagesUrl]);

  useEffect(() => {
    setMainErrorMessage(formErrorMessage);
  }, [formErrorMessage]);

  const UploadComponent: React.FC<{
    onUpload: ChangeEventHandler;
  }> = ({ onUpload }) => {
    return (
      <>
        <div className="bg-slate-100 aspect-[1/1] flex flex-col justify-center text-center cursor-pointer border-dotted border border-slate">
          <div className="flex flex-col">
            <label className="flex flex-col p-4 font-poppins gap-2 cursor-pointer">
              <PlusOutlined className="text-xs"></PlusOutlined>
              <div className="text-xs ">Upload</div>
              {errorMessage && (
                <div className="text-error text-xs">{errorMessage}</div>
              )}
              <input
                type="file"
                accept="image/*"
                className="hidden"
                onChange={onUpload}
              ></input>
            </label>
          </div>
        </div>
      </>
    );
  };

  interface ImageProp {
    imageIndex: number;
    imageUrl: string;
    onDelete: Function;
  }

  const ImageComponent: React.FC<ImageProp> = ({
    onDelete,
    imageIndex,
    imageUrl,
  }) => {
    return (
      <>
        <div className="bg-slate-100 aspect-[1/1] flex flex-col justify-center text-center cursor-pointer border-solid border border-slate">
          <>
            <div
              className="image-container h-full w-full relative p-2"
              onClick={() => onDelete(imageIndex)}
            >
              <img className="h-full w-full object-cover" src={imageUrl} />
              <div className="image-hover absolute top-0 left-0 w-full h-full hidden">
                <DeleteOutlined className="text-white"></DeleteOutlined>
              </div>
            </div>
          </>
        </div>
      </>
    );
  };
  return (
    <>
      <div className="grid grid-cols-[1fr_2fr] gap-4">
        <div className="text-end">Image(s) : </div>
        <div className="flex flex-col gap-2 w-full">
          <div className="grid grid-cols-3 gap-4">
            {images.map((img, i) => (
              <ImageComponent
                key={i}
                onDelete={onExistingImageDeleteHandler}
                imageIndex={i}
                imageUrl={img}
              ></ImageComponent>
            ))}
            {imageFiles.map((img, i) => (
              <ImageComponent
                key={i}
                onDelete={onImageDeleteHandler}
                imageIndex={i}
                imageUrl={URL.createObjectURL(img)}
              ></ImageComponent>
            ))}
            {imageFiles.length + images.length < 3 && (
              <UploadComponent
                onUpload={onImageUploadHandler}
              ></UploadComponent>
            )}
          </div>
          {mainErrorMessage && (
            <div className="text-error pt-4">{mainErrorMessage}</div>
          )}
        </div>
      </div>
    </>
  );
};
