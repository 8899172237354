export const isImageSmaller = (
  file: File,
  maxWidth: number,
  maxHeight: number,
): boolean => {
  let img = new Image();
  img.src = window.URL.createObjectURL(file);
  let valid = false;
  img.onload = () => {
    valid = img.width < maxWidth && img.height < maxHeight;
  };

  return valid;
};

export const isImageBigger = (
  file: File,
  minWidth: number,
  minHeight: number,
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = window.URL.createObjectURL(file);

    img.onload = () => {
      resolve(img.width > minWidth && img.height > minHeight);
    };
    img.onerror = error => reject(error);
  });
};

export const isFileSizeValid = (file: File, maxSize: number) => {
  return file.size <= maxSize;
};
