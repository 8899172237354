import { Category } from 'app/models/Category';
import {
  CREATE_CATEGORY,
  DELETE_CATEGORY,
  GET_CATEGORY,
  LIST_CATEGORY,
  UPDATE_CATEGORY,
} from 'app/util/constants/apiEndpoints/CategoryEndpoints';
import axiosInstance from 'utils/axios';

import { deleteObject } from './AWSService';

export const getCategories = async (
  filter?: {
    variantIds?: string[];
    nature?: 'FOOD' | 'NONFOOD';
  },
  paginations?: { page: number; limit: number },
): Promise<{ data: Array<Category>; meta: { count: number } }> => {
  // merge variant ids
  let result;
  try {
    result = await axiosInstance.get(LIST_CATEGORY(), {
      params: {
        variant_ids: filter?.variantIds?.join(','),
        nature: filter?.nature,
        ...paginations,
      },
    });
  } catch (e) {
    console.error(e);
    throw e;
  }
  return result.data;
};

export const getCategory = async (code: number): Promise<Category> => {
  let result;
  try {
    result = await axiosInstance.get(GET_CATEGORY(code));
  } catch (e) {
    console.error('Error Occurred Fetching Category ' + e);
  }
  return result.data.data;
};

export const createCategory = async (data: any) => {
  let result;
  try {
    result = await axiosInstance.post(CREATE_CATEGORY(), data);
  } catch (e) {
    console.error('Error Occurred Creating Category ' + e);
  }
  return result.data;
};

export const updateCategory = async (code: number, data) => {
  let result;
  try {
    result = await axiosInstance.patch(UPDATE_CATEGORY(code), data);
  } catch (e) {
    console.error('Error Occurred Updating Category ' + e);
  }
  return result.data;
};

export const deleteCategory = async (code: number) => {
  let result;
  try {
    result = await axiosInstance.delete(DELETE_CATEGORY(code));
  } catch (e) {
    console.error('Error Occurred Deleting Category ' + e);
  }
  return result.data;
};

export const deleteCategoryImage = async (
  categoryId: number,
  fileName: string,
) => {
  let result;
  try {
    result = await deleteObject(
      `uploads/category/${categoryId}/${fileName.replace('+', ' ')}`,
    );
  } catch (e) {
    console.error('Error Occurred Deleting Category ' + e);
  }
  return result.data;
};
