import { AdminUpdateVariantForm } from 'app/pages/Admin/Variant/Update/UpdateVariantPage/components/AdminUpdateVariantForm';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { PAGE_TITLES_ADMIN_PORTAL } from 'app/util/constants/pageTitles/adminPortal';

export function UpdateVariantPage() {
  return (
    <>
      <Helmet>
        <title>
          {PAGE_TITLES_ADMIN_PORTAL.productManagement.variantUpdatePage}
        </title>
        <meta
          name="description"
          content={PAGE_TITLES_ADMIN_PORTAL.productManagement.variantUpdatePage}
        />
      </Helmet>
      <span>Update Variant Item</span>
      <AdminUpdateVariantForm></AdminUpdateVariantForm>
    </>
  );
}
