/**
 * Asynchronously loads the component for Card
 */

import { lazyLoad } from 'utils/loadable';

export const ConfirmationModalComponent = lazyLoad(
  () => import('./index'),
  module => module.ConfirmationModal,
);
