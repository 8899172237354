const CATEGORY_ROOT_URL = '/category';

const CREATE_CATEGORY = () => CATEGORY_ROOT_URL;
const GET_CATEGORY = (id: number) => `${CATEGORY_ROOT_URL}/${id}`;
const LIST_CATEGORY = () => CATEGORY_ROOT_URL;
const UPDATE_CATEGORY = (id: number) => `${CATEGORY_ROOT_URL}/${id}`;
const DELETE_CATEGORY = (id: number) => `${CATEGORY_ROOT_URL}/${id}`;
const IMAGE_CATEGORY = (id: number, imageName?: string) =>
  `${process.env.REACT_APP_S3_URL}uploads` +
  (imageName ? `/category/${id}/${imageName}` : `/mart-for-good-logo.jpeg`);

export {
  CREATE_CATEGORY,
  GET_CATEGORY,
  LIST_CATEGORY,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
  IMAGE_CATEGORY,
};
