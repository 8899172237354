import {
  getAdminUserDetails,
  updateAdminUserDetails,
} from 'app/api/userService/userapi';
import { useAuthValue } from 'app/AuthContext';
import { InputComponent } from 'app/components/Forms/Input/Loadable';
import { PAGE_TITLES_ADMIN_PORTAL } from 'app/util/constants/pageTitles/adminPortal';
import {
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  signInWithEmailAndPassword,
  updatePassword,
} from 'firebase/auth';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Redirect, useParams } from 'react-router-dom';

import { decodeParam } from './utils';

export function FirstLoginUserPage() {
  const auth = getAuth();

  const { currentUser } = useAuthValue()!;
  // url example: /admin/login/first/<encryptedfirebaseuid>

  let { encodedUid } = useParams<{ encodedUid: string }>();
  const uid = decodeParam(encodedUid);
  const default_pass = 'Password123!';

  const [loginDetails, setLoginDetails] = React.useState({
    name: '',
    jobTitle: '',
    password: '',
    confirmPassword: '',
  });
  const [email, setEmail] = React.useState('');
  const [userType, setUserType] = React.useState('');
  const [hasSignedUp, setHasSignedUp] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState<any>({});
  const [loggedIn, setLoggedIn] = React.useState<boolean>(false);
  const [emailError, setEmailError] = React.useState<boolean>(false);

  // just to force state to update due to async hooks
  const validateEmail = () => {
    signInWithEmailAndPassword(auth, email, default_pass)
      .then(response => {
        setLoggedIn(true);
      })
      .catch(error => {
        console.error(error);
        setEmailError(true);
      });
  };
  React.useEffect(() => {}, [hasSignedUp, email, userType]);

  React.useEffect(() => {
    const apiWrapper = async () => {
      const res = await getAdminUserDetails(
        uid,
        await currentUser!.getIdToken(),
      );
      return res;
    };

    if (currentUser && loggedIn) {
      apiWrapper().then(res => {
        // if user has signed up, redirect them to login
        if (res.status === 'ACTIVE') {
          setHasSignedUp(true);
          return;
        }
        setEmail(res.email);
        setUserType(res.type);
      });
    }
  }, [uid, currentUser, loggedIn]);
  const changePassword = (currentPassword, newPassword) => {
    if (currentUser !== null && currentUser.email !== null) {
      const credential = EmailAuthProvider.credential(
        currentUser.email,
        currentPassword,
      );
      reauthenticateWithCredential(currentUser, credential)
        .then(() => {
          updatePassword(currentUser, newPassword)
            .then(() => {})
            .catch(error => {
              console.error(error);
            });
        })
        .catch(error => {
          console.error(error);
        });
    }
  };
  const publishToDatabase = async () => {
    const requestBody = {
      firebaseId: uid,
      type: userType,
      email: email,
      name: loginDetails.name,
      jobTitle: loginDetails.jobTitle,
      status: 'ACTIVE',
    };
    const res = await updateAdminUserDetails(
      uid,
      await currentUser!.getIdToken(),
      requestBody,
    );

    changePassword(default_pass, loginDetails.password);
    // updatePassword(getAuth().currentUser!, loginDetails.password);
    if (res!.status === 200) {
      setHasSignedUp(true);
    }
  };

  const handleChange = e => {
    const { value, name } = e.target;
    setLoginDetails(prev => ({ ...prev, [name]: value }));
  };
  const handleEmail = e => {
    setEmail(e.target.value);
  };
  const validateInputs = () => {
    const invalidName = loginDetails.name === '';
    const invalidJobTitle = loginDetails.jobTitle === '';
    let invalidPassword = false;
    let passwordMessage = '';
    let confirmPasswordMessage = '';
    if (loginDetails.password !== loginDetails.confirmPassword) {
      invalidPassword = true;
      passwordMessage = 'Passwords do not match.';
      confirmPasswordMessage = 'Passwords do not match.';
    }
    if (loginDetails.password === '') {
      invalidPassword = true;
      passwordMessage = 'Invalid format.';
    }
    if (loginDetails.confirmPassword === '') {
      invalidPassword = true;
      confirmPasswordMessage = 'Invalid format.';
    }
    setErrorMessages({
      nameError: invalidName ? 'Invalid format.' : '',
      setNameError: invalidName,
      jobTitleError: invalidJobTitle ? 'Invalid format.' : '',
      setJobTitleError: invalidJobTitle,
      passwordError: passwordMessage,
      setPasswordError: invalidPassword,
      confirmPasswordError: confirmPasswordMessage,
      setConfirmPasswordError: invalidPassword,
    });
    if (invalidName || invalidJobTitle || invalidPassword) {
      return;
    }
    publishToDatabase();
  };

  // user has to sign in first due to auth requirements for setting password
  return loggedIn ? (
    hasSignedUp ? (
      <Redirect to="/admin/accounts/login" />
    ) : (
      <>
        <Helmet>
          <title>
            {PAGE_TITLES_ADMIN_PORTAL.accountManagement.firstLoginPage}
          </title>
          <meta
            name="description"
            content={PAGE_TITLES_ADMIN_PORTAL.accountManagement.firstLoginPage}
          />
        </Helmet>
        <div className="flex flex-row items-center justify-center h-screen">
          <div className="flex flex-col items-end">
            <div className="flex flex-row">
              <p className="my-[15px] pr-[6px] text-right text-[14px] font-poppins">
                Full Name :
              </p>
              <InputComponent
                className="w-[400px] h-[32px] rounded-[2px] pl-[12px] pt-[8px] font-normal font-poppins leading-[20px] bg-neutral-200 border border-neutral-500 text-dark-blue text-[14px]"
                type="text"
                name="name"
                value={loginDetails.name}
                id="name"
                onChange={handleChange}
                showError={errorMessages.setNameError}
                error={errorMessages.nameError}
              />
            </div>
            <div className="flex flex-row">
              <p className="my-[15px] pr-[6px] text-right text-[14px] font-poppins">
                User Type :
              </p>
              <InputComponent
                className="w-[400px] h-[32px] rounded-[2px] pl-[12px] pt-[8px] font-normal font-poppins leading-[20px] bg-neutral-200 border border-neutral-500 text-neutral-500 text-[14px]"
                disabled={true}
                value={userType}
              />
            </div>
            <div className="flex flex-row">
              <p className="my-[15px] pr-[6px] text-right text-[14px] font-poppins">
                Job Title :
              </p>
              <InputComponent
                className="w-[400px] h-[32px] rounded-[2px] pl-[12px] pt-[8px] font-normal font-poppins leading-[20px] bg-neutral-200 border border-neutral-500 text-dark-blue text-[14px]"
                type="text"
                name="jobTitle"
                value={loginDetails.jobTitle}
                id="jobTitle"
                onChange={handleChange}
                showError={errorMessages.setJobTitleError}
                error={errorMessages.jobTitleError}
              />
            </div>
            <div className="flex flex-row">
              <p className="my-[15px] pr-[6px] text-right text-[14px] font-poppins">
                Email :
              </p>
              <InputComponent
                className="w-[400px] h-[32px] rounded-[2px] pl-[12px] pt-[8px] font-normal font-poppins leading-[20px] bg-neutral-200 border border-neutral-500 text-neutral-500 text-[14px]"
                disabled={true}
                value={email}
              />
            </div>
            <div className="flex flex-row">
              <p className="my-[15px] pr-[6px] text-right text-[14px] font-poppins">
                Password :
              </p>
              <InputComponent
                className="w-[400px] h-[32px] rounded-[2px] pl-[12px] pt-[8px] font-normal font-poppins leading-[20px] bg-neutral-200 border border-neutral-500 text-dark-blue text-[14px]"
                type="password"
                name="password"
                value={loginDetails.password}
                id="password"
                onChange={handleChange}
                showError={errorMessages.setPasswordError}
                error={errorMessages.passwordError}
              />
            </div>
            <div className="flex flex-row">
              <p className="my-[15px] pr-[6px] text-right text-[14px] font-poppins">
                Confirm Password :
              </p>
              <InputComponent
                className="w-[400px] h-[32px] rounded-[2px] pl-[12px] pt-[8px] font-normal font-poppins leading-[20px] bg-neutral-200 border border-neutral-500 text-dark-blue text-[14px]"
                type="password"
                name="confirmPassword"
                value={loginDetails.confirmPassword}
                id="confirmPassword"
                onChange={handleChange}
                showError={errorMessages.setConfirmPasswordError}
                error={errorMessages.confirmPasswordError}
              />
            </div>
            <button
              className="w-[129px] h-[32px] rounded-[20px] ml-[396px] text-[12px] whitespace-nowrap bg-primary"
              children="Create Account"
              type="button"
              onClick={() => validateInputs()}
            />
          </div>
        </div>
      </>
    )
  ) : (
    <>
      {' '}
      <div className="flex flex-row">
        <p className="my-[15px] pr-[6px] text-right text-[14px] font-poppins">
          Key in Email :
        </p>
        <InputComponent
          className="w-[400px] h-[32px] rounded-[2px] pl-[12px] pt-[8px] font-normal font-poppins leading-[20px] bg-neutral-200 border border-neutral-500 text-[#33424E] text-[14px]"
          type="text"
          name="email"
          value={email}
          id="email"
          onChange={handleEmail}
          showError={emailError}
          error={emailError ? 'Invalid Email' : undefined}
        />
      </div>
      <button
        className="w-[129px] h-[32px] rounded-[20px] ml-[396px] text-[12px] whitespace-nowrap bg-[#80B519]"
        type="button"
        onClick={() => validateEmail()}
      >
        Submit
      </button>
    </>
  );
}
