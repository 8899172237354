import React from 'react';
import { Link, LinkProps, useHistory } from 'react-router-dom';

export const CustomLink: React.FC<LinkProps> = (props: LinkProps) => {
  const history = useHistory();
  var { to, ...otherProps } = props;

  return (
    <Link
      to={{
        pathname: to,
        state: {
          from: history.location.pathname,
        },
      }}
      {...otherProps}
    >
      {props.children}
    </Link>
  );
};
