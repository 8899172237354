/**
 * Asynchronously loads the component for Card
 */

import { lazyLoad } from 'utils/loadable';

export const ProductModalComponent = lazyLoad(
  () => import('./index'),
  module => module.ProductModal,
);
