import React from 'react';
import { FooterComponent } from 'app/components/Ecommerce/Footer/Loadable';

interface LogoWithLinkProps {
  logo: string;
  description: string;
  redirectLink?: string;
  onButtonClick?: () => void;
}

const LogoWithLink: React.FC<LogoWithLinkProps> = ({
  logo,
  description,
  redirectLink,
  onButtonClick,
}) => {
  return (
    <div className="flex flex-col gap-4 items-center">
      <img src={logo} alt="" />
      {!redirectLink && <button onClick={onButtonClick}>{description}</button>}
      {redirectLink && (
        <a href={redirectLink} className="text-black font-normal">
          {description}
        </a>
      )}
    </div>
  );
};

export const FooterLogoComponent: React.FC = () => {
  return (
    <div>
      {/* <div className="flex flex-col gap-5 md:flex-row items-center justify-around p-10">
        <LogoWithLink
          logo="/images/logo.svg"
          description="Reread Terms and Conditions"
        />
        <LogoWithLink
          logo="/images/logo.svg"
          description="Return to food bank"
          redirectLink={'https://foodbank.sg/'}
        />
      </div> */}
      <div className="mt-6">
        <FooterComponent />
      </div>
    </div>
  );
};
