import React from 'react';
import { twMerge } from 'tailwind-merge';

export const CONFIRM_BUTTON_CLASSES = 'h-10 rounded-3xl text-white bg-primary';

export const CANCEL_BUTTON_CLASSES =
  'h-10 rounded-3xl text-white bg-neutral-500';

export const DELETE_BUTTON_CLASSES = 'h-10 rounded-3xl text-white bg-pink';

interface IButtonProps {
  className?: string;
  children?: React.ReactNode;
  type?: 'submit' | 'reset' | 'button';
  onClick?: (any) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  disabled?: boolean;
}

export const Button: React.FC<IButtonProps> = props => {
  return (
    <button
      type={props.type}
      className={twMerge(
        'h-10 px-6 font-medium tracking-wide text-white transition duration-200 bg-slate-500 inline-flex items-center justify-center rounded shadow-md focus:shadow-outline focus:outline-none ',
        props.className,
        props.disabled ? 'cursor-not-allowed' : 'hover:bg-slate-700',
      )}
      onClick={props.onClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
};
