import { Dialog, Transition } from '@headlessui/react';
import { Product } from 'app/models/Product';
import React, { Fragment } from 'react';

enum TableHeader {
  ProductName = 'Product Name',
  CatalogCode = 'Catalog Code',
}

interface ICategoryModalProps {
  isModalOpen: boolean;
  category: string;
  closeModal: () => void;
  products?: Product[];
}

interface ITableContentsProps {
  product: Product;
}

interface ITableProps {
  products: Product[];
}

// Table components
const TableHeaders = () => {
  return (
    <thead>
      <tr className="flex flex-row flex-grow items-center bg-dark-blue text-neutral-200 text-l">
        <th className="flex w-[281px] p-4 border border-black/[.06]">
          {TableHeader.ProductName}
        </th>
        <th className="flex w-[281px] p-4 border border-black/[.06]">
          {TableHeader.CatalogCode}
        </th>
      </tr>
    </thead>
  );
};

const TableContents: React.FC<ITableContentsProps> = ({ product }) => {
  return (
    <tr className="flex flex-row flex-grow items-center text-black/80 border-b border-black/[0.06] bg-neutral-200 text-sm leading-6">
      <th className="flex w-[281px] p-4 font-normal">{product.name}</th>
      <th className="flex w-[281px] p-4 font-normal">{product.skuCode}</th>
    </tr>
  );
};

const Table: React.FC<ITableProps> = ({ products }) => {
  return (
    <table className="flex flex-col flex-grow">
      <TableHeaders />
      <tbody>
        {products &&
          products.map((product, index) => (
            <TableContents product={product} key={index} />
          ))}
      </tbody>
    </table>
  );
};

export const CategoryModal: React.FC<ICategoryModalProps> = ({
  isModalOpen,
  category,
  closeModal,
  products,
}) => {
  return (
    <Transition appear show={isModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <div className="fixed inset-0">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-[635px] h-[635px] transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="flex flex-col gap-2.5">
                  <div className="flex flex-col">
                    <Dialog.Title
                      as="h3"
                      className="text-xl font-medium leading-6 text-gray-900"
                    >
                      {category}
                    </Dialog.Title>
                    {products && (
                      <p className="text-sm leading-6 text-black/80">
                        {products.length} products
                      </p>
                    )}
                  </div>
                  <div className="overflow-scroll h-[500px]">
                    {products && <Table products={products} />}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
