import { CustomLink } from 'app/components/CustomLink';
import { ILocationState } from 'app/pages/LogoutPage';
import { PAGE_URL_ADMIN_PORTAL } from 'app/util/constants/pageUrls/adminPortal';
import { PAGE_URL_ECommerce } from 'app/util/constants/pageUrls/ecommerce';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

export function NotFoundPage() {
  const location = useLocation<ILocationState>();

  let redirectPath: string = '';

  if (location.state && location.state.from.split('/')[0] == 'admin') {
    redirectPath = PAGE_URL_ADMIN_PORTAL.accountManagement.loginPage;
  } else {
    redirectPath = PAGE_URL_ECommerce.landingPage;
  }
  return (
    <>
      <Helmet>
        <title>404 Page Not Found</title>
        <meta name="description" content="Page not found" />
      </Helmet>
      <div
        className="grid justify-items-center min-h-screen py-4 md:py-12 lg:py-16 xl:pb-24 xl:pt-48"
        style={{
          backgroundImage: "url('/ecommerce/foodMartBg.svg')",
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <div className="px-6 md:px-12 lg:px-16 xl:px-24 py-2 md:py-4 lg:py-8 xl:py-12 lg:min-w-3xl xl:min-w-4xl bg-neutral-100/75 rounded-3xl border border-neutral-200 shadow-md text-center my-auto mx-5">
          <h1 className="text-6xl font-extrabold text-primary my-2">
            Page Not Found
          </h1>
          <div className="text-md font-normal text-neutral-600 py-5">
            <p>The page you are looking for is not found!</p>
          </div>
          <div className="flex">
            <CustomLink
              to={redirectPath}
              className="rounded-full bg-purple mx-auto mt-10 px-4 py-2 font-semibold text-white"
            >
              Return to Home Page
            </CustomLink>
          </div>
        </div>
      </div>
      {/* <div className="h-screen flex flex-col min-h-[320px] justify-center items-center">
        <div className="-mt-[8vh] text-bold text-black text-[3.375rem]">
          4
          <span role="img" aria-label="Crying Face" className="text-[3.125rem]">
            😢
          </span>
          4
        </div>
        <p>Page not found.</p>
      </div> */}
    </>
  );
}
