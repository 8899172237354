import React, { useContext } from 'react';

export enum VariantIds {
  A = '01',
  B = '02',
  C = '03',
}

export const normaliseVariantId: (variantId: VariantIds) => number = (
  variantId: VariantIds,
) => {
  switch (variantId) {
    case VariantIds.A:
      return 1;

    case VariantIds.B:
      return 2;

    case VariantIds.C:
      return 3;

    default:
      return 1;
  }
};

export const VariantContext = React.createContext<VariantIds>(VariantIds.A);

export function useVariantValue() {
  return useContext(VariantContext);
}
