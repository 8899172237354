import {
  DELETE_OBJECT,
  GET_OBJECT,
  UPLOAD_OBJECT,
} from 'app/util/constants/apiEndpoints/AWSEndpoints';
import axiosInstance from 'utils/axios';

export const getObject = async (key: string) => {
  let result;
  try {
    result = axiosInstance.get(GET_OBJECT(key));
  } catch (e) {
    console.error(e);
  }
  return result;
};

/**
 * Uploads the file the designated path
 * @param path @example "uploads/item/item_id/"
 * @param file
 */
export const uploadObject = async (path: string, file: File) => {
  const formData = new FormData();

  formData.append('file', file);
  formData.append('path', path);

  let result;
  try {
    result = await axiosInstance.put(UPLOAD_OBJECT(), formData);
  } catch (e) {
    console.error('Error Occurred Uploading File ' + e);
  }

  return result.data;
};

export const deleteObject = async (path: string) => {
  let result;
  try {
    result = await axiosInstance.delete(DELETE_OBJECT(), {
      data: { location: path },
    });
  } catch (e) {
    console.error('Error Deleting Object ' + e);
  }
  return result;
};
