import {
  deactivateUser,
  deleteAdminUser,
  getAdminUserDetails,
  updateAdminUserDetails,
} from 'app/api/userService/userapi';
import { useAuthValue } from 'app/AuthContext';
import { InputComponent } from 'app/components/Forms/Input/Loadable';
import { ConfirmationModalComponent } from 'app/components/Modals/ConfirmationModal/Loadable';
import { PAGE_TITLES_ADMIN_PORTAL } from 'app/util/constants/pageTitles/adminPortal';
import { PAGE_URL_ADMIN_PORTAL } from 'app/util/constants/pageUrls/adminPortal';
import { isEmptyField } from 'app/util/validators/FormValidators';
import { isFormValid } from 'app/util/validators/ValidatorHelper';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';

export function UpdateUserPage(user: any) {
  const { currentUser } = useAuthValue()!;
  const [userDetails, setUserDetails] = React.useState({
    name: '',
    jobTitle: '',
    userType: 'FOODBANK',
    email: '',
    status: 'ACTIVE',
  });
  const [errorMessages, setErrorMessages] = React.useState<any>({});
  const [isUpdateModalOpen, setIsUpdateModalOpen] =
    React.useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] =
    React.useState<boolean>(false);
  const cancelButtonRef = React.useRef(null);
  const history = useHistory();

  React.useEffect(() => {}, [userDetails]);

  React.useEffect(() => {
    const apiWrapper = async () => {
      // get data of uid params user
      const res = await getAdminUserDetails(
        user.userUid,
        await currentUser!.getIdToken(),
      );
      return res;
    };

    if (currentUser) {
      apiWrapper().then(res => {
        setUserDetails({
          name: res.name,
          jobTitle: res.jobTitle,
          email: res.email,
          status: res.status,
          userType: res.type,
        });
      });
    }
  }, [currentUser]);

  const updateDatabase = async () => {
    const requestBody = {
      firebaseId: user.userUid,
      type: userDetails.userType,
      email: userDetails.email,
      name: userDetails.name,
      jobTitle: userDetails.jobTitle,
      status: userDetails.status,
    };
    if (requestBody.status === 'INACTIVE') {
      await deactivateUser(user.userUid, await currentUser!.getIdToken());
    }
    const res = await updateAdminUserDetails(
      user.userUid,
      await currentUser!.getIdToken(),
      requestBody,
    );
    if (res!.status !== 200) {
      console.error('Something went wrong');
    }
    setIsUpdateModalOpen(false);
    setIsDeleteModalOpen(false);
    history.push({
      pathname: PAGE_URL_ADMIN_PORTAL.accountManagement.accountsPage,
      state: {
        from: history.location.pathname,
      },
    });
  };

  const deleteFromDatabase = async () => {
    const res = await deleteAdminUser(
      user.userUid,
      await currentUser!.getIdToken(),
    );
    if (res!.status !== 200) {
      console.error('Something went wrong');
    }
    setIsUpdateModalOpen(false);
    setIsDeleteModalOpen(false);
    history.push({
      pathname: PAGE_URL_ADMIN_PORTAL.accountManagement.accountsPage,
      state: {
        from: history.location.pathname,
      },
    });
  };

  const handleChange = e => {
    const { value, name } = e.target;
    setUserDetails(prev => ({ ...prev, [name]: value }));
  };

  const validateInputs = () => {
    const errors = {
      nameError: isEmptyField('Please enter a name', userDetails.name),
      invalidJobTitle: isEmptyField(
        'Please enter a job title',
        userDetails.jobTitle,
      ),
    };
    setErrorMessages(errors);
    if (!isFormValid(errors)) {
      return;
    } else {
      setIsUpdateModalOpen(true);
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {PAGE_TITLES_ADMIN_PORTAL.accountManagement.updateAccountPage}
        </title>
        <meta
          name="description"
          content={PAGE_TITLES_ADMIN_PORTAL.accountManagement.updateAccountPage}
        />
      </Helmet>
      <div className="flex flex-row items-center justify-center h-screen">
        <div className="flex flex-col items-end">
          <div className="flex flex-row">
            <p className="my-[15px] pr-[6px] text-right text-[14px] font-poppins">
              Name :
            </p>
            <InputComponent
              className="w-[400px] h-[32px] rounded-[2px] pl-[12px] pt-[8px] font-normal font-poppins leading-[20px] bg-neutral-200 border border-neutral-500 text-dark-blue text-[14px]"
              type="text"
              name="name"
              value={userDetails.name}
              id="name"
              onChange={handleChange}
              error={errorMessages.nameError}
            />
          </div>
          <div className="flex flex-row pt-2 pb-4">
            <p className="pt-[6px] pr-[6px] text-right text-[14px] font-poppins">
              User Type :
            </p>
            <select
              className="w-[400px] h-[32px] rounded-[2px] pl-[9px] pt-[2px] font-normal font-poppins leading-[20px] bg-neutral-200 border border-neutral-500 text-dark-blue text-[14px]"
              id="userType"
              name="userType"
              value={userDetails.userType}
              onChange={handleChange}
            >
              <option value="FOODBANK">Food Bank</option>
              <option value="RESEARCH">Research</option>
            </select>
          </div>
          <div className="flex flex-row">
            <p className="my-[15px] pr-[6px] text-right text-[14px] font-poppins">
              Job Title :
            </p>
            <InputComponent
              className="w-[400px] h-[32px] rounded-[2px] pl-[12px] pt-[8px] font-normal font-poppins leading-[20px] bg-neutral-200 border border-neutral-500 text-dark-blue text-[14px]"
              type="text"
              name="jobTitle"
              value={userDetails.jobTitle}
              id="jobTitle"
              onChange={handleChange}
              error={errorMessages.invalidJobTitle}
            />
          </div>
          <div className="flex flex-row">
            <p className="my-[15px] pr-[6px] text-right text-[14px] font-poppins">
              Email :
            </p>
            <InputComponent
              className="w-[400px] h-[32px] rounded-[2px] pl-[12px] pt-[8px] font-normal font-poppins leading-[20px] bg-neutral-200 border border-neutral-500 text-neutral-500 text-[14px]"
              disabled={true}
              value={userDetails.email}
            />
          </div>
          <div className="flex flex-row pt-2 pb-4">
            <p className="pt-[6px] pr-[6px] text-right text-[14px] font-poppins">
              Status :
            </p>
            <select
              className="w-[400px] h-[32px] rounded-[2px] pl-[9px] pt-[2px] font-normal font-poppins leading-[20px] bg-neutral-200 border border-neutral-500 text-dark-blue text-[14px]"
              id="status"
              name="status"
              value={userDetails.status}
              onChange={handleChange}
            >
              <option value="ACTIVE">Active</option>
              <option value="INACTIVE">Inactive</option>
            </select>
          </div>
          <div>
            <button
              className="w-[129px] h-[32px] rounded-[20px] mx-2 text-[12px] whitespace-nowrap bg-primary"
              children="Save Changes"
              type="button"
              onClick={() => validateInputs()}
            />
            <button
              className="w-[109px] h-[32px] rounded-[20px] text-[12px] whitespace-nowrap bg-pink"
              children="Delete User"
              type="button"
              onClick={() => setIsDeleteModalOpen(true)}
            />
          </div>
        </div>
      </div>
      <ConfirmationModalComponent
        showState={isUpdateModalOpen}
        showStateController={setIsUpdateModalOpen}
        cancelButtonRef={cancelButtonRef}
        header={'Would you like to save the changes made to this user?'}
        iconStyles={'w-[22px] h-[22px] text-primary my-1 mr-3'}
        primaryButtonText={'Update'}
        primaryButtonStyles={
          'items-end bg-primary w-[72px] h-[32px] rounded-[20px] text-[12px] whitespace-nowrap'
        }
        buttonFunction={() => updateDatabase()}
        secondaryButtonStyles={
          'items-end bg-neutral-500 w-[72px] h-[32px] rounded-[20px] text-[12px] whitespace-nowrap mr-2'
        }
      />
      <ConfirmationModalComponent
        showState={isDeleteModalOpen}
        showStateController={setIsDeleteModalOpen}
        cancelButtonRef={cancelButtonRef}
        header={'Are you sure you want to delete this user?'}
        iconStyles={'w-[22px] h-[22px] text-pink my-1 mr-3'}
        primaryButtonText={'Delete'}
        primaryButtonStyles={
          'items-end bg-pink w-[72px] h-[32px] rounded-[20px] text-[12px] whitespace-nowrap'
        }
        buttonFunction={() => deleteFromDatabase()}
        secondaryButtonStyles={
          'items-end bg-neutral-500 w-[72px] h-[32px] rounded-[20px] text-[12px] whitespace-nowrap mr-2'
        }
      />
    </>
  );
}
