import { PAGE_TITLES_ADMIN_PORTAL } from 'app/util/constants/pageTitles/adminPortal';
import { PAGE_URL_ADMIN_PORTAL } from 'app/util/constants/pageUrls/adminPortal';
import { PAGE_URL_ECommerce } from 'app/util/constants/pageUrls/ecommerce';
import { getAuth, signOut } from 'firebase/auth';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

export interface ILocationState {
  from: string;
}

export function LogoutPage() {
  const history = useHistory();
  const location = useLocation<ILocationState>();
  const [loggedOut, setLoggedOut] = useState<boolean>(false);
  const [redirectUrl, setRedirectUrl] = useState<string>('');

  const handleLogout = () => {
    setRedirectUrl(PAGE_URL_ECommerce.landingPage);
    if (location.state && location.state.from.split('/')[1] == 'admin') {
      setRedirectUrl(PAGE_URL_ADMIN_PORTAL.accountManagement.loginPage);
    }

    setLoggedOut(true);
  };

  const logout = () => {
    const auth = getAuth();
    signOut(auth).then(res => handleLogout());
  };

  React.useEffect(() => logout(), [logout]);

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLES_ADMIN_PORTAL.logoutPage}</title>
        <meta
          name="description"
          content={PAGE_TITLES_ADMIN_PORTAL.logoutPage}
        />
      </Helmet>
      <div className="h-full flex justify-center items-center">
        <h1>Logging out now!</h1>
        {loggedOut ? (
          <Redirect
            to={{
              pathname: redirectUrl,
              state: { referrer: history.location.pathname },
            }}
          />
        ) : null}
      </div>
    </>
  );
}
