import React, { InputHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  wrapperClassName?: string;
  error?: string;
  showError?: boolean;
  isLabelHorizontal?: boolean;
}

export const Input: React.FC<IInputProps> = ({
  wrapperClassName,
  title,
  className,
  type,
  name,
  value,
  id,
  placeholder,
  min,
  showError,
  error,
  maxLength,
  onChange,
  disabled,
  isLabelHorizontal,
  required,
  minLength,
}) => {
  return (
    <div
      className={
        isLabelHorizontal
          ? 'grid grid-cols-[1fr_2fr] gap-x-4 items-center'
          : 'flex flex-col h-full'
      }
    >
      {title && (
        <div
          className={
            isLabelHorizontal
              ? 'justify-self-end'
              : 'mb-3 block text-sm md:text-base'
          }
        >
          {title}
          {required && <span className="text-error">*</span>}:
        </div>
      )}

      <input
        type={type}
        name={name}
        value={value}
        id={id}
        placeholder={placeholder}
        min={min}
        maxLength={maxLength}
        onChange={onChange}
        disabled={disabled}
        className={twMerge(
          'py-1.5 px-3 text-base font-medium border-[#e0e0e0] bg-neutral-200 text-neutral-600 focus:border-[#6A64F1] focus:shadow-md w-full rounded-md border outline-none',
          className,
        )}
        required={required}
        minLength={minLength}
      ></input>
      {showError && error ? (
        <div
          className={
            'text-[#FF4D4F] text-[14px] ' +
            (isLabelHorizontal ? 'col-start-2' : '')
          }
        >
          {error}
        </div>
      ) : (
        <span></span>
      )}
    </div>
  );
};
