/**
 * Asynchronously loads the component for Accordion
 */

import { lazyLoad } from 'utils/loadable';

export const AccordionComponent = lazyLoad(
  () => import('./index'),
  module => module.Accordion,
);
