import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { useAuthValue } from 'app/AuthContext';
import { Button } from 'app/components/Buttons/Button';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { PAGE_URL_ADMIN_PORTAL } from 'app/util/constants/pageUrls/adminPortal';

type LoginFormProps = {
  className?: string;
};

const InputContainer = ({ children }) => {
  return (
    <div className="w-96 px-4 py-2 border-neutral-600 !bg-neutral-200 flex flex-row items-center gap-3 border rounded-sm">
      {children}
    </div>
  );
};

function sanitize(input: string): string {
  return input.replace(' ', '');
}

export const LoginForm: React.FC<LoginFormProps> = ({ className }) => {
  // State
  const [username, setUsername] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [rememberMe, setRememberMe] = React.useState<boolean>(false);
  const [loginError, setLoginError] = React.useState(false);

  const { currentUser } = useAuthValue()!;

  const login = (email, password) => {
    const emailSanitized = sanitize(email);
    const auth = getAuth();
    signInWithEmailAndPassword(auth, emailSanitized, password).catch(error => {
      setLoginError(true);
      console.error(error);
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    login(username, password);
  };
  if (currentUser) {
    return <Redirect to={PAGE_URL_ADMIN_PORTAL.homePage} />;
  }

  return (
    <form
      className="h-[100vh] flex items-center justify-center"
      onSubmit={e => {
        handleSubmit(e);
      }}
    >
      <div className="w-96 gap-6 font-medium tracking-wide text-black flex flex-col items-center justify-center">
        <div className="flex border-b border-black/[.06] py-2 w-full">
          <span className="text-primary text-sm py-1 border-b-2 border-primary">
            Login
          </span>
        </div>
        <InputContainer>
          <UserOutlined className="text-2xl !text-primary" />
          <input
            type="text"
            placeholder="johnsnow@got.com"
            onChange={e => {
              setUsername(e.target.value);
              setLoginError(false);
            }}
            className={`w-full bg-neutral-200 placeholder-neutral-500 !outline-0 border-0 focus:border-0 ${
              !loginError ? 'text-black' : 'text-error'
            }`}
          ></input>
        </InputContainer>
        <div className="flex flex-col">
          <InputContainer>
            <LockOutlined className="text-2xl !text-primary" />
            <input
              type="password"
              placeholder="password"
              onChange={e => {
                setPassword(e.target.value);
                setLoginError(false);
              }}
              className={`w-full bg-neutral-200 placeholder-neutral-500 !outline-0 border-0 focus:border-0 ${
                !loginError ? 'text-black' : 'text-error'
              }`}
            ></input>
          </InputContainer>
          {loginError && (
            <span className="text-error">Wrong email or password.</span>
          )}
        </div>
        <div className="flex flex-row justify-between w-full">
          <a
            href={PAGE_URL_ADMIN_PORTAL.accountManagement.forgetPasswordPage}
            className="text-neutral-600"
          >
            Forgot password?
          </a>
        </div>
        <div className="flex w-full">
          <Button
            children={<span className="text-white">Sign In</span>}
            type={'submit'}
            className="px-8 !rounded-3xl !bg-primary"
          />
        </div>
      </div>
    </form>
  );
};
