import {
  DownOutlined,
  FilterOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Menu } from '@headlessui/react';
import Pagination from '@mui/material/Pagination';
import { getAllUsers } from 'app/api/userService/userapi';
import { useAuthValue } from 'app/AuthContext';
import { CustomLink } from 'app/components/CustomLink';
import { PAGE_TITLES_ADMIN_PORTAL } from 'app/util/constants/pageTitles/adminPortal';
import { PAGE_URL_ADMIN_PORTAL } from 'app/util/constants/pageUrls/adminPortal';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

enum Status {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  PENDING = 'Pending',
}

enum TableHeader {
  Name = 'Name',
  Email = 'Email',
  JobTitle = 'Job Title',
  UserType = 'User Type',
  LastUpdatedBy = 'Last Updated By',
  Actions = 'Actions',
}

interface data {
  name: string;
  email: string;
  jobTitle: string;
  userType: string;
  lastUpdatedBy: string;
  actions: string;
  status: Status;
  id: string;
}

interface TableContentsProps {
  data: data;
}

interface TableProps {
  contents: data[];
}

enum UserType {
  Default = 'User Type',
  FOODBANK = 'Food Bank',
  RESEARCH = 'Research Team',
}

const TableHeaders = () => {
  return (
    <tr className="flex flex-row bg-dark-blue flex-grow text-neutral-200 text-l max-h-[55px] min-w-[1200px]">
      <th className="flex flex-1 min-w-[250px] p-4 border border-black/[.06] max-h-[55px]">
        {TableHeader.Name}
      </th>
      <th className="flex flex-1 min-w-[288px] p-4 border border-black/[.06] max-h-[55px]">
        {TableHeader.Email}
      </th>
      <th className="flex flex-1 min-w-[181px] p-4 border border-black/[.06] max-h-[55px]">
        {TableHeader.JobTitle}
      </th>
      <th className="flex flex-1 min-w-[155px] p-4 border border-black/[.06] max-h-[55px]">
        {TableHeader.UserType}
      </th>
      <th className="flex flex-1 min-w-[198px] p-4 border border-black/[.06] max-h-[55px]">
        {TableHeader.LastUpdatedBy}
      </th>
      <th className="flex flex-1 min-w-[128px] pt-4 pb-4 pl-4 border border-black/[.06] max-h-[55px]">
        {TableHeader.Actions}
      </th>
    </tr>
  );
};

const TableContents: React.FC<TableContentsProps> = ({ data }) => {
  return (
    <tr className="flex flex-row flex-grow text-black/80 border-b border-black/[0.06] max-h-[55px] min-w-[1200px] items-center">
      <td
        className="flex flex-1 min-w-[288px] p-4 max-h-[55px]"
        style={{ overflow: 'scroll' }}
      >
        {data.name}
      </td>
      <td
        className="flex flex-1 min-w-[239px] p-4 max-h-[55px]"
        style={{ overflow: 'scroll' }}
      >
        {data.email}
      </td>
      <td className="flex flex-1 min-w-[181px] p-4 max-h-[55px]">
        {data.jobTitle}
      </td>
      <td className="flex flex-1 min-w-[155px] p-4 max-h-[55px]">
        {data.userType}
      </td>
      <td className="flex flex-1 min-w-[198px] p-4 max-h-[55px]">
        {data.lastUpdatedBy}
      </td>
      <td className="flex flex-1 min-w-[128px] p-4 max-h-[55px] text-[#1890FF]">
        <a href={'/admin/accounts/update/' + data.id}>{data.actions}</a>
      </td>
    </tr>
  );
};

const Table: React.FC<TableProps> = ({ contents }) => {
  return (
    <table className="flex flex-col flex-grow" style={{ overflowX: 'scroll' }}>
      <TableHeaders />
      {contents &&
        contents.map((content, index) => (
          <TableContents data={content} key={`${content.email}-${index}`} />
        ))}
    </table>
  );
};

export function UserManagementPage() {
  // Mock data
  const { currentUser } = useAuthValue()!;
  React.useEffect(() => {
    const retrieveUsers = async () => {
      const data = await getAllUsers(await currentUser!.getIdToken());
      const users = data.map(user => {
        return {
          name: user.name,
          email: user.email,
          jobTitle: user.jobTitle,
          userType: UserType[user.type],
          lastUpdatedBy: 'Blank',
          actions: 'Edit User',
          status: user.status,
          id: user.id,
        };
      });
      setUsers(users);
      setFilteredContent(users);
    };
    if (currentUser) {
      retrieveUsers();
    }
  }, [currentUser]);

  // State
  const [users, setUsers] = useState<data[]>();
  const statuses = [Status.ACTIVE, Status.INACTIVE, Status.PENDING];
  const [selectedStatus, setSelectedStatus] = useState(Status.ACTIVE);
  const [filteredContent, setFilteredContent] = useState<data[]>([]);
  const filterList = [UserType.Default, UserType.FOODBANK, UserType.RESEARCH];
  const [filtered, setFiltered] = useState(UserType.Default);
  const [searchName, setSearchName] = useState('');

  const recordsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [paginatedContent, setPaginatedContent] = useState<data[]>();
  const [totalPages, setTotalPages] = useState(1);

  // Helper function
  const handleFilter = selectedFilter => {
    setFiltered(selectedFilter);
  };

  // Use effect
  useEffect(() => {
    if (users) {
      let temp = users;
      if (filtered !== UserType.Default) {
        temp = temp.filter(data => data.userType === filtered);
      }
      if (searchName !== '') {
        temp = temp.filter(data =>
          data.name.toLowerCase().includes(searchName.toLowerCase()),
        );
      }
      temp = temp.filter(data => Status[data.status] === selectedStatus);
      setFilteredContent(temp);
    }
  }, [searchName, filtered, selectedStatus, users]);

  useEffect(() => {
    let indexOfLastRecord = currentPage * recordsPerPage;
    let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

    setTotalPages(Math.ceil(filteredContent.length / recordsPerPage));

    setPaginatedContent(
      filteredContent.slice(indexOfFirstRecord, indexOfLastRecord),
    );
  }, [currentPage, filteredContent]);

  const Dropdown = () => {
    return (
      <Menu as="div" className={`w-[139px] text-sm relative inline-block`}>
        <div className="">
          <Menu.Button className="flex flex-row items-center justify-between text-neutral-600 px-2 py-[1px] w-full bg-neutral-200 border border-neutral-500">
            <span>{filtered}</span>
            <DownOutlined className="text-[10px]" />
          </Menu.Button>
        </div>
        <Menu.Items className="absolute flex flex-col w-full">
          {filterList &&
            filterList
              .filter(filter => filter !== filtered)
              .map(filter => {
                return (
                  <Menu.Item key={filter}>
                    {() => (
                      <span
                        className="text-neutral-600 px-2 py-[1px] w-full bg-neutral-200 border-b border-neutral-500 hover:bg-dark-blue hover:text-neutral-200"
                        onClick={() => handleFilter(filter)}
                      >
                        {filter}
                      </span>
                    )}
                  </Menu.Item>
                );
              })}
        </Menu.Items>
      </Menu>
    );
  };

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLES_ADMIN_PORTAL.accountManagement.accountsPage}</title>
        <meta
          name="description"
          content={PAGE_TITLES_ADMIN_PORTAL.accountManagement.accountsPage}
        />
      </Helmet>
      <main>
        <div className="flex flex-col flex-1 h-screen">
          {/* Heading */}
          <div className="pl-2.5 py-3 border-b border-[#D9D9D9]">
            <h1 className="text-xl text-primary leading-[1.375rem] font-bold">
              Admin Accounts
            </h1>
          </div>

          {/* Search bar and filter */}
          <div className="flex border-b border-[#D9D9D9] py-1">
            <div className="flex flex-row items-center flex-1 px-2.5">
              {/* Search bar */}
              <div className="flex flex-row flex-1 items-center gap-2.5 h-6 border-r border-neutral-500 ">
                <SearchOutlined className="text-[13px] text-neutral-600" />
                <input
                  placeholder="Search Name..."
                  className="flex flex-grow text-sm text-dark-blue outline-0 placeholder-neutral-500"
                  onChange={e => setSearchName(e.target.value)}
                />
              </div>
              {/* Filter */}
              <div className="flex flex-row flex-1 px-2.5 items-center gap-2.5 h-6 border-r border-neutral-500 text-neutral-600">
                <FilterOutlined className="text-[13px] text-neutral-600" />
                <span>Filter</span>
                <div>
                  <Dropdown />
                </div>
              </div>
            </div>
            <div className="w-[200px]">
              <CustomLink
                to={PAGE_URL_ADMIN_PORTAL.accountManagement.createUserPage}
                className="text-white border-primary border rounded-3xl !bg-primary py-1 px-4"
              >
                <span className="text-sm leading-[1.375rem]">Add User</span>
              </CustomLink>
            </div>
          </div>

          {/* Status */}
          <div className="flex flex-row border-b border-[#D9D9D9]">
            {statuses &&
              statuses.map(status => {
                return (
                  <div
                    key={status}
                    className={`flex justify-center py-2 w-[101px] ${
                      status === selectedStatus
                        ? 'border-b-2  border-primary'
                        : ''
                    }`}
                    onClick={() => {
                      setSelectedStatus(status);
                    }}
                  >
                    <span className="py-1 text-sm text-neutral-600 ">
                      {status}
                    </span>
                  </div>
                );
              })}
          </div>

          {/* Table */}
          {paginatedContent ? (
            <div className="flex flex-col justify-between flex-grow h-full p-6">
              <Table contents={paginatedContent} />
              <div className="flex justify-end">
                <Pagination
                  count={totalPages}
                  variant="outlined"
                  shape="rounded"
                  onChange={(_e, page) => {
                    setCurrentPage(page);
                  }}
                />
              </div>
            </div>
          ) : null}
        </div>
      </main>
    </>
  );
}
