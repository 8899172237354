import { ButtonComponent } from 'app/components/Buttons/Button/Loadable';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import React from 'react';
import { Redirect } from 'react-router-dom';
import ReactGA4 from 'react-ga4';

export const EcommerceLoginForm: React.FC = () => {
  // State
  const [username, setUsername] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [countdownTime, setCountdownTime] = React.useState<number>(5);
  const [logInSuccess, setLogInSuccess] = React.useState<boolean>(false);
  const [userNotFound, setUserNotFound] = React.useState<boolean>(false);
  const [passwordInvalid, setPasswordInvalid] = React.useState<boolean>(false);

  const auth = getAuth();
  const user = auth.currentUser;

  const login = (email, password) => {
    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
      .then(response => {
        ReactGA4.event({
          category: 'user',
          action: 'login',
        });
        setLogInSuccess(true);
      })
      .catch(error => {
        if (error.code === 'auth/user-not-found') {
          setUserNotFound(true);
        } else if (error.code === 'auth/wrong-password') {
          setPasswordInvalid(true);
        }
      });
  };
  const handleSubmit = event => {
    event.preventDefault();
    login(username, password);
  };

  if (user) {
    setTimeout(() => {
      if (countdownTime > 0) {
        setCountdownTime(countdownTime - 1);
      }
    }, 1000);
    return countdownTime > 0 ? (
      <div>
        <h1>Logged in!</h1>
        <p>Redirecting in {countdownTime}</p>
      </div>
    ) : (
      <Redirect to="/" />
    );
  }

  if (logInSuccess) {
    return <Redirect to="/" />;
  }

  return (
    <form
      className=""
      onSubmit={e => {
        handleSubmit(e);
      }}
    >
      <div className="flex flex-col flex-grow gap-3">
        <div className="flex flex-col flex-grow">
          <label
            className={`${
              userNotFound ? 'text-pink' : 'text-neutral-600'
            } text-xs h-10 flex items-center font-bold rounded-sm`}
          >
            Email
          </label>
          <input
            placeholder={'Enter Email'}
            className={`h-10 border border-neutral-600 rounded-[5px] ${
              userNotFound ? 'border-pink text-pink' : 'border-neutral-600'
            }`}
            onChange={e => {
              setUsername(e.target.value);
              setUserNotFound(false);
            }}
            type={'text'}
          />
        </div>
        <div className="flex flex-col">
          <div className="flex flex-col flex-grow">
            <div className="flex flex-row items-center justify-between">
              <label
                className={`${
                  passwordInvalid ? 'text-pink' : 'text-neutral-600'
                } text-xs h-10 flex items-center font-bold rounded-sm`}
              >
                Password
              </label>
              <a href="/forgot" className="text-primary">
                forgot password
              </a>
            </div>
            <input
              placeholder={'Enter password'}
              className={`h-10 border border-neutral-600 rounded-[5px] ${
                passwordInvalid ? 'border-pink text-pink' : 'border-neutral-600'
              }`}
              onChange={e => {
                setPassword(e.target.value);
                setPasswordInvalid(false);
              }}
              type={'password'}
            />
          </div>
          {userNotFound && (
            <span className="text-xs text-pink">Email is not registered!</span>
          )}
          {passwordInvalid && (
            <span className="text-xs text-pink">Incorrect password!</span>
          )}
        </div>
        <ButtonComponent
          className="!bg-primary flex items-center justify-center text-center h-10"
          children={<p className="mb-0 text-base text-white">Sign in</p>}
          type={'submit'}
        />
        <div className="flex flex-row items-center leading-7 text-neutral-600">
          <p className="mb-0">New to Mart for Good?&nbsp;</p>
          <a href="/signup" className="mb-0 !text-primary">
            {' '}
            Sign Up
          </a>
        </div>
      </div>
    </form>
  );
};
