import { InfoCircleOutlined } from '@ant-design/icons';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { useAuthValue } from 'app/AuthContext';
import { ButtonComponent } from 'app/components/Buttons/Button/Loadable';
import {
  Auth,
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification,
} from 'firebase/auth';
import React from 'react';
import ReactGA4 from 'react-ga4';
import { Redirect } from 'react-router-dom';

interface IInputContainerProps {
  label: React.ReactNode;
  placeHolder: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type: 'text' | 'password' | 'email';
}

const InputContainer: React.FC<IInputContainerProps> = ({
  label,
  placeHolder,
  onChange,
  type,
}) => {
  return (
    <div className="flex flex-col flex-grow">
      {label}
      <input
        placeholder={placeHolder}
        className="h-10 border border-neutral-600 rounded-[5px] placeholder-neutral-500"
        onChange={onChange}
        type={type}
      />
    </div>
  );
};

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 1)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
    border: '1px solid #5200D1',
  },
}));

export const EcommerceSignupForm: React.FC = () => {
  // State
  const [name, setName] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [confirmPassword, setConfirmPassword] = React.useState<string>('');
  const [countdownTime, setCountdownTime] = React.useState<number>(5);
  const [signupSuccess, setSignupSuccess] = React.useState<boolean>(false);
  // TODO: Handle validations
  const [emailInUsed, setEmailInUsed] = React.useState<boolean | null>(null);
  const [passwordInvalid, setPasswordInalid] = React.useState<boolean | null>(
    null,
  );
  const [confirmPasswordValid, setConfirmPasswordValid] = React.useState<
    boolean | null
  >(null);

  const auth = getAuth();

  const { currentUser } = useAuthValue()!;

  // TODO: Handle sign up
  const handleSubmit = event => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setConfirmPasswordValid(false);
    } else {
      handleFirebaseUserCreation(auth, email, password);
    }
  };

  const handleFirebaseUserCreation = (
    auth: Auth,
    email: string,
    password: string,
  ) => {
    createUserWithEmailAndPassword(auth, email, password)
      .then(newUsrCredentials => {
        sendEmailVerification(newUsrCredentials.user);
        setSignupSuccess(true);
      })
      .catch(error => {
        console.error(error.code);
        if (error.code === 'auth/email-already-in-use') {
          setEmailInUsed(true);
        } else if (error.code === 'auth/weak-password') {
          setPasswordInalid(true);
        }
      });
  };

  if (currentUser) {
    setTimeout(() => {
      if (countdownTime > 0) {
        setCountdownTime(countdownTime - 1);
      }
    }, 1000);
    return countdownTime > 0 ? (
      <div>
        <h1>Already logged in!</h1>
        <p>Redirecting in {countdownTime}</p>
      </div>
    ) : (
      <Redirect to="/" />
    );
  }

  if (signupSuccess) {
    ReactGA4.event({
      category: 'user',
      action: 'signup',
    });
    return <Redirect to="/" />;
  }

  return (
    <form
      className=""
      onSubmit={e => {
        handleSubmit(e);
      }}
    >
      <div className="flex flex-col gap-3 flex-grow">
        {/* Name */}
        <InputContainer
          label={
            <label className="text-neutral-600 text-xs h-10 flex items-center font-bold rounded-sm ml-1">
              Name
            </label>
          }
          placeHolder="Enter full name as in Identity Card/Passport"
          onChange={e => setName(e.target.value)}
          type="text"
        />
        {/* Email */}
        <div>
          <div className="flex flex-col flex-grow">
            <div className="flex flex-row items-center gap-2">
              <label
                className={`${
                  emailInUsed ? 'text-pink' : 'text-neutral-600'
                } text-xs h-10 flex items-center font-bold rounded-sm ml-1`}
                title="Hi! I'm tooltip"
              >
                Email
              </label>
              <CustomTooltip
                title="Your email will be used only for receiving payment confirmations and not for any marketing purposes."
                placement="right"
                sx={{
                  width: 200,
                }}
              >
                <InfoCircleOutlined className="text-purple" />
              </CustomTooltip>
            </div>
            <input
              placeholder={'Enter your email address'}
              className={`${
                emailInUsed ? 'text-pink' : 'text-neutral-600'
              } h-10 border border-neutral-600 rounded-[5px] placeholder-neutral-500 pl-2.5`}
              onChange={e => {
                setEmail(e.target.value);
                setEmailInUsed(false);
              }}
              type={email}
            />
          </div>
          {emailInUsed && (
            <span className="text-xs text-pink">Email is in use!</span>
          )}
        </div>

        {/* Password */}
        <div>
          <div className="flex flex-col flex-grow">
            <div className="flex flex-row items-center gap-2">
              <label
                className={`${
                  passwordInvalid ? 'text-pink' : 'text-neutral-600'
                } text-xs h-10 flex items-center font-bold rounded-sm ml-1`}
              >
                Password
              </label>
            </div>
            <input
              placeholder={'Enter password'}
              className={`${
                passwordInvalid ? 'text-pink' : 'text-neutral-600'
              } h-10 border border-neutral-600 rounded-[5px] placeholder-neutral-500`}
              onChange={e => {
                setPassword(e.target.value);
                setPasswordInalid(false);
              }}
              type={'password'}
            />
          </div>
          {passwordInvalid && (
            <span className="text-xs text-pink">Password invalid!</span>
          )}
        </div>

        {/* Confirm Password */}
        <div>
          <div className="flex flex-col flex-grow">
            <div className="flex flex-row items-center gap-2">
              <label
                className={`${
                  confirmPasswordValid === false
                    ? 'text-pink'
                    : 'text-neutral-600'
                } text-xs h-10 flex items-center font-bold rounded-sm ml-1`}
              >
                Confirm Password
              </label>
            </div>
            <input
              placeholder={'Enter same password'}
              className={`${
                confirmPasswordValid === false
                  ? 'text-pink'
                  : 'text-neutral-600'
              } h-10 border border-neutral-600 rounded-[5px] placeholder-neutral-500`}
              onChange={e => {
                setConfirmPassword(e.target.value);
                setConfirmPasswordValid(true);
              }}
              type={'password'}
            />
          </div>
          {confirmPasswordValid === false && (
            <span className="text-xs text-pink">Password not equal!</span>
          )}
        </div>

        <ButtonComponent
          className="bg-primary hover:bg-primary flex items-center justify-center text-center h-10 rounded-[5px]"
          children={
            <p className="text-white mb-0 text-base">Create new account</p>
          }
          type={'submit'}
        />
        <div className="flex flex-row items-center text-neutral-600 leading-7">
          <p className="mb-0">Have an existing account?</p>
          <a href="/login" className="mb-0 !text-primary">
            {' '}
            Sign In
          </a>
        </div>
      </div>
    </form>
  );
};
