import { useAuthValue } from 'app/AuthContext';
import { ButtonComponent } from 'app/components/Buttons/Button/Loadable';
import { InputComponent } from 'app/components/Forms/Input/Loadable';
import { ConfirmationModalComponent } from 'app/components/Modals/ConfirmationModal/Loadable';
import { PAGE_URL_ADMIN_PORTAL } from 'app/util/constants/pageUrls/adminPortal';
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  verifyBeforeUpdateEmail,
} from 'firebase/auth';
import React from 'react';
import { useHistory } from 'react-router-dom';

export function UpdateUserEmailPage() {
  const history = useHistory();
  const [email, setEmail] = React.useState<string>('');
  const [passwords, setPassword] = React.useState<string>('');
  const { currentUser } = useAuthValue()!;
  const [errorMessages, setErrorMessages] = React.useState<any>();
  const [isUpdateModalOpen, setIsUpdateModalOpen] =
    React.useState<boolean>(false);
  const cancelButtonRef = React.useRef(null);

  const handleEmailChange = e => {
    setEmail(e.target.value);
  };
  const handlePassChange = e => {
    setPassword(e.target.value);
  };
  const changeEmail = () => {
    if (currentUser && currentUser.email && email && passwords) {
      const credential = EmailAuthProvider.credential(
        currentUser.email,
        passwords,
      );
      reauthenticateWithCredential(currentUser, credential)
        .then(() => {
          verifyBeforeUpdateEmail(currentUser, email)
            .then(() => {
              setIsUpdateModalOpen(true);
            })
            .catch(error => {
              console.error(error);
            });
        })
        .catch(error => {
          if (error.code === 'auth/wrong-password') {
            const errors = 'Password is incorrect.';
            setErrorMessages(errors);
          } else {
            throw new Error(error.code);
          }
        });
    }
  };
  return (
    <>
      <div className="flex flex-row m-2 text-xl font-bold border-b-2">
        <div>My Profile / Edit My Profile / </div>
        <div className="ml-1 text-primary">Change Email</div>
      </div>
      <div className="flex flex-row items-center justify-center">
        <div className="flex flex-col items-end">
          <div className="relative flex flex-row">
            <p className="my-[15px] pr-[6px] text-right text-[14px] font-poppins">
              Password:
            </p>
            <InputComponent
              className="w-[400px] h-[32px] rounded-[2px] pl-[12px] pt-[8px] font-normal font-poppins leading-[20px] bg-neutral-200 border border-neutral-500 text-[#33424E] text-[14px]"
              type="password"
              name="password"
              value={passwords}
              id="password"
              onChange={handlePassChange}
              showError={errorMessages}
              error={errorMessages}
              placeholder="Enter Password"
            />
          </div>
          <div className="relative flex flex-row">
            <p className="my-[15px] pr-[6px] text-right text-[14px] font-poppins">
              New Email:
            </p>
            <InputComponent
              className="w-[400px] h-[32px] rounded-[2px] pl-[12px] pt-[8px] font-normal font-poppins leading-[20px] bg-neutral-200 border border-neutral-500 text-[#33424E] text-[14px]"
              type="text"
              name="email"
              id="email"
              onChange={handleEmailChange}
              value={email}
              placeholder="Enter New Email"
            />
          </div>
          <div className="flex flex-row">
            <ButtonComponent
              className="w-[133px] h-[32px] rounded-[20px] mx-2 text-[14px] whitespace-nowrap text-white bg-[#80B519]"
              children="Change Email"
              type="button"
              onClick={changeEmail}
            />
            <ButtonComponent
              className="w-[75px] h-[32px] rounded-[20px] mx-2 text-[14px] whitespace-nowrap text-white bg-[#ADB5BD]"
              children="Cancel"
              type="button"
              onClick={() => history.goBack()}
            />
          </div>
        </div>
      </div>
      <ConfirmationModalComponent
        showState={isUpdateModalOpen}
        showStateController={setIsUpdateModalOpen}
        cancelButtonRef={cancelButtonRef}
        header={'Please verify your email'}
        description="A confirmation link has been sent to your newly registered email."
        iconStyles={'w-[22px] h-[22px] text-[#80B519] my-1 mr-3'}
        primaryButtonText={'OK'}
        primaryButtonStyles={
          'items-end bg-[#80B519] w-[72px] h-[32px] rounded-[20px] text-[12px] whitespace-nowrap'
        }
        buttonFunction={() => {
          setIsUpdateModalOpen(false);
          history.push({
            pathname: PAGE_URL_ADMIN_PORTAL.accountManagement.profilePage,
            state: {
              from: history.location.pathname,
            },
          });
        }}
        secondaryButtonStyles={
          'items-end bg-neutral-500 w-[72px] h-[32px] rounded-[20px] text-[12px] whitespace-nowrap mr-2'
        }
      />
    </>
  );
}
