/**
 * Asynchronously loads the component for HomePage
 */

import { lazyLoad } from 'utils/loadable';

export const LandingModalComponent = lazyLoad(
  () => import('./index'),
  module => module.LandingModal,
);
