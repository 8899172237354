import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon';
import React, { InputHTMLAttributes } from 'react';

interface IInputWithIconProps extends InputHTMLAttributes<HTMLInputElement> {
  icon: React.NamedExoticComponent<
    AntdIconProps & React.RefAttributes<HTMLSpanElement>
  >;
  errorText?: string;
}

export const InputWithIcon: React.FC<IInputWithIconProps> = ({
  icon: Icon,
  errorText,
  className,
  type,
  name,
  value,
  id,
  placeholder,
  min,
  onChange,
  disabled,
}) => {
  return (
    <div>
      <div
        className={`w-96 p-4 border-neutral-600 bg-neutral-200 flex flex-row items-center gap-3 border ${className}`}
      >
        <Icon className={`text-2xl text-primary ${className}`} />
        <input
          type={type}
          name={name}
          value={value}
          id={id}
          placeholder={placeholder}
          min={min}
          onChange={onChange}
          disabled={disabled}
          className={`w-full bg-neutral-200  placeholder-neutral-500 text-neutral-500 outline-0 ${className}`}
        ></input>
      </div>
      <span className={`py-0 text-error text-red-600 ${className}`}>
        {errorText ?? ''}
      </span>
    </div>
  );
};
