import { FilterOutlined } from '@ant-design/icons';
import { getCategories } from 'app/api/CategoryService';
import { Category } from 'app/models/Category';
import React, { useEffect, useState } from 'react';

export const AdminCategoryFilterComponent: React.FC<{
  onSelectedCategoryChange: Function;
  variantId: string;
}> = ({ onSelectedCategoryChange, variantId }) => {
  const [foodCategories, setFoodCategories] = useState<Category[]>([]);
  const [nonFoodCategories, setNonFoodCategories] = useState<Category[]>([]);
  const [selectedCategoriesCodes, setSelectedCategoriesCodes] = useState<
    number[]
  >([]);

  //
  const filterClickedHandler = (code: number) => {
    const _existingSelectedCategoryIndex =
      selectedCategoriesCodes.indexOf(code);

    let _newSelectedCategory: number[] = [];

    if (_existingSelectedCategoryIndex !== -1) {
      // remove id
      _newSelectedCategory = selectedCategoriesCodes.filter(
        val => val !== code,
      );
    } else {
      _newSelectedCategory = [...selectedCategoriesCodes, code];
    }
    setSelectedCategoriesCodes(_newSelectedCategory);
  };

  // fetch categories for food and non-food
  useEffect(() => {
    getCategories({ nature: 'FOOD', variantIds: [variantId] }).then(data => {
      setFoodCategories(data.data);
    });
    getCategories({ nature: 'NONFOOD', variantIds: [variantId] }).then(data => {
      setNonFoodCategories(data.data);
    });
  }, []);

  // send the updated selected category codes every time it changes
  useEffect(() => {
    onSelectedCategoryChange(selectedCategoriesCodes);
  }, [selectedCategoriesCodes]);
  return (
    <>
      <div>
        <div className="flex flex-row gap-2 items-baseline">
          <FilterOutlined className="text-normal" />
          <div className="flex flex-col gap-2">
            <div className="flex flex-row gap-4 items-center">
              <div>Food</div>
              <div className="flex flex-row gap-2">
                {foodCategories &&
                  foodCategories.map(cat => (
                    <CategoryFilterChipComponet
                      code={cat.categoryCode}
                      name={cat.name}
                      onClick={filterClickedHandler}
                    ></CategoryFilterChipComponet>
                  ))}
              </div>
            </div>
            <div className="flex flex-row gap-4 items-center">
              <div>Non-Food</div>
              <div className="flex flex-row gap-2">
                {nonFoodCategories &&
                  nonFoodCategories.map(cat => (
                    <CategoryFilterChipComponet
                      code={cat.categoryCode}
                      name={cat.name}
                      onClick={filterClickedHandler}
                    ></CategoryFilterChipComponet>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const CategoryFilterChipComponet: React.FC<{
  code: number;
  name: string;
  onClick: Function;
}> = ({ code, name, onClick }) => {
  const [selected, setSelected] = useState<boolean>(false);

  const clickHandler = () => {
    setSelected(!selected);
    onClick(code);
  };

  return (
    <div
      className={
        'shadow-mdz flex flex-row items-center border-solid border border-neutral-500 bg-gray-100 rounded-full py-1 px-4 text-slate-400 cursor-pointer' +
        (selected ? ' !bg-dark-blue border-gray-700 !text-white' : '')
      }
      onClick={clickHandler}
    >
      {name}
    </div>
  );
};
