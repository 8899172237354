import { Product } from 'app/models/Product';
import {
  CREATE_MANY_PRODUCTS,
  CREATE_PRODUCT,
  DELETE_PRODUCT,
  EXPORT_PRODUCT,
  GET_PRODUCT,
  IMPORT_PRODUCTS,
  LIST_PRODUCT,
  UPDATE_MANY_PRODUCTS,
  UPDATE_PRODUCT,
} from 'app/util/constants/apiEndpoints/ProductEndpoints';
import { VariantIds } from 'app/VariantContext';
import qs from 'qs';
import { ExportFormatEnum } from 'types/admin/exportProducts';
import axiosInstance from 'utils/axios';

export const createProduct = async (data: Product): Promise<Product> => {
  let result;
  try {
    result = await axiosInstance.post(CREATE_PRODUCT(), data);
  } catch (e) {
    console.error('Error Creating Item ' + e);
  }
  return result.data.data;
};

export const createManyProducts = async (datas: Array<Product>) => {
  let result;
  try {
    result = await axiosInstance.post(CREATE_MANY_PRODUCTS(), datas);
  } catch (e) {
    console.error('Error Creating Items ' + e);
  }
  return result.data.data;
};

export const getProduct = async (id: number): Promise<Product> => {
  let result;
  try {
    result = await axiosInstance.get(GET_PRODUCT(id));

    if (!result.data.data) throw new Error('No Product Found');
  } catch (e) {
    console.error('Error Fetching Item ' + e);
    throw e;
  }
  return result.data.data;
};

/**
 *
 * @param filter Available Filters
 *              OR: IDs, categoryCode, Nature
 *              AND: Variant Ids, Status, skuCode, Name
 * @param sort
 * @param paginations
 * @returns {data: Array<Item>; meta: { count: number }}
 */
export const getProducts = async (
  filter?: {
    ids?: Array<number>;
    category_codes?: Array<number>;
    status?: 'ACTIVE' | 'INACTIVE';
    variant_ids?: Array<string>;
    nature?: 'FOOD' | 'NONFOOD';
    price_min?: number;
    price_max?: number;
    name?: string;
    sku_code?: string;
  },
  sort?: {
    sort_price?: 'asc' | 'desc';
    sort_name?: 'asc' | 'desc';
    sort_category?: 'asc' | 'desc';
    sort_quantity?: 'asc' | 'desc';
    sort_rank?: 'asc' | 'desc ';
  },
  paginations?: { page: number; limit: number },
): Promise<{ data: Array<Product>; meta: { count: number } }> => {
  let result;

  // nature: filter?.nature,
  try {
    result = await axiosInstance.get(LIST_PRODUCT(), {
      params: {
        ...filter,
        ids: filter?.ids?.length ? filter.ids.join(',') : undefined,
        category_codes: filter?.category_codes?.join(','),
        variant_ids: filter?.variant_ids?.join(','),
        // nature: filter?.nature,
        status: filter?.status,
        // name: filter?.name,
        // sku_code: filter?.sku_code,
        ...sort,
        ...paginations,
      },
    });
  } catch (e) {
    console.error(e);
  }
  return result.data;
};

export const updateProduct = async (
  id: number,
  data: any,
): Promise<Product> => {
  let result;
  try {
    result = await axiosInstance.patch(UPDATE_PRODUCT(id), data);
  } catch (e) {
    console.error('Error Updating Item ' + e);
  }

  return result.data.data;
};

export const updateManyProducts = async (ids: Array<number>, data: any) => {
  let result;
  try {
    result = await axiosInstance.patch(UPDATE_MANY_PRODUCTS(), { ids, data });
  } catch (e) {
    console.error('Error Updating Many Items ' + e);
  }
  return result.data;
};

export const deleteProduct = async (id: number) => {
  let result;
  try {
    result = await axiosInstance.delete(DELETE_PRODUCT(id));
  } catch (e) {
    console.error('Error Deleting Item ' + e);
  }
  return result.data;
};

export const importProducts = async (excel: File, zip: File) => {
  let result;
  try {
    const form = new FormData();

    form.append('excel', excel);
    form.append('zip', zip);

    result = await axiosInstance.put(IMPORT_PRODUCTS(), form);
  } catch (e) {
    console.error('Error Importing Item ' + e);
  }
  return result.data;
};

export const exportProducts = async (queryParams: {
  variantId: VariantIds;
  status: string[];
  fields: string[];
  format: ExportFormatEnum;
}) => {
  let result;
  try {
    result = await axiosInstance.get(
      `${EXPORT_PRODUCT()}?${qs.stringify(queryParams)}`,
      {},
    );
  } catch (e) {
    console.error(e);
  }
  return result.data;
};
