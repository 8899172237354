import { SuccessPaymentBox } from 'app/components/Ecommerce/PaymentBox/Success';
import { PAGE_TITLES_ECommerce } from 'app/util/constants/pageTitles/ecommerce';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

// interface Details {
//   customerName: string;
//   paymentMethod: string;
//   totalAmount: number;
// }
// interface Props {
//   transactionId: string;
//   paymentDetails: Details;
// }
export function ECommercePaymentSuccessPage() {
  const [donorName, setDonorName] = useState<string>('');
  const [dateReceived, setDateReceived] = useState<string>('');
  const [timeReceived, setTimeReceived] = useState<string>('');
  const [donationAmount, setDonationAmount] = useState<number>(0);
  const [paymentMethod, setPaymentMethod] = useState<string>('');
  const [transactionNumber, setTransactionNumber] = useState<string>('');

  // useEffect(() => {
  //   if (location.state) {
  //     const state = location.state as Props;
  //     setDonorName(state.paymentDetails.customerName);
  //     setDateReceived(new Date().toLocaleDateString());
  //     setTimeReceived(new Date().getTime());
  //     setDonationAmount(state.paymentDetails.totalAmount);
  //     setPaymentMethod(state.paymentDetails.paymentMethod);
  //     setTransactionNumber(state.transactionId);
  //   }
  // }, [location]);

  const unixTimestamp = new Date().getTime();
  const currentTime = new Date(unixTimestamp).toLocaleTimeString('en-SG', {
    hour12: true,
    hour: '2-digit',
    minute: '2-digit',
  });
  useEffect(() => {
    const pmtDetails = localStorage.getItem('paymentDetails');
    if (pmtDetails) {
      const pay = JSON.parse(pmtDetails);
      setDonorName(pay.paymentDetails.customerName);
      setDateReceived(new Date().toLocaleDateString());
      setTimeReceived(`${currentTime} SGT (GMT +8)`);
      setDonationAmount(pay.paymentDetails.totalAmount);
      setPaymentMethod(pay.paymentDetails.paymentMethod);
      setTransactionNumber(pay.transactionId);
    }
  }, [currentTime]);
  return (
    <>
      <Helmet>
        <title>{PAGE_TITLES_ECommerce.paymentSuccessPage}</title>
        <meta
          name="description"
          content={PAGE_TITLES_ECommerce.paymentSuccessPage}
        />
      </Helmet>
      <div className="grid min-h-screen py-4 justify-items-center md:py-12 lg:py-16 xl:pb-24 xl:pt-48 bg-[#009100]">
        <SuccessPaymentBox
          donorName={donorName}
          dateReceived={dateReceived}
          timeReceived={timeReceived}
          donationAmount={
            paymentMethod == 'PayNow' ? donationAmount : donationAmount / 100
          }
          paymentMethod={paymentMethod}
          transactionNumber={transactionNumber}
        />
      </div>
    </>
  );
}
