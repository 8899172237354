/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'sanitize.css/sanitize.css';

import { Auth0Provider } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { App } from 'app';
import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import reportWebVitals from 'reportWebVitals';

// Use consistent styling
// Import root app

// Initialize languages
const MOUNT_NODE = document.getElementById('root') as HTMLElement;

// sentry init
Sentry.init({
  dsn: 'https://cd700a532d5e409e97870372200f2323@o4504059057799168.ingest.sentry.io/4504059136180224',
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.NODE_ENV,
  tracesSampleRate: 1.0,
});

ReactDOMClient.createRoot(MOUNT_NODE!).render(
  <HelmetProvider>
    <Auth0Provider
      domain="dev-fo3er5wpn5laqqpf.us.auth0.com"
      clientId="1t5t0U8hgXU6YrfK4jvIXBtA613RHlcd"
      redirectUri={window.location.origin}
    >
      <App />
    </Auth0Provider>
  </HelmetProvider>,
);

// Hot reloadable translation json files
// if (module.hot) {
//   module.hot.accept(['./locales/i18n'], () => {
//     // No need to render the App again because i18next works with the hooks
//   });
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
