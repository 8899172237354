import { exportProducts } from 'app/api/ProductService';
import {
  Button,
  CANCEL_BUTTON_CLASSES,
  CONFIRM_BUTTON_CLASSES,
} from 'app/components/Buttons/Button';
import { CustomLink } from 'app/components/CustomLink';
import { InputDropdown } from 'app/components/Forms/InputDropdown';
import { InputDropdownMultiselect } from 'app/components/Forms/InputDropdownMultiselect';
import { ConfirmationModalComponent } from 'app/components/Modals/ConfirmationModal/Loadable';
import { PAGE_URL_ADMIN_PORTAL } from 'app/util/constants/pageUrls/adminPortal';
import { VariantIds } from 'app/VariantContext';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import React from 'react';
import {
  ExportFormatEnum,
  ExportFormatToLabelMap,
  ExportStatusEnum,
  ExportStatusEnumToLabelMap,
  ExportStatusEnumToStringMap,
} from 'types/admin/exportProducts';
import * as XLSX from 'xlsx';

type ExportCatalogueFormProps = {
  className?: string;
  variantId: VariantIds;
};

const downloadExcel = (data, format: 'csv' | 'xlsx', fileName: string) => {
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(data);
  XLSX.utils.book_append_sheet(wb, ws);
  XLSX.writeFile(wb, `${fileName}.${format}`, {
    bookType: format,
  });
};

export const ExportCatalogueForm: React.FC<ExportCatalogueFormProps> = ({
  className,
  variantId,
}) => {
  const [isButtonDisabled, setIsButtonDisabled] =
    React.useState<boolean>(false);
  const [isAlertModalOpen, setIsAlertModalOpen] =
    React.useState<boolean>(false);

  const [status, setStatus] = React.useState<ExportStatusEnum[]>([
    ExportStatusEnum.ACTIVE,
  ]);
  const [fields, setFields] = React.useState<string[]>([]);
  const [format, setFormat] = React.useState<ExportFormatEnum>(
    ExportFormatEnum.XLSX,
  );

  const handleSubmit = e => {
    e.preventDefault();
    const queryParams = {
      variantId: variantId,
      status: status.map(s => ExportStatusEnumToStringMap[s]),
      fields: fields,
      format: format,
    };

    setIsButtonDisabled(true);
    // Generate the file in the frontend.
    exportProducts(queryParams)
      .then(async response => {
        var zip = new JSZip();
        const data = response.data;
        const excelData = data.excelData;
        const imgData: { id: number; images: { name: string; data: any }[] }[] =
          data.imageData ?? [];
        if (fields.includes('images')) {
          excelData.map(item => {
            item.images = item.images?.join(', ');
          });
        }

        // Generate Excel
        downloadExcel(
          excelData,
          format === ExportFormatEnum.CSV ? 'csv' : 'xlsx',
          'Catalogue_Export',
        );
        // Generate Zip
        imgData.forEach(img => {
          img.images.forEach(imgItm => {
            const imgName = imgItm.name;
            const imgScr = imgItm.data.data;
            const folder = zip.folder(img.id.toString());
            folder?.file(imgName, imgScr);
          });
        });
        if (imgData.length > 0) {
          const zipFile = await zip.generateAsync({ type: 'blob' });
          var fileName = 'Catalogue_Export_Images.zip';
          saveAs(zipFile, fileName);
        }
      })
      .catch(error => {
        setIsAlertModalOpen(true);
        console.error(error);
      })
      .finally(() => {
        setIsButtonDisabled(false);
      });
  };

  return (
    <form
      className="font-medium tracking-wide text-black black flex flex-col items-center justify-center"
      onSubmit={handleSubmit}
    >
      <InputDropdownMultiselect
        title="Status:"
        options={[ExportStatusEnum.ACTIVE, ExportStatusEnum.INACTIVE].map(
          key => ({
            label: ExportStatusEnumToLabelMap[key],
            value: key,
          }),
        )}
        onChange={setStatus}
        values={status}
      />
      <InputDropdownMultiselect
        title="Fields:"
        options={[
          { label: 'Product Type', value: 'productType' },
          { label: 'Catalogue Code', value: 'catalogueCode' },
          { label: 'Category', value: 'categoryName' },
          { label: 'Product Name', value: 'name' },
          { label: 'Warehouse SKU Code', value: 'skuCode' },
          { label: 'Description', value: 'description' },
          { label: 'Price', value: 'price' },
          { label: 'Target Quantity', value: 'targetQuantity' },
          { label: 'Images', value: 'images' },
          { label: 'Status', value: 'status' },
        ]}
        onChange={setFields}
        values={fields}
      />
      <InputDropdown
        title="Format:"
        options={[ExportFormatEnum.CSV, ExportFormatEnum.XLSX].map(key => ({
          label: ExportFormatToLabelMap[key],
          value: key,
        }))}
        onChange={setFormat}
        value={format}
      />
      <div className="w-5/6 sm:w-3/6 grid grid-cols-4 gap-4 py-2">
        <span className="col-span-1"></span>
        <div className="col-span-3">
          <div className="flex w-full gap-6">
            <Button
              children={<>Export</>}
              type={'submit'}
              className={CONFIRM_BUTTON_CLASSES}
              disabled={isButtonDisabled}
            />
            <Button
              children={
                <CustomLink
                  to={PAGE_URL_ADMIN_PORTAL.productManagement.viewPage(
                    VariantIds.A,
                  )}
                >
                  Cancel
                </CustomLink>
              }
              type={'button'}
              className={CANCEL_BUTTON_CLASSES}
            />
          </div>
        </div>
      </div>
      <ConfirmationModalComponent
        showState={isAlertModalOpen}
        showStateController={setIsAlertModalOpen}
        header={'Export failed.'}
        iconStyles={'w-[22px] h-[22px] text-primary my-1 mr-3'}
        primaryButtonText={'Ok'}
        primaryButtonStyles={
          'items-end bg-primary w-[72px] h-[32px] rounded-[20px] text-[12px] text-white whitespace-nowrap'
        }
        secondaryButtonStyles={
          'items-end bg-neutral-500 w-[72px] h-[32px] rounded-[20px] text-[12px] text-white whitespace-nowrap'
        }
        buttonFunction={() => setIsAlertModalOpen(false)}
      />
    </form>
  );
};
