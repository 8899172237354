import { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'app/components/Buttons/Button';
import { EcommerceLoginForm } from 'app/pages/ECommerce/User/LoginPage/components/EcommerceLoginForm';
import React from 'react';
import { Helmet } from 'react-helmet-async';

import { PAGE_TITLES_ECommerce } from 'app/util/constants/pageTitles/ecommerce';
import { ReactComponent as GoogleIcon } from './assets/GoogleIcon.svg';

export function EcommerceLoginPage() {
  const { loginWithRedirect } = useAuth0();

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLES_ECommerce.loginPage}</title>
        <meta name="description" content={PAGE_TITLES_ECommerce.loginPage} />
      </Helmet>
      <div className="relative bg-primary h-[100vh] rounded-[10px]">
        {/* Background picture */}
        <div className="hidden absolute lg:flex items-center h-[100vh] right-0 py-4 pr-4">
          <img
            src="ecommerce_login.png"
            alt="ecommerce_login"
            className="right-0 h-full z-0"
          />
        </div>
        {/* Login by google or email  */}
        <div className="flex relative top-[120px] h-full z-50 mx-4 lg:mx-0 lg:pl-44 2xl:pl-40 ">
          <div className="flex flex-col flex-grow bg-white lg:max-w-[573px] lg:h-[748px] text-center py-2 px-8 lg:py-20 lg:px-16 rounded-[10px]">
            <div className="w-full flex flex-col gap-4">
              <div className="flex flex-col">
                <h2 className="text-2xl lg:text-[40px] leading-[64px] font-bold m-0">
                  Welcome back
                </h2>
                <span className="text-base leading-7 m-0 text-neutral-500">
                  Please enter your details.
                </span>
              </div>
              {/* Login by google */}
              <Button
                className="bg-white hover:bg-white flex items-center justify-center h-[69px] drop-shadow-xl rounded-[10px]"
                onClick={() => loginWithRedirect()}
                children={
                  <a
                    href="/"
                    className="flex flex-row items-center justify-center gap-4"
                  >
                    <GoogleIcon />
                    <span className="text-black/60 ">Continue with Google</span>
                  </a>
                }
                type={'button'}
              />
            </div>
            <div className="relative flex py-5 items-center">
              <div className="flex-grow border-t border-gray-400"></div>
              <span className="w-40 text-xl">or</span>
              <div className="flex-grow border-t border-gray-400"></div>
            </div>
            {/* Login by email */}
            <EcommerceLoginForm />
          </div>
        </div>
      </div>
    </>
  );
}
