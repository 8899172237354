import { getCategories } from 'app/api/CategoryService';
import { getProducts } from 'app/api/ProductService';
import { useAuthValue } from 'app/AuthContext';
import { ProductCardComponent } from 'app/components/Ecommerce/Cards/Loadable';
import { EcommerceSearchBar } from 'app/components/Search';
import { ICartItem } from 'app/models/CartItem';
import { Product } from 'app/models/Product';
import { CarouselCategoryProps } from 'app/pages/ECommerce/Landing/HomePage/components/ItemSectionComponent/components/CategoryCarouselComponent';
import { CarouselComponent } from 'app/pages/ECommerce/Landing/HomePage/components/ItemSectionComponent/components/CategoryCarouselComponent/Loadable';
import { ProductPaginationComponent } from 'app/pages/ECommerce/Landing/HomePage/components/ItemSectionComponent/components/ProductPaginationComponent';
import { cartDefaultKey, cartState } from 'app/state/shoppingCart/atoms';
import { useAddToCart } from 'app/state/shoppingCart/hooks';
import { IMAGE_PRODUCT } from 'app/util/constants/apiEndpoints/ProductEndpoints';
import useWindowDimensions from 'app/util/useWindowDimensions';
import { useVariantValue } from 'app/VariantContext';
import React from 'react';
import { useRecoilValue } from 'recoil';

type loadFromDBFunc = () => void;

export const FilteredCarousel: React.FC = () => {
  const variantId = useVariantValue();
  const { width } = useWindowDimensions();

  // Cart
  const { currentUserId } = useAuthValue()!;
  const cartItems = useRecoilValue(cartState(currentUserId || cartDefaultKey));
  const addToCart = useAddToCart(currentUserId || cartDefaultKey);

  // Pagination
  const numOfRows: number = 4;
  const COL_NO_SM: number = 2;
  const COL_NO_MD: number = 3;
  const COL_NO_LG: number = 4;
  const COL_NO_XL: number = 5;
  const COL_NO_2XL: number = 4;

  const [numOfCols, setNumOfCols] = React.useState<number>(5);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [totalPages, setTotalPages] = React.useState<number>(1);
  const [recordsPerPage, setRecordsPerPage] = React.useState<number>(15);

  const setNumberOfColsBasedOnWidth: (width: number) => void = width => {
    if (width <= 640) {
      // sm
      setNumOfCols(COL_NO_SM);
    } else if (width <= 768) {
      // md
      setNumOfCols(COL_NO_MD);
    } else if (width <= 1024) {
      // lg
      setNumOfCols(COL_NO_LG);
    } else if (width <= 1280) {
      // xl
      setNumOfCols(COL_NO_XL);
    } else {
      // 2xl
      setNumOfCols(COL_NO_2XL);
    }
    setRecordsPerPage(numOfRows * numOfCols);
  };

  React.useEffect(() => setNumberOfColsBasedOnWidth(width), [width]);

  // Filter & sort
  type sortDirection = 'asc' | 'desc';
  type sortResult = { fieldName: string; sortDirection: sortDirection };

  const [priceMin, setPriceMin] = React.useState<number>(0);
  const [priceMax, setPriceMax] = React.useState<number>();
  const [search, setSearch] = React.useState<string | undefined>();
  // const [sort, setSort] = React.useState<sortResult>({
  //   fieldName: 'price',
  //   sortDirection: 'asc',
  // });
  const [sort, setSort] = React.useState<{
    [sort_property: string]: 'asc' | 'desc';
  }>({ sort_quantity: 'asc' });

  const [categories, setCategories] = React.useState<CarouselCategoryProps[]>(
    [],
  );
  const [selectedCategoriesCodes, setSelectedCategoriesCodes] = React.useState<
    number[]
  >([]);

  const [items, setItems] = React.useState<Product[]>([]);

  // Sets the selected category codes from the category carousel
  const handleSelectCategory = selectedCategories => {
    setSelectedCategoriesCodes(selectedCategories);
  };

  // Sets the filter price from the filter component
  const filterHandler = (range: [number, number | undefined]) => {
    setPriceMin(range[0]);
    setPriceMax(range[1]);
  };

  /**
   * Sorts the items based on the sort result
   * @param sortResult
   */
  const sortHandler = (sortResult: {
    field: 'sort_name' | 'sort_price' | 'sort_quantity';
    sort: 'asc' | 'desc';
  }) => {
    setSort({ [sortResult.field]: sortResult.sort });
  };

  const searcHandler = (search: string) => {
    setSearch(search);
  };

  const loadCategoriesFromDB: loadFromDBFunc = () => {
    getCategories({
      variantIds: [variantId],
    }).then(res => {
      const categoryData = res.data.map(category => {
        return {
          ...category,
          imageUrl: `${process.env.REACT_APP_S3_URL}uploads/category/${
            category.categoryCode
          }/${category.image ? category.image.replace(' ', '+') : ''}`,
          selected: false,
        };
      });
      setCategories(categoryData);
    });
  };

  // here got search
  const loadItemsFromDB: loadFromDBFunc = () => {
    getProducts(
      {
        variant_ids: [variantId],
        price_min: priceMin,
        price_max: priceMax,
        category_codes: selectedCategoriesCodes.length
          ? selectedCategoriesCodes
          : undefined,
        status: 'ACTIVE',
        name: search?.length ? search : undefined,
      },
      sort,
      { page: currentPage, limit: numOfCols * numOfRows },
    ).then(res => {
      setTotalPages(Math.ceil(res.meta.count / (numOfCols * numOfRows)));

      setItems(res.data);
    });
  };

  // Loading Initial Categories and Items
  React.useEffect(() => {
    loadCategoriesFromDB();
    loadItemsFromDB();
  }, []);

  React.useEffect(() => {
    loadItemsFromDB();
  }, [
    currentPage,
    sort,
    priceMin,
    priceMax,
    variantId,
    search,
    selectedCategoriesCodes,
  ]);

  const getCartQuantity = (item: Product, cartItems: ICartItem[]) => {
    let foundItem = cartItems.find(cartItem => item.id === cartItem.id);
    return foundItem ? foundItem.cartQuantity : 0;
  };

  return (
    <div className="container mx-auto">
      <div className="py-3">
        <CarouselComponent
          categories={categories}
          onSelectedCategoryChange={handleSelectCategory}
        />
      </div>
      <div className="flex flex-col items-center z-[91] fixed top-[31px] md:top-[34px] lg:top-[34px] xl:top-[34px] right-[42%] xxs:right-[38%] xs:right-[35%] sm:right-[42%] md:right-[28%] lg:right-[29%] xl:right-[28%] 2xl:right-[30%] xxl:right-[36%]">
        <EcommerceSearchBar
          onSearch={searcHandler}
          variantId={variantId}
        ></EcommerceSearchBar>
      </div>
      {/* <ProductFilterSortComponent
        onFilterChange={filterHandler}
        onSortChange={sortHandler}
      /> */}
      <div className="py-5 m-5">
        {items ? (
          <div
            className={`grid grid-cols-${COL_NO_SM} gap-4 md:grid-cols-${COL_NO_MD} lg:grid-cols-${COL_NO_LG} xl:grid-cols-${COL_NO_XL} 2xl:grid-cols-${COL_NO_2XL}`}
          >
            {items.map((item, idx) => {
              return (
                <ProductCardComponent
                  key={`productCard_item${item.id}`}
                  id={item.id}
                  name={item.name}
                  description={item.description}
                  imgURLs={
                    item.images?.length
                      ? item.images!.map(img => IMAGE_PRODUCT(item.id, img))
                      : [IMAGE_PRODUCT(item.id, undefined)]
                  }
                  price={item.price}
                  addToCart={addToCart}
                  cartQuantity={getCartQuantity(item, cartItems)}
                  product={item}
                />
              );
            })}
          </div>
        ) : null}
      </div>
      <ProductPaginationComponent
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
    </div>
  );
};
