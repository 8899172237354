import { ShoppingCartOutlined } from '@ant-design/icons';
import { ICartItem } from 'app/models/CartItem';
import { CartItem } from 'app/pages/ECommerce/Shop/ShoppingCartPage/components/ShoppingCart/CartItem';
import { CartManipulatorFunc } from 'app/state/shoppingCart/hooks';
import { PAGE_URL_ECommerce } from 'app/util/constants/pageUrls/ecommerce';
import React from 'react';
import { useHistory } from 'react-router-dom';

export interface IUsesAddToCartManipulatorFunc {
  addToCart: CartManipulatorFunc;
}
export interface IUsesRemoveFromCartManipulatorFunc {
  removeFromCart: CartManipulatorFunc;
}
export interface IUsesCartManipulatorFuncs
  extends IUsesAddToCartManipulatorFunc,
    IUsesRemoveFromCartManipulatorFunc {}

interface ICartProps extends IUsesCartManipulatorFuncs {
  cartItems: ICartItem[];
}

export const Cart: React.FC<ICartProps> = ({
  cartItems,
  addToCart,
  removeFromCart,
}) => {
  const history = useHistory();
  const calculateTotal = (items: ICartItem[]) =>
    items.reduce(
      (ack: number, item) => ack + item.cartQuantity * item.price,
      0,
    );
  return (
    <>
      <div className="flex flex-col h-full w-full gap-2 p-3 md:p-6 lg:p-6 bg-white rounded-xl overflow-auto">
        <div className="flex flex-row gap-2 text-xl font-bold py-2">
          <ShoppingCartOutlined className="text-[30px] text-[#009100]" />
          <h1 className="md:text-[24px]">My Shopping Cart</h1>
        </div>
        <div className="flex flex-col gap-2 border-[#ADB5BD]">
          {cartItems.length === 0 ? <p>No items in cart.</p> : null}
          {cartItems.map(item => (
            <CartItem
              key={item.id}
              item={item}
              addToCart={addToCart}
              removeFromCart={removeFromCart}
            />
          ))}
        </div>
        <div className="flex flex-row justify-between px-3 xs:px-4 sm:px-4 lg:px-4 py-2 xs:py-4 sm:py-4 lg:py-4 text-sm xs:text-lg sm:text-xl md:text-xl text-white bg-tosca rounded-xl">
          <h2>
            Total (
            {cartItems.reduce(
              (acc: number, cur: ICartItem) => acc + cur.cartQuantity,
              0,
            )}{' '}
            items)
          </h2>
          <h3>SGD {calculateTotal(cartItems).toFixed(2)}</h3>
        </div>
        <div className="flex flex-row items-center justify-end gap-2">
          <button
            type="button"
            className={`p-2 xs:p-4 s:p-4 md:p-4 rounded-full  ${
              cartItems.length === 0
                ? 'bg-gray-400'
                : 'bg-primary hover:bg-primary'
            }`}
            onClick={() =>
              history.push({
                pathname: PAGE_URL_ECommerce.checkoutPage,
                state: {
                  from: history.location.pathname,
                },
              })
            }
            disabled={cartItems.length === 0}
          >
            <div>
              <p className="text-white">Checkout</p>
            </div>
          </button>
        </div>
      </div>
    </>
  );
};
