import {
  DashboardOutlined,
  DownOutlined,
  ImportOutlined,
  ShopOutlined,
  TagsOutlined,
} from '@ant-design/icons';
import { Disclosure } from '@headlessui/react';
import { useAuthValue } from 'app/AuthContext';
import { CustomLink } from 'app/components/CustomLink';
import { PAGE_URL_ADMIN_PORTAL } from 'app/util/constants/pageUrls/adminPortal';
import { VariantIds } from 'app/VariantContext';
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

interface ISidebarProps {
  className?: string;
}

enum MenuDropdownRowName {
  Dashboard = 'Dashboard',
  CategoryManagement = 'Category Management',
  Products = 'Products',
  Import = 'Import',
}

interface DropdownMenuRowType {
  name: string;
  variantId: VariantIds;
}

function checkForCurrentActivePage(url: string, path: string): boolean {
  // Handle sidebar text color
  let regex;
  // Since products and import share almost the same url pattern
  // we have to handle them seperately.
  if (url.includes('products') || path.includes('products')) {
    regex = /\/admin\/[a-z]+\/[0-9]+\/[a-z]+/g;
  } else {
    regex = /\/admin\/[a-z]+/g;
  }
  const rowUrl = url.match(regex);
  const pathUrl = path.match(regex);
  if (rowUrl === null && pathUrl === null) return true;
  if (!rowUrl || !pathUrl) return false;
  return rowUrl[0] === pathUrl[0];
}

const DropdownMenuRow: React.FC<DropdownMenuRowType> = ({
  name,
  variantId,
}) => {
  const params: { variantId: string } = useParams();
  const location = useLocation();
  const currentPath = location.pathname;

  const menuRows = [
    {
      name: MenuDropdownRowName.Dashboard,
      icon: <DashboardOutlined className="h-3.5 w-3.5" />,
      url: PAGE_URL_ADMIN_PORTAL.dashboard.viewPage(variantId),
    },
    {
      name: MenuDropdownRowName.CategoryManagement,
      icon: <TagsOutlined className="h-3.5 w-3.5" />,
      url: PAGE_URL_ADMIN_PORTAL.categoryManagement.viewPage(variantId),
    },
    {
      name: MenuDropdownRowName.Products,
      icon: <ShopOutlined className="h-3.5 w-3.5" />,
      url: PAGE_URL_ADMIN_PORTAL.productManagement.viewPage(variantId),
    },
    {
      name: MenuDropdownRowName.Import,
      icon: <ImportOutlined className="h-3.5 w-3.5" />,
      url: PAGE_URL_ADMIN_PORTAL.productManagement.uploadPage(variantId),
    },
  ];
  return (
    <div className="flex flex-col w-full px-4">
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex flex-row items-center justify-between h-10 font-bold">
              <span
                className={`text-sm leading-5.5 ${
                  variantId === params.variantId ? 'text-primary' : ''
                }`}
              >
                {name}
              </span>
              <DownOutlined className="h-[9px] w-[10px]" />
            </Disclosure.Button>
            <Disclosure.Panel className="">
              {() => (
                <div className="flex flex-col gap-2.5 px-4">
                  {menuRows.map((row, index) => (
                    <div key={index}>
                      <div
                        className={`h-10 flex items-center gap-2.5 text-white hover:!text-primary ${
                          variantId === params.variantId &&
                          checkForCurrentActivePage(row.url, currentPath)
                            ? '!text-primary'
                            : '!text-white'
                        }`}
                        key={row.name}
                      >
                        {row.icon}
                        <CustomLink
                          to={row.url}
                          className={`text-sm leading-5.5 hover:!text-primary ${
                            variantId === params.variantId &&
                            checkForCurrentActivePage(row.url, currentPath)
                              ? '!text-primary'
                              : '!text-white'
                          }`}
                        >
                          {row.name}
                        </CustomLink>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export const Sidebar: React.FC<ISidebarProps> = ({ className }) => {
  const menus = [
    { name: 'Variant 1', variantId: VariantIds.A },
    { name: 'Variant 2', variantId: VariantIds.B },
    { name: 'Variant 3', variantId: VariantIds.C },
  ];

  // Get User name
  const { currentUser } = useAuthValue()!;
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <div className="flex flex-col justify-between w-full h-full min-h-screen transition duration-300 text-neutral-200 bg-dark-blue">
      <div>
        <div className="pl-2.5 py-3 border-b border-[#D9D9D9]/[0.21] t">
          <CustomLink to={PAGE_URL_ADMIN_PORTAL.accountManagement.profilePage}>
            <span className="text-2xl leading-6 text-white weigth-700 hover:text-primary">
              {currentUser && currentUser.displayName
                ? currentUser.displayName
                : 'Admin'}
            </span>
          </CustomLink>
        </div>
        {menus &&
          menus.map((menu, index) => (
            <DropdownMenuRow
              name={menu.name}
              key={index}
              variantId={menu.variantId}
            />
          ))}
        <div
          className={`w-full flex flex-col px-4 py-2 font-bold text-sm leading-5.5`}
        >
          <CustomLink
            to={PAGE_URL_ADMIN_PORTAL.accountManagement.accountsPage}
            className={`hover:text-primary ${
              currentPath.includes('/admin/accounts')
                ? 'text-primary'
                : 'text-white '
            }`}
          >
            Admin Accounts
          </CustomLink>
        </div>
      </div>
      <div className="pb-4 pl-6">
        <CustomLink
          to={PAGE_URL_ADMIN_PORTAL.accountManagement.logoutPage}
          className="text-white hover:text-primary"
        >
          <u>Log Out</u>
        </CustomLink>
      </div>
    </div>
  );
};
