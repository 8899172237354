/**
 * Asynchronously loads the component for Container
 */

import { lazyLoad } from 'utils/loadable';

export const CheckoutComponent = lazyLoad(
  () => import('./index'),
  module => module.Checkout,
);
