import React from 'react';
import { Sidebar } from 'app/components/Navigation/Sidebar';

export const AdminPortalLayout = ({ children }) => {
  return (
    <div className="flex flex-row">
      <aside className="w-1/6">
        <Sidebar />
      </aside>
      <aside className="w-5/6">{children}</aside>
    </div>
  );
};
