import { AuthGuard, userTypes } from 'app/guards/AuthGuard';
import { AdminPortalLayout } from 'app/layouts/AdminPortal';
import { EcommerceLayout } from 'app/layouts/Ecommerce';
import { AdminPage } from 'app/pages/Admin/AdminPage';
import { CreateCategoryPage } from 'app/pages/Admin/Category/Create/CreateCategoryPage';
import { AdminCategoryUpdatePage } from 'app/pages/Admin/Category/Update/UpdateCategoryPage';
import { CategoryManagementPage } from 'app/pages/Admin/Category/View/CategoryManagementPage';
import { CreateFoodPage } from 'app/pages/Admin/Product/Create/CreateProductPage';
import { ImportProductPage } from 'app/pages/Admin/Product/Create/ImportProductPage';
import { DeleteProductPage } from 'app/pages/Admin/Product/Delete/DeleteProductPage';
import { UpdateProductPage } from 'app/pages/Admin/Product/Update/UpdateProductPage';
import { ExportProductsPage } from 'app/pages/Admin/Product/View/ExportProductPage';
import { ProductManagementPage } from 'app/pages/Admin/Product/View/ProductManagementPage';
import { AdminCreateUserPage } from 'app/pages/Admin/User/Create/CreateUserPage';
import { DeactivateUserPage } from 'app/pages/Admin/User/Delete/DeactivateUserPage';
import { DeleteUserPage } from 'app/pages/Admin/User/Delete/DeleteUserPage';
import { FirstLoginUserPage } from 'app/pages/Admin/User/Login/FirstLoginUserPage';
import { LoginUserPage } from 'app/pages/Admin/User/Login/LoginUserPage';
import { LogoutPage } from 'app/pages/LogoutPage';
import { ForgotPasswordPage } from 'app/pages/Admin/User/Update/ForgotPasswordPage';
import { UpdatePage } from 'app/pages/Admin/User/Update/UpdatePage';
import { UpdateUserEmailPage } from 'app/pages/Admin/User/Update/UpdateUserEmailPage';
import { UpdateUserPage } from 'app/pages/Admin/User/Update/UpdateUserPage';
import { UpdateUserPasswordPage } from 'app/pages/Admin/User/Update/UpdateUserPasswordPage';
import { UserManagementPage } from 'app/pages/Admin/User/View/UserManagementPage';
import { UserPage } from 'app/pages/Admin/User/View/UserPage';
import { UpdateVariantPage } from 'app/pages/Admin/Variant/Update/UpdateVariantPage';
import { EcommerceLandingPage } from 'app/pages/ECommerce/Landing/HomePage';
import { CheckoutPage } from 'app/pages/ECommerce/Payment/CheckoutPage';
import { ECommercePaymentFailurePage } from 'app/pages/ECommerce/Payment/FailurePage';
import { PaymentPage } from 'app/pages/ECommerce/Payment/PaymentPage';
import { ECommercePaymentSuccessPage } from 'app/pages/ECommerce/Payment/SuccessPage';
import { ShoppingCartPage } from 'app/pages/ECommerce/Shop/ShoppingCartPage';
import { EcommerceLoginPage } from 'app/pages/ECommerce/User/LoginPage';
import { EcommerceSignupPage } from 'app/pages/ECommerce/User/signUpPage';
import { NotFoundPage } from 'app/pages/NotFoundPage';
import { SentryPage } from 'app/pages/SentryPage';
import { PAGE_URL_ADMIN_PORTAL } from 'app/util/constants/pageUrls/adminPortal';
import { ForgotPasswordForm } from 'app/pages/Admin/User/Update/ForgotPasswordPage/components/ForgotPasswordForm';

import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

export const MainSwitch: React.FC = () => {
  return (
    <Switch>
      {/* Ecommerce */}
      <Route exact path={`/`}>
        <EcommerceLayout showHeader showFooter>
          <EcommerceLandingPage />
        </EcommerceLayout>
      </Route>
      <Route exact path={`/cart`}>
        <EcommerceLayout showHeader>
          <ShoppingCartPage />
        </EcommerceLayout>
      </Route>
      <Route exact path={`/checkout`}>
        <EcommerceLayout showHeader>
          <CheckoutPage />
        </EcommerceLayout>
      </Route>
      <Route exact path={`/signup`}>
        <EcommerceLayout showHeader>
          <EcommerceSignupPage />
        </EcommerceLayout>
      </Route>
      <Route exact path={`/login`}>
        <EcommerceLayout showHeader>
          <EcommerceLoginPage />
        </EcommerceLayout>
      </Route>
      <Route exact path={`/logout`}>
        <EcommerceLayout showHeader>
          <LogoutPage />
        </EcommerceLayout>
      </Route>
      <Route exact path={`/payment`}>
        <EcommerceLayout showHeader>
          <PaymentPage />
        </EcommerceLayout>
      </Route>
      <Route exact path={`/payment/success`}>
        <EcommerceLayout showHeader>
          <ECommercePaymentSuccessPage />
        </EcommerceLayout>
      </Route>
      <Route exact path={`/payment/failure`}>
        <EcommerceLayout showHeader>
          <ECommercePaymentFailurePage />
        </EcommerceLayout>
      </Route>
      <Route exact path={`/sentry`}>
        <EcommerceLayout showHeader>
          <SentryPage />
        </EcommerceLayout>
      </Route>
      {/* Admin Panel */}
      <Route exact path={`/admin/forgotpassword`}>
        <ForgotPasswordForm />
      </Route>
      <AuthGuard
        exact
        path={`/admin`}
        userTypesRequired={[userTypes.FOODBANK, userTypes.RESEARCH]}
      >
        <Redirect to="/admin/dashboard/01" />
      </AuthGuard>
      {/* Account Management */}
      <Route
        exact
        path={PAGE_URL_ADMIN_PORTAL.accountManagement.loginPage}
        component={LoginUserPage}
      />
      <Route
        exact
        path={PAGE_URL_ADMIN_PORTAL.accountManagement.firstLoginPage}
        component={FirstLoginUserPage}
      />
      <Route
        exact
        path={PAGE_URL_ADMIN_PORTAL.accountManagement.forgetPasswordPage}
        component={ForgotPasswordPage}
      />
      <AuthGuard
        exact
        path={`/admin/accounts/profile`}
        Component={
          <AdminPortalLayout>
            <UserPage />
          </AdminPortalLayout>
        }
        userTypesRequired={[userTypes.FOODBANK]}
      >
        <AdminPortalLayout>
          <UserPage />
        </AdminPortalLayout>
      </AuthGuard>
      <AuthGuard
        exact
        path={`/admin/accounts/profile/password`}
        Component={
          <AdminPortalLayout>
            <UpdateUserPasswordPage />
          </AdminPortalLayout>
        }
        userTypesRequired={[userTypes.FOODBANK]}
      >
        <AdminPortalLayout>
          <UpdateUserPasswordPage />
        </AdminPortalLayout>
      </AuthGuard>
      <AuthGuard
        exact
        path={`/admin/accounts/profile/email`}
        Component={
          <AdminPortalLayout>
            <UpdateUserEmailPage />
          </AdminPortalLayout>
        }
        userTypesRequired={[userTypes.FOODBANK]}
      >
        <AdminPortalLayout>
          <UpdateUserEmailPage />
        </AdminPortalLayout>
      </AuthGuard>
      <AuthGuard
        exact
        path={`/admin/accounts/delete`}
        Component={
          <AdminPortalLayout>
            <DeleteUserPage />
          </AdminPortalLayout>
        }
        userTypesRequired={[userTypes.FOODBANK]}
      >
        <AdminPortalLayout>
          <DeleteUserPage />
        </AdminPortalLayout>
      </AuthGuard>
      <AuthGuard
        exact
        path={`/admin/accounts/deactivate`}
        Component={
          <AdminPortalLayout>
            <DeactivateUserPage />
          </AdminPortalLayout>
        }
        userTypesRequired={[userTypes.FOODBANK]}
      >
        <AdminPortalLayout>
          <DeactivateUserPage />
        </AdminPortalLayout>
      </AuthGuard>
      <AuthGuard
        exact
        path={`/admin/accounts/create`}
        Component={
          <AdminPortalLayout>
            <AdminCreateUserPage />
          </AdminPortalLayout>
        }
        userTypesRequired={[userTypes.FOODBANK]}
      >
        <AdminPortalLayout>
          <AdminCreateUserPage />
        </AdminPortalLayout>
      </AuthGuard>
      <AuthGuard
        exact
        path={`/admin/accounts/update`}
        Component={
          <AdminPortalLayout>
            <UpdateUserPage />
          </AdminPortalLayout>
        }
        userTypesRequired={[userTypes.FOODBANK]}
      >
        <AdminPortalLayout>
          <UpdateUserPage />
        </AdminPortalLayout>
      </AuthGuard>
      <AuthGuard
        exact
        path={`/admin/accounts/update/:uid`}
        Component={
          <AdminPortalLayout>
            <UpdatePage />
          </AdminPortalLayout>
        }
        userTypesRequired={[userTypes.FOODBANK]}
      >
        <AdminPortalLayout>
          <UpdatePage />
        </AdminPortalLayout>
      </AuthGuard>
      <AuthGuard
        exact
        path={`/admin/accounts`}
        Component={
          <AdminPortalLayout>
            <UserManagementPage />
          </AdminPortalLayout>
        }
        userTypesRequired={[userTypes.FOODBANK]}
      >
        <AdminPortalLayout>
          <UserManagementPage />
        </AdminPortalLayout>
      </AuthGuard>
      <Route exact path={`/admin/accounts/logout`} component={LogoutPage} />
      {/* Product Management */}
      <AuthGuard
        path={`/admin/products/:variantId/view`}
        Component={
          <AdminPortalLayout>
            <ProductManagementPage />
          </AdminPortalLayout>
        }
        userTypesRequired={[userTypes.FOODBANK]}
      >
        <AdminPortalLayout>
          <ProductManagementPage />
        </AdminPortalLayout>
      </AuthGuard>
      <AuthGuard
        path={`/admin/products/:variantId/create`}
        Component={
          <AdminPortalLayout>
            <CreateFoodPage />
          </AdminPortalLayout>
        }
        userTypesRequired={[userTypes.FOODBANK]}
      >
        <AdminPortalLayout>
          <CreateFoodPage />
        </AdminPortalLayout>
      </AuthGuard>
      <AuthGuard
        path={`/admin/products/:variantId/import`}
        Component={
          <AdminPortalLayout>
            <ImportProductPage />
          </AdminPortalLayout>
        }
        userTypesRequired={[userTypes.FOODBANK]}
      >
        <AdminPortalLayout>
          <ImportProductPage />
        </AdminPortalLayout>
      </AuthGuard>
      <AuthGuard
        exact
        path={`/admin/products/:variantId/update/:itemId`}
        Component={
          <AdminPortalLayout>
            <UpdateProductPage />
          </AdminPortalLayout>
        }
        userTypesRequired={[userTypes.FOODBANK]}
      >
        <AdminPortalLayout>
          <UpdateProductPage />
        </AdminPortalLayout>
      </AuthGuard>

      <AuthGuard
        path={`/admin/products/delete`}
        Component={
          <AdminPortalLayout>
            <DeleteProductPage />
          </AdminPortalLayout>
        }
        userTypesRequired={[userTypes.FOODBANK]}
      >
        <AdminPortalLayout>
          <DeleteProductPage />
        </AdminPortalLayout>
      </AuthGuard>

      <AuthGuard
        exact
        path={`/admin/variant/update/:id`}
        Component={
          <AdminPortalLayout>
            <UpdateVariantPage />
          </AdminPortalLayout>
        }
        userTypesRequired={[userTypes.FOODBANK]}
      >
        <AdminPortalLayout>
          <UpdateVariantPage />
        </AdminPortalLayout>
      </AuthGuard>
      <Route exact path={`/admin/products/:variantId/export`}>
        <AdminPortalLayout>
          <ExportProductsPage />
        </AdminPortalLayout>
      </Route>
      {/* Category Management */}
      <AuthGuard
        exact
        path={`/admin/categories/:variantId`}
        Component={
          <AdminPortalLayout>
            <CategoryManagementPage />
          </AdminPortalLayout>
        }
        userTypesRequired={[userTypes.FOODBANK]}
      >
        <AdminPortalLayout>
          <CategoryManagementPage />
        </AdminPortalLayout>
      </AuthGuard>
      <AuthGuard
        exact
        path={`/admin/categories/:variantId/update/:categoryId`}
        Component={
          <AdminPortalLayout>
            <AdminCategoryUpdatePage />
          </AdminPortalLayout>
        }
        userTypesRequired={[userTypes.FOODBANK]}
      >
        <AdminPortalLayout>
          <AdminCategoryUpdatePage />
        </AdminPortalLayout>
      </AuthGuard>
      <AuthGuard
        exact
        path={`/admin/categories/:variantId/create`}
        Component={
          <AdminPortalLayout>
            <CreateCategoryPage />
          </AdminPortalLayout>
        }
        userTypesRequired={[userTypes.FOODBANK]}
      >
        <AdminPortalLayout>
          <CreateCategoryPage />
        </AdminPortalLayout>
      </AuthGuard>

      {/* Dashboard */}
      <AuthGuard
        exact
        path={`/admin/dashboard/:variantId`}
        userTypesRequired={[userTypes.FOODBANK, userTypes.RESEARCH]}
      >
        <AdminPortalLayout>
          <AdminPage />
        </AdminPortalLayout>
      </AuthGuard>

      <Route component={NotFoundPage} />
    </Switch>
  );
};
