import { Input } from 'app/components/Forms/Input';
import { PAYMENT_POST_TOKEN_ENDPOINT } from 'app/util/constants/apiEndpoints/PaymentEndpoints';
import { CardFrame, Frames } from 'frames-react';
import React from 'react';
import axiosInstance from 'utils/axios';

interface IPaymentFormProps {
  className?: string;
}

export const PaymentForm: React.FC<IPaymentFormProps> = ({ className }) => {
  const [fullName, setFullName] = React.useState<string>('');
  const [nric, setNric] = React.useState<string>('');
  const sendPaymentToken = async tokenId => {
    let reqBody = {
      tokenId: tokenId,
      nric,
      fullName,
    };
    return await axiosInstance.post(PAYMENT_POST_TOKEN_ENDPOINT(), reqBody);
  };
  return (
    <div className="flex items-center justify-center p-12 mx-auto w-full max-w-[550px]">
      <form
        onSubmit={e => {
          e.preventDefault();
          Frames.submitCard();
        }}
      >
        <Input
          title="Full Name"
          type="text"
          name="fullname"
          id="fullname"
          value={fullName}
          onChange={e => {
            setFullName(e.target.value);
          }}
          placeholder="Full Name"
        />
        <Input
          title="NRIC"
          type="text"
          name="nric"
          id="nric"
          value={nric}
          onChange={e => {
            setNric(e.target.value);
          }}
          placeholder="NRIC"
        />
        <Frames
          config={{
            publicKey: process.env.REACT_APP_PAYMENT_PUBLIC_KEY as string,
          }}
          cardTokenized={e => {
            sendPaymentToken(e.token);
          }}
        >
          <CardFrame />
        </Frames>
        <div>
          <button className="text-black" type="submit">
            Pay
          </button>
        </div>
      </form>
    </div>
  );
};
