const USER_ROOT_URL = '/user';

const USER_GET_GET_ALL_USERS_ENDPOINT = () => `${USER_ROOT_URL}`;
const USER_GET_GET_USER_DETAILS_ENDPOINT = (uid: string) =>
  `${USER_ROOT_URL}/${uid}`;
const USER_POST_CREATE_USER_ENDPOINT = () => USER_ROOT_URL;
const USER_PUT_UPDATE_USER_ENDPOINT = (uid: string) =>
  `${USER_ROOT_URL}/${uid}`;
const USER_DELETE_DELETE_USER_DETAILS_ENDPOINT = (uid: string) =>
  `${USER_ROOT_URL}/${uid}`;
const USER_PUT_DEACTIVATE_USER_ENDPOINT = (uid: string) =>
  `${USER_ROOT_URL}/${uid}`;
const USER_PUT_ACTIVATE_USER_ENDPOINT = (uid: string) =>
  `${USER_ROOT_URL}/${uid}`;

export {
  USER_GET_GET_ALL_USERS_ENDPOINT,
  USER_GET_GET_USER_DETAILS_ENDPOINT,
  USER_POST_CREATE_USER_ENDPOINT,
  USER_PUT_UPDATE_USER_ENDPOINT,
  USER_DELETE_DELETE_USER_DETAILS_ENDPOINT,
  USER_PUT_DEACTIVATE_USER_ENDPOINT,
  USER_PUT_ACTIVATE_USER_ENDPOINT,
};
