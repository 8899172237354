import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Dialog, Transition } from '@headlessui/react';
import { Button } from 'app/components/Buttons/Button';
import React, { Fragment } from 'react';

interface IUnableDeleteModalProps {
  isModalOpen: boolean;
  closeModal: () => void;
}

export const UnableDeleteModal: React.FC<IUnableDeleteModalProps> = ({
  isModalOpen,
  closeModal,
}) => {
  return (
    <Transition appear show={isModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-opacity-25 z-10" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-[416px] h-[212px] transform overflow-hidden bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="flex flex-col gap-2">
                  <div className="flex flex-row gap-4">
                    <ExclamationCircleOutlined className="text-neutral-500 text-[22px]" />
                    <div className="flex flex-col gap-2">
                      <Dialog.Title as="h5" className="text-base text-gray-900">
                        Unable to delete category with active products.
                      </Dialog.Title>
                      <p className="text-sm leading-6 text-black/80">
                        Please delete all active products under this category
                        before deleting the catgory.
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-row justify-end">
                    <Button
                      className="bg-neutral-500 rounded-3xl"
                      children={<span>OK</span>}
                      type={'submit'}
                      onClick={() => closeModal}
                    />
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
