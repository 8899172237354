import { ForgotPasswordForm } from 'app/pages/Admin/User/Update/ForgotPasswordPage/components/ForgotPasswordForm';
import { PAGE_TITLES_ADMIN_PORTAL } from 'app/util/constants/pageTitles/adminPortal';
import React from 'react';
import { Helmet } from 'react-helmet-async';

export function ForgotPasswordPage() {
  return (
    <>
      <Helmet>
        <title>
          {PAGE_TITLES_ADMIN_PORTAL.accountManagement.forgetPasswordPage}
        </title>
        <meta
          name="description"
          content={
            PAGE_TITLES_ADMIN_PORTAL.accountManagement.forgetPasswordPage
          }
        />
      </Helmet>
      <ForgotPasswordForm />
    </>
  );
}
