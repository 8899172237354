import { AdminCreateUserForm } from 'app/pages/Admin/User/Create/CreateUserPage/components/AdminCreateUserForm';
import { PAGE_TITLES_ADMIN_PORTAL } from 'app/util/constants/pageTitles/adminPortal';
import React from 'react';
import { Helmet } from 'react-helmet-async';

export function AdminCreateUserPage() {
  return (
    <>
      <Helmet>
        <title>
          {PAGE_TITLES_ADMIN_PORTAL.accountManagement.createUserPage}
        </title>
        <meta
          name="description"
          content={PAGE_TITLES_ADMIN_PORTAL.accountManagement.createUserPage}
        />
      </Helmet>
      <div className="flex border-b border-[#D9D9D9] py-3 pl-4">
        <span className="text-xl font-bold">
          Admin Accounts / <span className="text-primary">Add User</span>
        </span>
      </div>
      <AdminCreateUserForm></AdminCreateUserForm>
    </>
  );
}
