import React from 'react';

const throwError = e => {
  e.preventDefault();
  throw Error('Error for sentry logging');
};
export function SentryPage() {
  return (
    <>
      <button type="submit" onClick={throwError} className="border">
        Throw error to test sentry logging
      </button>
    </>
  );
}
