import { Product } from 'app/models/Product';
import { useRecoilState } from 'recoil';

import { cartDefaultKey, cartState } from './atoms';

export type CartManipulatorFunc = (product: Product, quantity: number) => void;
type CurryCartManipulatorFunc = (userId?: string) => CartManipulatorFunc;

export const useAddToCart: CurryCartManipulatorFunc = (
  userId = cartDefaultKey,
) => {
  const [cart, setCart] = useRecoilState(cartState(userId));

  return (product: Product, quantity: number) => {
    const index = cart.findIndex(item => item.id === product.id);

    if (index === -1) {
      return setCart([...cart, { ...product, cartQuantity: quantity }]);
    }

    const newCart = cart.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          cartQuantity: item.cartQuantity + quantity,
        };
      }

      return item;
    });

    setCart(newCart);
  };
};

export const useRemoveFromCart: CurryCartManipulatorFunc = (
  userId = cartDefaultKey,
) => {
  const [cart, setCart] = useRecoilState(cartState(userId));

  return (product: Product, quantity: number) => {
    const index = cart.findIndex(item => item.id === product.id);

    if (index === -1) {
      alert('Product not found in cart!');
      return;
    }

    const newCart = cart.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          cartQuantity: item.cartQuantity - quantity,
        };
      }

      return item;
    });

    const filteredCart = newCart.filter(item => item.cartQuantity > 0);

    setCart(filteredCart);
  };
};

export const useChangeQuantityCart: CurryCartManipulatorFunc = (
  userId = cartDefaultKey,
) => {
  const [cart, setCart] = useRecoilState(cartState(userId));

  return (product: Product, quantity: number) => {
    const index = cart.findIndex(item => item.id === product.id);

    if (index === -1) {
      alert('Product not found in cart!');
      return;
    }
    if (quantity <= 0) {
      alert('Invalid quantity');
      return;
    }
    const newCart = cart.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          cartQuantity: quantity,
        };
      }

      return item;
    });

    const filteredCart = newCart.filter(item => item.cartQuantity > 0);

    setCart(filteredCart);
  };
};

export const useClearCart = (userId = cartDefaultKey) => {
  const [, setCart] = useRecoilState(cartState(userId));

  return () => {
    setCart([]);
  };
};
