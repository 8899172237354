import { EditOutlined } from '@ant-design/icons';
import { useAuthValue } from 'app/AuthContext';
import { ButtonComponent } from 'app/components/Buttons/Button/Loadable';
import { InputComponent } from 'app/components/Forms/Input/Loadable';
import { ConfirmationModalComponent } from 'app/components/Modals/ConfirmationModal/Loadable';
import { LoadingState } from 'app/types/enums/loadingState';
import { PAGE_URL_ADMIN_PORTAL } from 'app/util/constants/pageUrls/adminPortal';
import { isEmptyField } from 'app/util/validators/FormValidators';
import { isFormValid } from 'app/util/validators/ValidatorHelper';
import axios from 'axios';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axiosInstance from 'utils/axios';

export function UserPage() {
  const [userDetails, setUserDetails] = React.useState({
    name: 'Ryan',
    jobTitle: 'Janitor',
    userType: 'FOODBANK',
    email: 'ree@gmail.com',
    status: '',
  });
  const [editField, setEditField] = React.useState({
    name: {
      disabled: true,
      value: null,
    },
    jobTitle: {
      disabled: true,
      value: null,
    },
    email: {
      disabled: true,
      value: null,
    },
  });
  //   const API_ENDPOINT = `${process.env.REACT_APP_PROXY_BACKEND_API_URL}/user`;
  const [loadingState, setLoadingState] = React.useState<LoadingState>(
    LoadingState.LOADING,
  );
  const { currentUser } = useAuthValue()!;
  const history = useHistory();
  useEffect(() => {
    if (currentUser && loadingState == LoadingState.LOADING) {
      currentUser.getIdToken().then(token => {
        const axiosConfig = {
          headers: {
            'x-admin-access': token,
          },
        };

        axiosInstance.get(`/user/${currentUser.uid}`, axiosConfig).then(res => {
          setUserDetails({
            name: res.data.name,
            jobTitle: res.data.jobTitle,
            userType: res.data.type,
            email: res.data.email,
            status: res.data.status,
          });
          setEditField({
            name: {
              disabled: true,
              value: res.data.name,
            },
            jobTitle: {
              disabled: true,
              value: res.data.jobTitle,
            },
            email: {
              disabled: true,
              value: res.data.email,
            },
          });
          setLoadingState(LoadingState.SUCCESS);
        });
      });
    }
  }, [currentUser, loadingState]);

  const [errorMessages, setErrorMessages] = React.useState<any>({});
  const [isUpdateModalOpen, setIsUpdateModalOpen] =
    React.useState<boolean>(false);
  const cancelButtonRef = React.useRef(null);
  const updateDatabase = field => {
    if (validateInputs()) {
      setIsUpdateModalOpen(true);
    } else {
      return;
    }
    if (currentUser) {
      currentUser.getIdToken().then(token => {
        const axiosConfig = {
          headers: {
            'x-admin-access': token,
          },
        };
        const reqBody = {
          name: editField.name.value,
          jobTitle: editField.jobTitle.value,
          email: editField.email.value,
          type: userDetails.userType,
          firebaseId: currentUser.uid,
          status: userDetails.status,
        };
        reqBody[field] = userDetails[field];
        axios
          .put(`/user/${currentUser.uid}`, reqBody, axiosConfig)
          .then(res => {
            setEditField(prevState => ({
              ...prevState,
              [field]: {
                disabled: true,
                value: editField[field].value,
              },
            }));
          });
      });
    }
  };

  const handleChange = e => {
    const { value, name } = e.target;
    setUserDetails(prev => ({ ...prev, [name]: value }));
  };
  const validateInputs = () => {
    const emailValidRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const errors = {
      nameError: isEmptyField('Please enter a name', userDetails.name),
      invalidJobTitle: isEmptyField(
        'Please enter a job title',
        userDetails.jobTitle,
      ),
      emailError:
        !new RegExp(emailValidRegex).test(userDetails.email) === false
          ? undefined
          : true,
    };
    setErrorMessages(errors);
    if (!isFormValid(errors)) {
      return false;
    } else {
      return true;
    }
  };
  const handleEdit = field => {
    setEditField(prevState => ({
      ...prevState,
      [field]: {
        disabled: false,
        value: editField[field].value,
      },
    }));
  };
  const handleCancel = field => {
    setEditField(prevState => ({
      ...prevState,
      [field]: {
        disabled: true,
        value: editField[field].value,
      },
    }));
    setUserDetails(prev => ({
      ...prev,
      [field]: editField[field].value,
    }));
  };
  if (loadingState == LoadingState.LOADING) {
    return <h1>Loading...</h1>;
  }
  return (
    <>
      <div className="m-2 text-xl font-bold border-b-2 ">My Profile</div>
      <div className="flex flex-row items-center justify-center">
        <div className="flex flex-col items-end">
          <div className="relative flex flex-row">
            <p className="my-[15px] pr-[6px] text-right text-[14px] font-poppins">
              Full Name :
            </p>
            <InputComponent
              className="w-[400px] h-[32px] rounded-[2px] pl-[12px] pt-[8px] font-normal font-poppins leading-[20px] bg-neutral-200 border border-neutral-500 text-[#33424E] text-[14px]"
              disabled={editField.name.disabled}
              type="text"
              name="name"
              value={userDetails.name}
              id="name"
              onChange={handleChange}
              showError={errorMessages.nameError}
              error={errorMessages.nameError}
            />
            {editField.name.disabled ? (
              <EditOutlined
                className="absolute bottom-0 -translate-y-1/2 -right-10"
                style={{ fontSize: '23px', color: '#80B519' }}
                onClick={() => handleEdit('name')}
              />
            ) : (
              <div className="absolute top-0 flex flex-row translate-y-1/3 left-full">
                <ButtonComponent
                  className="w-[62px] h-[32px] rounded-[20px] mx-2 text-[14px] whitespace-nowrap text-white bg-[#80B519]"
                  children="Save"
                  type="button"
                  onClick={() => updateDatabase('name')}
                />
                <ButtonComponent
                  className="w-[75px] h-[32px] rounded-[20px] mx-2 text-[14px] whitespace-nowrap text-white bg-[#ADB5BD]"
                  children="Cancel"
                  type="button"
                  onClick={() => handleCancel('name')}
                />
              </div>
            )}
          </div>
          <div className="relative flex flex-row">
            <p className="my-[15px] pr-[6px] text-right text-[14px] font-poppins">
              User Type :
            </p>
            <InputComponent
              className="w-[400px] h-[32px] rounded-[2px] pl-[12px] pt-[8px] font-normal font-poppins leading-[20px] bg-neutral-200 border border-neutral-500 text-neutral-500 text-[14px]"
              disabled={true}
              value={userDetails.userType}
              onChange={handleChange}
            />
          </div>
          <div className="relative flex flex-row">
            <p className="my-[15px] pr-[6px] text-right text-[14px] font-poppins">
              Job Title :
            </p>
            <InputComponent
              className="w-[400px] h-[32px] rounded-[2px] pl-[12px] pt-[8px] font-normal font-poppins leading-[20px] bg-neutral-200 border border-neutral-500 text-[#33424E] text-[14px]"
              disabled={editField.jobTitle.disabled}
              type="text"
              name="jobTitle"
              value={userDetails.jobTitle}
              id="jobTitle"
              onChange={handleChange}
              showError={errorMessages.invalidJobTitle}
              error={errorMessages.invalidJobTitle}
            />
            {editField.jobTitle.disabled ? (
              <EditOutlined
                className="absolute bottom-0 -translate-y-1/2 -right-10"
                style={{ fontSize: '23px', color: '#80B519' }}
                onClick={() => handleEdit('jobTitle')}
              />
            ) : (
              <div className="absolute top-0 flex flex-row translate-y-1/3 left-full">
                <ButtonComponent
                  className="w-[62px] h-[32px] rounded-[20px] mx-2 text-[14px] whitespace-nowrap text-white bg-[#80B519]"
                  children="Save"
                  type="button"
                  onClick={() => updateDatabase('jobTitle')}
                />
                <ButtonComponent
                  className="w-[75px] h-[32px] rounded-[20px] mx-2 text-[14px] whitespace-nowrap text-white bg-[#ADB5BD]"
                  children="Cancel"
                  type="button"
                  onClick={() => handleCancel('jobTitle')}
                />
              </div>
            )}
          </div>
          <div className="relative flex flex-row">
            <p className="my-[15px] pr-[6px] text-right text-[14px] font-poppins">
              Email :
            </p>
            <InputComponent
              className="w-[400px] h-[32px] rounded-[2px] pl-[12px] pt-[8px] font-normal font-poppins leading-[20px] bg-neutral-200 border border-neutral-500 text-[#33424E] text-[14px]"
              disabled={editField.email.disabled}
              type="text"
              name="email"
              id="email"
              value={userDetails.email}
            />
            <EditOutlined
              className="absolute bottom-0 -translate-y-1/2 -right-10"
              style={{ fontSize: '23px', color: '#80B519' }}
              onClick={() =>
                history.push({
                  pathname:
                    PAGE_URL_ADMIN_PORTAL.accountManagement.updateEmailPage,
                  state: {
                    from: history.location.pathname,
                  },
                })
              }
            />
          </div>
          <div className="relative flex flex-row">
            <p className="my-[15px] pr-[6px] text-right text-[14px] font-poppins">
              Password :
            </p>
            <InputComponent
              className="w-[400px] h-[32px] rounded-[2px] pl-[12px] pt-[8px] font-normal font-poppins leading-[20px] bg-neutral-200 border border-neutral-500 text-[#33424E] text-[14px]"
              disabled={true}
              type="password"
              name="password"
              id="password"
              value={'********'}
            />
            <EditOutlined
              className="absolute bottom-0 -translate-y-1/2 -right-10"
              style={{ fontSize: '23px', color: '#80B519' }}
              onClick={() =>
                history.push({
                  pathname:
                    PAGE_URL_ADMIN_PORTAL.accountManagement.updatePasswordPage,
                  state: {
                    from: history.location.pathname,
                  },
                })
              }
            />
          </div>
        </div>
      </div>
      <ConfirmationModalComponent
        showState={isUpdateModalOpen}
        showStateController={setIsUpdateModalOpen}
        cancelButtonRef={cancelButtonRef}
        header={'You have successfully edited your profile'}
        iconStyles={'w-[22px] h-[22px] text-[#80B519] my-1 mr-3'}
        primaryButtonText={'OK'}
        primaryButtonStyles={
          'items-end bg-[#80B519] w-[72px] h-[32px] rounded-[20px] text-[12px] whitespace-nowrap'
        }
        buttonFunction={() => setIsUpdateModalOpen(false)}
        secondaryButtonStyles={
          'items-end bg-neutral-500 w-[72px] h-[32px] rounded-[20px] text-[12px] whitespace-nowrap mr-2'
        }
      />
    </>
  );
}
