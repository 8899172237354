import { UpdateUserPage } from 'app/pages/Admin/User/Update/UpdateUserPage';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { PAGE_TITLES_ADMIN_PORTAL } from 'app/util/constants/pageTitles/adminPortal';

type UpdatePageParams = {
  uid: string;
};

export function UpdatePage() {
  let { uid } = useParams<UpdatePageParams>();
  return (
    <>
      <Helmet>
        <title>
          {PAGE_TITLES_ADMIN_PORTAL.accountManagement.updateAccountPage}
        </title>
        <meta
          name="description"
          content={PAGE_TITLES_ADMIN_PORTAL.accountManagement.updateAccountPage}
        />
      </Helmet>
      <UpdateUserPage userUid={uid} />
    </>
  );
}
