import { useAuthValue } from 'app/AuthContext';
import { ButtonComponent } from 'app/components/Buttons/Button/Loadable';
import { InputComponent } from 'app/components/Forms/Input/Loadable';
import { ConfirmationModalComponent } from 'app/components/Modals/ConfirmationModal/Loadable';
import { PAGE_URL_ADMIN_PORTAL } from 'app/util/constants/pageUrls/adminPortal';
import { isFormValid } from 'app/util/validators/ValidatorHelper';
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from 'firebase/auth';
import React from 'react';
import { useHistory } from 'react-router-dom';

export function UpdateUserPasswordPage() {
  const history = useHistory();
  const [passwords, setPasswords] = React.useState({
    oldPassword: '',
    newPassword: '',
    secondNewPassword: '',
  });
  const { currentUser } = useAuthValue()!;
  const [errorMessages, setErrorMessages] = React.useState<any>({});
  const [isUpdateModalOpen, setIsUpdateModalOpen] =
    React.useState<boolean>(false);
  const cancelButtonRef = React.useRef(null);

  const handleChange = e => {
    const { value, name } = e.target;
    setPasswords(prev => ({ ...prev, [name]: value }));
  };
  const changePassword = () => {
    const errors: any = {
      oldPassword: undefined,
      newPassword: undefined,
      secondNewPassword: undefined,
    };
    if (passwords.newPassword !== passwords.secondNewPassword) {
      errors.secondNewPassword = 'Passwords do not match.';
    }

    if (!isFormValid(errors)) {
      setErrorMessages(errors);
      return;
    }
    if (currentUser !== null && currentUser.email !== null) {
      const credential = EmailAuthProvider.credential(
        currentUser.email,
        passwords.oldPassword,
      );
      reauthenticateWithCredential(currentUser, credential)
        .then(() => {
          updatePassword(currentUser, passwords.newPassword)
            .then(() => {
              setIsUpdateModalOpen(true);
            })
            .catch(error => {
              throw new Error(error.code);
            });
        })
        .catch(error => {
          if (error.code === 'auth/wrong-password') {
            errors.oldPassword = 'Old Password is incorrect.';
            setErrorMessages(errors);
          } else {
            throw new Error(error.code);
          }
        });
    }
  };

  //   const validateInputs = () => {
  //     const emailValidRegex =
  //       /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  //     const errors = {
  //       nameError: isEmptyField('Please enter a name', userDetails.name),
  //       invalidJobTitle: isEmptyField(
  //         'Please enter a job title',
  //         userDetails.jobTitle,
  //       ),
  //       emailError:
  //         !new RegExp(emailValidRegex).test(userDetails.email) === false
  //           ? undefined
  //           : true,
  //     };
  //     setErrorMessages(errors);
  //     if (!isFormValid(errors)) {
  //       return false;
  //     } else {
  //       return true;
  //     }
  //   };

  return (
    <>
      <div className="flex flex-row m-2 text-xl font-bold border-b-2">
        <div>My Profile / Edit My Profile / </div>
        <div className="ml-1 text-primary">Change Password</div>
      </div>
      <div className="flex flex-row items-center justify-center">
        <div className="flex flex-col items-end">
          <div className="relative flex flex-row">
            <p className="my-[15px] pr-[6px] text-right text-[14px] font-poppins">
              Old Password:
            </p>
            <InputComponent
              className="w-[400px] h-[32px] rounded-[2px] pl-[12px] pt-[8px] font-normal font-poppins leading-[20px] bg-neutral-200 border border-neutral-500 text-[#33424E] text-[14px]"
              type="password"
              name="oldPassword"
              value={passwords.oldPassword}
              id="oldPassword"
              onChange={handleChange}
              showError={errorMessages.oldPassword}
              error={errorMessages.oldPassword}
              placeholder="Enter Old Password"
            />
          </div>
          <div className="flex flex-row pt-2 pb-4">
            <p className="my-[15px] pr-[6px] text-right text-[14px] font-poppins">
              New Password:
            </p>
            <InputComponent
              className="w-[400px] h-[32px] rounded-[2px] pl-[12px] pt-[8px] font-normal font-poppins leading-[20px] bg-neutral-200 border border-neutral-500 text-[#33424E] text-[14px]"
              type="password"
              name="newPassword"
              value={passwords.newPassword}
              id="newPassword"
              onChange={handleChange}
              showError={errorMessages.newPassword}
              error={errorMessages.newPassword}
              placeholder="Enter New Password"
            />
          </div>
          <div className="flex flex-row relative">
            <p className="my-[15px] pr-[6px] text-right text-[14px] font-poppins">
              Confirm New Password:
            </p>
            <InputComponent
              className="w-[400px] h-[32px] rounded-[2px] pl-[12px] pt-[8px] font-normal font-poppins leading-[20px] bg-neutral-200 border border-neutral-500 text-[#33424E] text-[14px]"
              type="password"
              name="secondNewPassword"
              value={passwords.secondNewPassword}
              id="secondNewPassword"
              onChange={handleChange}
              showError={errorMessages.secondNewPassword}
              error={errorMessages.secondNewPassword}
              placeholder="Confirm New Password"
            />
          </div>
          <div className="flex flex-row">
            <ButtonComponent
              className="w-[133px] h-[32px] rounded-[20px] mx-2 text-[14px] whitespace-nowrap text-white bg-[#80B519]"
              children="Change Password"
              type="button"
              onClick={changePassword}
            />
            <ButtonComponent
              className="w-[75px] h-[32px] rounded-[20px] mx-2 text-[14px] whitespace-nowrap text-white bg-[#ADB5BD]"
              children="Cancel"
              type="button"
              onClick={() => history.goBack()}
            />
          </div>
        </div>
      </div>
      <ConfirmationModalComponent
        showState={isUpdateModalOpen}
        showStateController={setIsUpdateModalOpen}
        cancelButtonRef={cancelButtonRef}
        header={'You have changed your password'}
        description="A confirmation slip has been sent to your registered email."
        iconStyles={'w-[22px] h-[22px] text-[#80B519] my-1 mr-3'}
        primaryButtonText={'OK'}
        primaryButtonStyles={
          'items-end bg-[#80B519] w-[72px] h-[32px] rounded-[20px] text-[12px] whitespace-nowrap'
        }
        buttonFunction={() => {
          setIsUpdateModalOpen(false);
          history.push({
            pathname: PAGE_URL_ADMIN_PORTAL.accountManagement.profilePage,
            state: {
              from: history.location.pathname,
            },
          });
        }}
        secondaryButtonStyles={
          'items-end bg-neutral-500 w-[72px] h-[32px] rounded-[20px] text-[12px] whitespace-nowrap mr-2'
        }
      />
    </>
  );
}
