import { Variant } from 'app/models/Variant';
import { GET_VARIANT } from 'app/util/constants/apiEndpoints/VariantEndpoint';
import axiosInstance from 'utils/axios';

import { VariantIds } from '../VariantContext';

export const getVariant = async (variantId: VariantIds): Promise<Variant> => {
  let result;

  try {
    result = await axiosInstance.get(GET_VARIANT(variantId));

    if (!result.data.data) throw new Error('No Variant Found');
  } catch (e) {
    console.error('Error Fetching Variant ' + e);
    throw e;
  }
  return result.data.data;
};
