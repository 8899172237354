import { CustomBreadcrumb } from 'app/components/Navigation/BreadCrumbs';
import { AddCategoryForm } from 'app/pages/Admin/Category/Create/CreateCategoryPage/components/AddCategoryForm';
import { PAGE_TITLES_ADMIN_PORTAL } from 'app/util/constants/pageTitles/adminPortal';
import { normaliseVariantId, VariantIds } from 'app/VariantContext';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

export function CreateCategoryPage() {
  let { variantId } = useParams<{ variantId: VariantIds }>();

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLES_ADMIN_PORTAL.categoryManagement.createPage}</title>
        <meta
          name="description"
          content={PAGE_TITLES_ADMIN_PORTAL.categoryManagement.createPage}
        />
      </Helmet>
      <CustomBreadcrumb
        data={[
          {
            label: `Variant ${normaliseVariantId(variantId)}`,
            link: '/admin',
          },
          {
            label: 'Category Management',
            link: `/admin/categories/${variantId}`,
          },
          {
            label: 'Add Category',
            link: `/admin/categories/${variantId}/create`,
            isCurrentPage: true,
          },
        ]}
      />
      <AddCategoryForm variant={variantId} />
    </>
  );
}
