import { Input } from 'app/components/Forms/Input';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  deleteCategory,
  deleteCategoryImage,
  getCategory,
  updateCategory,
} from 'app/api/CategoryService';
import { Product } from 'app/models/Product';
import { getProducts } from 'app/api/ProductService';
import { InputSelectComponent } from 'app/components/Forms/InputSelect/Loadable';
import { ButtonComponent } from 'app/components/Buttons/Button/Loadable';
import { ImageUploadComponent } from 'app/pages/Admin/Category/Update/UpdateCategoryPage/components/ImageUpload/imageUpload';
import { uploadObject } from 'app/api/AWSService';
import { PAGE_URL_ADMIN_PORTAL } from 'app/util/constants/pageUrls/adminPortal';
import { VariantIds } from 'app/VariantContext';
import { DeleteModal } from 'app/pages/Admin/Category/View/CategoryManagementPage/components/DeleteCategoryModal';
import {
  CANCEL_BUTTON_CLASSES,
  CONFIRM_BUTTON_CLASSES,
  DELETE_BUTTON_CLASSES,
} from 'app/components/Buttons/Button';
import { Category } from 'app/models/Category';

interface IAdminUpdateCategoryFormProps {
  className?: string;
}

type CategoryFormFields = {
  name: string;
  image: string;
  nature: 'FOOD' | 'NONFOOD';
  variantId: string;
};

enum ProductsTableHeader {
  ProductName = 'Product Name',
  CatalogCode = 'Catalog Code',
}

interface ITableContentsProps {
  product: Product;
}
interface ITableProps {
  products: Product[];
}

// Table components
const TableHeaders = () => {
  return (
    <thead>
      <tr className="flex flex-row flex-grow items-center bg-dark-blue text-neutral-200 text-l">
        <th className="flex w-[200px] p-4 border border-black/[.06]">
          {ProductsTableHeader.ProductName}
        </th>
        <th className="flex w-[200px] p-4 border border-black/[.06]">
          {ProductsTableHeader.CatalogCode}
        </th>
      </tr>
    </thead>
  );
};

const TableContents: React.FC<ITableContentsProps> = ({ product }) => {
  return (
    <tr className="flex flex-row flex-grow items-center text-black/80 border-b border-black/[0.06] bg-neutral-200 text-sm leading-6">
      <th className="flex w-[200px] p-4 font-normal">{product.name}</th>
      <th className="flex w-[200px] p-4 font-normal">{product.skuCode}</th>
    </tr>
  );
};

const Table: React.FC<ITableProps> = ({ products }) => {
  return (
    <table className="flex flex-col flex-grow max-h-80">
      <TableHeaders />
      <tbody style={{ overflowY: 'scroll' }}>
        {products &&
          products.map((product, index) => (
            <TableContents product={product} key={index} />
          ))}
      </tbody>
    </table>
  );
};

export const AdminUpdateCategoryForm: React.FC<
  IAdminUpdateCategoryFormProps
> = props => {
  const history = useHistory();
  const params: { variantId: VariantIds; categoryId: string } = useParams();

  const [originalCategory, setOriginalCategory] = useState<Category>();
  const [updatedCategory, setUpdatedCategory] = useState<CategoryFormFields>({
    name: '',
    nature: 'NONFOOD',
    image: '',
    variantId: '',
  });
  const [products, setProducts] = useState<Product[]>([]);
  const [imageFile, setImageFile] = useState<File | undefined>(undefined);
  const [imageUrl, setImageUrl] = useState('');

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  // Load category
  useEffect(() => {
    getCategory(+params.categoryId).then(res => {
      setUpdatedCategory({ ...updatedCategory, ...res });
      setOriginalCategory(res);
      if (res.image != null) {
        const imgUrl = `${process.env.REACT_APP_S3_URL}uploads/category/${
          params.categoryId
        }/${res.image.replace(' ', '+')}`;
        setImageUrl(imgUrl);
      }
    });
    getProducts({ category_codes: [parseInt(params.categoryId)] }).then(res =>
      setProducts(res.data.filter((p: Product) => p.status != 'DELETED')),
    );
  }, []);

  // Send updated data
  const handleOnSubmit = async event => {
    event.preventDefault();

    let reqBody: any = updatedCategory;
    Object.keys(reqBody).forEach(key => {
      if (reqBody[key] === '') {
        reqBody[key] = undefined;
      }
    });
    reqBody['image'] = imageFile?.name;

    if (imageFile) {
      if (originalCategory) {
        deleteCategoryImage(
          originalCategory.categoryCode,
          originalCategory.image ?? '',
        ).catch(e => console.error('An error occurred deleting file', e));
      }
      uploadObject(`uploads/category/${params.categoryId}/`, imageFile).catch(
        e => console.error('An error occurred uploading file', e),
      );
    }

    updateCategory(+params.categoryId, reqBody)
      .then(() => {
        toCategoryManagementPage();
      })
      .catch(e => console.error('An error occurred updating category', e));
  };

  const toCategoryManagementPage = () => {
    history.push(
      PAGE_URL_ADMIN_PORTAL.categoryManagement.viewPage(params.variantId),
    );
  };

  const handleDelete = () => {
    deleteCategory(parseInt(params.categoryId)).then(() =>
      toCategoryManagementPage(),
    );
    if (originalCategory) {
      deleteCategoryImage(
        originalCategory.categoryCode,
        originalCategory.image ?? '',
      ).catch(e => console.error('An error occurred deleting file', e));
    }
  };

  // Update the data to be sent by the form
  const handleChange = e => {
    const { value, name } = e.target;
    setUpdatedCategory(prev => ({ ...prev, [name]: value }));
  };

  return (
    <div className="flex flex-col gap-4 items-center justify-center p-12 mx-auto w-full max-w-[550px]">
      <form onSubmit={handleOnSubmit} className="flex flex-col gap-4">
        <Input
          title="Category Name"
          type="text"
          name="name"
          id="name"
          value={updatedCategory?.name}
          onChange={handleChange}
          placeholder="Name"
          isLabelHorizontal
        />
        <InputSelectComponent
          id="nature"
          label="Type:"
          name="nature"
          value={updatedCategory?.nature}
          options={[
            { label: 'Food', value: 'FOOD' },
            { label: 'Non-Food', value: 'NONFOOD' },
          ]}
          onChange={handleChange}
          isLabelHorizontal
        />
        <ImageUploadComponent
          imageFile={imageFile}
          setImageFile={setImageFile}
          imageUrl={imageUrl}
          setImageUrl={setImageUrl}
        />
        <div className="grid grid-cols-[1fr_2fr] gap-x-4 items-center">
          <div className="justify-self-end">{'Products:'}</div>
          {products.length === 0 && <div>{'No Products'}</div>}
        </div>
        {products.length > 0 && <Table products={products} />}
        <div className="flex justify-end gap-2">
          <ButtonComponent
            className={CONFIRM_BUTTON_CLASSES}
            type="submit"
            onClick={e => handleOnSubmit(e)}
          >
            Save Changes
          </ButtonComponent>
          <ButtonComponent
            type="button"
            className={DELETE_BUTTON_CLASSES}
            onClick={() => setIsDeleteModalOpen(true)}
            disabled={products.length > 0}
          >
            Delete
          </ButtonComponent>
          <ButtonComponent
            className={CANCEL_BUTTON_CLASSES}
            onClick={toCategoryManagementPage}
          >
            Back
          </ButtonComponent>
        </div>
      </form>
      <DeleteModal
        isModalOpen={isDeleteModalOpen}
        closeModal={() => setIsDeleteModalOpen(false)}
        handleDelete={handleDelete}
      ></DeleteModal>
    </div>
  );
};
