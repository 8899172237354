import './AdminCreateFoodForm.css';

import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { uploadObject } from 'app/api/AWSService';
import { getCategories } from 'app/api/CategoryService';
import { createProduct, updateProduct } from 'app/api/ProductService';
import { getVariant } from 'app/api/VariantService';
import { Button } from 'app/components/Buttons/Button';
import { Input } from 'app/components/Forms/Input';
import { InputSelectComponent } from 'app/components/Forms/InputSelect';
import { InputTextAreaComponent } from 'app/components/Forms/InputTextArea';
import { ConfirmationModalComponent } from 'app/components/Modals/ConfirmationModal/Loadable';
import { Product } from 'app/models/Product';
import { PAGE_URL_ADMIN_PORTAL } from 'app/util/constants/pageUrls/adminPortal';
import {
  isFileSizeValid,
  isImageBigger,
} from 'app/util/validators/FileValidators';
import {
  hasAlphbetCharacters,
  hasFiles,
  hasLessThanMin,
  hasSpecialCharacters,
  isEmptyField,
} from 'app/util/validators/FormValidators';
import { isFormValid } from 'app/util/validators/ValidatorHelper';
import { VariantIds } from 'app/VariantContext';
import React, { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

interface IAdminCreateFoodFormProps {
  className?: string;
}
type Variant = {
  name: string;
};
type Category = {
  code: number;
  name: string;
  nature: 'FOOD' | 'NONFOOD';
};

type Item = {
  name?: string;
  description?: string;
  price: string;
  status?: 'ACTIVE' | 'INACTIVE';
  weight: string;
  categoryCode?: string;
  targetQuantity: string;
  productCode?: string;
  images?: string[];
  skuCode?: string;
  rank?: string;
};

export const AdminCreateFoodForm: React.FC<IAdminCreateFoodFormProps> = ({
  className,
}) => {
  const params: { variantId: VariantIds } = useParams();
  const history = useHistory();

  const [newFood, setNewFood] = React.useState<Item>({
    name: '',
    description: '',
    price: '1',
    weight: '0',
    status: 'INACTIVE',
    categoryCode: undefined,
    targetQuantity: '0',
    rank: '1',
  });

  const [categories, setCategories] = React.useState<Category[]>([]);
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
  const [variant, setVariant] = useState<Variant>();
  const [selectedCategory, setSelectedCategory] = useState<Category>();
  const [errorMessages, setErrorMessages] = React.useState<any>({});
  const [imageFiles, setImageFiles] = React.useState<File[]>([]);

  // modals
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState<boolean>(false);
  const [isCancelModalOpen, setIsCancelModalOpen] =
    React.useState<boolean>(false);
  const cancelButtonRef = useRef(null);

  useEffect(() => {
    getVariant(params.variantId)
      .then(res => {
        setVariant(res);
      })
      .catch(() => {
        history.push({
          pathname: PAGE_URL_ADMIN_PORTAL.errorPage,
          state: {
            from: history.location.pathname,
          },
        });
      });
    getCategories({ variantIds: [params.variantId] })
      .then(res => {
        const categoryArray = res.data.map(cat => {
          const obj: Category = {
            code: cat.categoryCode,
            name: cat.name,
            nature: cat.nature,
          };
          return obj;
        });

        setCategories(categoryArray);
      })
      .catch(e => {});
  }, []);

  const onFieldChangeHandler = e => {
    let { value, name } = e.target;

    if (name === 'categoryCode') {
      for (let category of categories) {
        if (category.code === +value) {
          setSelectedCategory(category);
        }
      }
    }

    setNewFood(prev => ({ ...prev, [name]: value }));
  };

  const onImageFilesChangeHandler = (files: any[]) => {
    setImageFiles(files);
  };

  const onFormSubmitHandler = e => {
    e.preventDefault();

    setIsFormDirty(true);

    // check for invalid inputs
    setErrorMessages({
      categoryCodeError: isEmptyField(
        'Please Select a Category',
        newFood.categoryCode,
      ),
      nameError: isEmptyField('Please Enter a Name', newFood.name),
      skuCodeError:
        hasSpecialCharacters(
          'Please Remove Any Special Characters',
          newFood.skuCode,
        ) ||
        hasAlphbetCharacters('Please Remove Any Alphabets', newFood.skuCode),
      descriptionError: isEmptyField(
        'Please Enter a Description',
        newFood.description,
      ),
      rankError:
        hasLessThanMin(
          'Please enter a 0 or a positive number',
          0,
          +newFood.rank!,
        ) || hasAlphbetCharacters('Please remove any alphabets', newFood.rank),
      priceError:
        isEmptyField('Please Enter a Price', newFood.price) ||
        hasLessThanMin('Please enter a positive number', 1, +newFood.price),
      quantityError:
        hasAlphbetCharacters(
          'Please remove any alphabets',
          newFood.targetQuantity,
        ) ||
        hasLessThanMin(
          'Please enter a positive target quantity',
          0,
          +(newFood?.targetQuantity ?? 0),
        ),
      imageError: hasFiles('Please Upload at least 1 Image', imageFiles),
    });
  };

  // check for error messages changes that is triggered when the form is submitted

  const uploadAndUpdate = async (newItemId: number) => {
    const _imageFileNames: string[] = [];
    // upload each file and update the item
    for (let image of imageFiles) {
      try {
        await uploadObject(`uploads/product/${newItemId}/`, image);

        _imageFileNames.push(image.name);
      } catch (e) {
        console.error('An error occurred uploading file', e);
      }
    }

    // update the new item with the uploaded images
    try {
      updateProduct(+newItemId, { images: _imageFileNames });
    } catch (e) {
      console.error("An error occurred updating the item's images array", e);
    }
  };

  const createItem = async () => {
    let request = {
      ...newFood,
      targetQuantity: +newFood.targetQuantity,
      price: +newFood.price,
      weight: +newFood.weight,
      rank: +(newFood.rank ?? 0),
      categoryCode: +newFood.categoryCode!,
    };

    try {
      const _newProductId = (await createProduct(request as Product)).id;

      if (imageFiles.length) {
        await uploadAndUpdate(_newProductId);
      }

      // return to the previous page
      history.push({
        pathname: PAGE_URL_ADMIN_PORTAL.productManagement.viewPage(
          params.variantId,
        ),
        state: {
          from: history.location.pathname,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (isFormValid(errorMessages) && isFormDirty) {
      // submit to backend
      setIsUpdateModalOpen(true);
    } else {
      console.error(errorMessages);
    }
  }, [errorMessages]);

  return (
    <>
      <div className="flex flex-col items-center">
        <form className="flex flex-col gap-8 w-[50%] m-8">
          <div className="grid grid-cols-[1fr_2fr] gap-x-4 items-center">
            <div className="justify-self-end">
              Product Type <span className="text-error">*</span>:
            </div>
            <div>
              {selectedCategory?.nature === undefined
                ? ''
                : selectedCategory?.nature === 'FOOD'
                ? 'Food'
                : 'Non-Food'}
            </div>
          </div>

          <InputSelectComponent
            name="categoryCode"
            id="categoryCode"
            label="Category "
            required={true}
            value={newFood.categoryCode}
            isLabelHorizontal={true}
            onChange={onFieldChangeHandler}
            options={categories.map(cat => {
              return { value: cat.code, label: cat.name };
            })}
            error={errorMessages.categoryCodeError}
          ></InputSelectComponent>
          <Input
            title="Product Name "
            type="text"
            name="name"
            id="name"
            value={newFood.name}
            onChange={onFieldChangeHandler}
            placeholder="Tasty Rice"
            required={true}
            error={errorMessages.nameError}
            showError={errorMessages.nameError !== ''}
            isLabelHorizontal={true}
            maxLength={40}
          />
          <Input
            title="Warehouse SKU Code "
            type="number"
            name="skuCode"
            id="skuCode"
            placeholder="Warehouse SKU Code"
            onChange={onFieldChangeHandler}
            error={errorMessages.skuCodeError}
            showError={errorMessages.skuCodeError !== ''}
            value={newFood.skuCode}
            isLabelHorizontal={true}
            maxLength={6}
          ></Input>
          <InputTextAreaComponent
            value={newFood.description}
            label={'Description '}
            required={true}
            onChange={onFieldChangeHandler}
            error={errorMessages.descriptionError}
            isLabelHorizontal={true}
          ></InputTextAreaComponent>
          <Input
            title="Set Price "
            type="number"
            name="price"
            id="price"
            min={1}
            value={newFood.price}
            onChange={onFieldChangeHandler}
            placeholder="1"
            error={errorMessages.priceError}
            showError={errorMessages.priceError !== ''}
            isLabelHorizontal={true}
          />
          <Input
            title="Rank "
            type="number"
            name="rank"
            id="rank"
            min={0}
            required={true}
            value={newFood.rank}
            onChange={onFieldChangeHandler}
            placeholder="0"
            error={errorMessages.rankError}
            showError={errorMessages.rankError !== ''}
            isLabelHorizontal={true}
          />
          <Input
            title="Target Quantity "
            type="number"
            name="targetQuantity"
            id="targetQuantity"
            min={0}
            value={newFood.targetQuantity}
            onChange={onFieldChangeHandler}
            placeholder="1"
            error={errorMessages.quantityError}
            showError={errorMessages.quantityError !== ''}
            isLabelHorizontal={true}
          />
          <ImageSectionComponent
            onImageFilesChange={onImageFilesChangeHandler}
            formErrorMessage={errorMessages.imageError}
          ></ImageSectionComponent>
          <InputSelectComponent
            name="status"
            id="status"
            label="Status "
            value={newFood.status}
            onChange={onFieldChangeHandler}
            isLabelHorizontal={true}
            options={[
              { label: 'Active', value: 'ACTIVE' },
              { label: 'Inactive', value: 'INACTIVE' },
            ]}
            required={true}
          ></InputSelectComponent>
          <div className="grid grid-cols-[1fr_2fr] gap-4">
            <div className="col-start-2 flex flex-row gap-1">
              <Button
                type="submit"
                onClick={onFormSubmitHandler}
                className="items-end inline-block bg-primary w-[72px] h-[32px] rounded-[20px] text-[12px] text-white whitespace-nowrap mr-2 "
              >
                Add
              </Button>
              <Button
                type="button"
                onClick={() => setIsCancelModalOpen(true)}
                className="items-end inline-block bg-neutral-500 h-[32px] rounded-[20px] text-[12px] text-white whitespace-nowrap mr-2"
              >
                Cancel
              </Button>
            </div>

            {errorMessages.miscError && (
              <div className="text-error text-white col-start-2">
                {errorMessages.miscError}
              </div>
            )}
          </div>
        </form>
      </div>
      <ConfirmationModalComponent
        showState={isUpdateModalOpen}
        showStateController={setIsUpdateModalOpen}
        cancelButtonRef={cancelButtonRef}
        header={'Are you sure you would like to add this product?'}
        iconStyles={'w-[22px] h-[22px] text-primary my-1 mr-3'}
        primaryButtonText={'Add'}
        secondaryButtonText="Back"
        primaryButtonStyles={
          'items-end bg-primary w-[72px] h-[32px] rounded-[20px] text-[12px] text-white whitespace-nowrap'
        }
        buttonFunction={() => createItem()}
        secondaryButtonStyles={
          'items-end bg-neutral-500 w-[72px] h-[32px] rounded-[20px] text-[12px] text-white whitespace-nowrap mr-2'
        }
      />
      <ConfirmationModalComponent
        showState={isCancelModalOpen}
        showStateController={setIsCancelModalOpen}
        cancelButtonRef={cancelButtonRef}
        header={'You are cancelling your current edits.'}
        description="Changes you've made will not be saved"
        iconStyles={'w-[22px] h-[22px] text-slate my-1 mr-3'}
        primaryButtonText={'Cancel'}
        primaryButtonStyles={
          'items-end bg-neutral-600 w-[72px] h-[32px] rounded-[20px] text-[12px] text-white whitespace-nowrap'
        }
        buttonFunction={() => {
          history.push({
            pathname: PAGE_URL_ADMIN_PORTAL.productManagement.viewPage(
              params.variantId,
            ),
            state: {
              from: history.location.pathname,
            },
          });
        }}
        secondaryButtonText="Back"
        secondaryButtonStyles={
          'items-end bg-neutral-500 w-[72px] h-[32px] rounded-[20px] text-[12px] text-white whitespace-nowrap mr-2'
        }
      />
    </>
  );
};

interface IImageSectionProps {
  onImageFilesChange: Function;
  formErrorMessage: string;
}

const ImageSectionComponent: React.FC<IImageSectionProps> = ({
  onImageFilesChange,
  formErrorMessage,
}) => {
  const [mainErrorMessage, setMainErrorMessage] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [imageFiles, setImageFiles] = useState<any[]>([]);

  const onImageUploadHandler = async e => {
    const file = e.target.files[0];
    // check if the image is at least 100 x 100 pixels and Max File Size is 16mb

    if (!isFileSizeValid(file, 1600000)) {
      setErrorMessage('Choose an image that is smaller than 16mb');
      return;
    }

    if (!(await isImageBigger(file, 100, 100))) {
      setErrorMessage('Choose a larger image than 100px * 100px');
      return;
    }

    setMainErrorMessage('');
    setImageFiles([...imageFiles, e.target.files[0]]);
  };

  const onImageDeleteHandler = indexDeleted => {
    const resultImages: any[] = [];
    // find the index of the image that has been removed
    for (let i = 0; i < imageFiles.length; i++) {
      if (i !== indexDeleted) {
        resultImages.push(imageFiles[i]);
      }
    }

    if (resultImages.length === 0) {
      setMainErrorMessage('Please upload at least one image');
    }

    setImageFiles(resultImages);
  };

  useEffect(() => {
    // propagate all the files upwards
    onImageFilesChange(imageFiles);
  }, [imageFiles]);

  useEffect(() => {
    setMainErrorMessage(formErrorMessage);
  }, [formErrorMessage]);

  const UploadComponent: React.FC<{ onUpload: ChangeEventHandler }> = ({
    onUpload,
  }) => {
    return (
      <>
        <div className="bg-slate-100 aspect-[1/1] flex flex-col justify-center text-center cursor-pointer border-dotted border border-slate">
          <div className="flex flex-col">
            <label className="flex flex-col p-4 font-poppins gap-2 cursor-pointer">
              <PlusOutlined className="text-xs"></PlusOutlined>
              <div className="text-xs ">Upload</div>
              {errorMessage && (
                <div className="text-error text-xs">{errorMessage}</div>
              )}
              <input
                type="file"
                accept="image/*"
                className="hidden"
                onChange={onUpload}
              ></input>
            </label>
          </div>
        </div>
      </>
    );
  };

  interface ImageProp {
    imageIndex: number;
    imageUrl: string;
    onDelete: Function;
  }

  const ImageComponent: React.FC<ImageProp> = ({
    onDelete,
    imageIndex,
    imageUrl,
  }) => {
    return (
      <>
        <div className="bg-slate-100 aspect-[1/1] flex flex-col justify-center text-center cursor-pointer border-solid border border-slate">
          <>
            <div
              className="image-container h-full w-full relative p-2"
              onClick={() => onDelete(imageIndex)}
            >
              <img className="h-full w-full object-cover" src={imageUrl} />
              <div className="image-hover absolute top-0 left-0 w-full h-full hidden">
                <DeleteOutlined className="text-white"></DeleteOutlined>
              </div>
            </div>
          </>
        </div>
      </>
    );
  };
  return (
    <>
      <div className="grid grid-cols-[1fr_2fr] gap-4">
        <div className="text-end">
          Image(s) <span className="text-error">*</span>:
        </div>
        <div className="flex flex-col gap-2 w-full">
          <div className="grid grid-cols-3 gap-4">
            {imageFiles.map((img, i) => (
              <ImageComponent
                key={i}
                onDelete={onImageDeleteHandler}
                imageIndex={i}
                imageUrl={URL.createObjectURL(img)}
              ></ImageComponent>
            ))}
            {imageFiles.length < 3 && (
              <UploadComponent
                onUpload={onImageUploadHandler}
              ></UploadComponent>
            )}
          </div>
          <div>1 compulsory, maximum 3</div>
          {mainErrorMessage && (
            <div className="text-error pt-4">{mainErrorMessage}</div>
          )}
        </div>
      </div>
    </>
  );
};
