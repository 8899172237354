export enum adminPortalAccountManagementPages {
  loginPage,
  profilePage,
  deleteAccountPage,
  deactivateAccountPage,
  createUserPage,
  updateAccountPage,
  accountsPage,
  forgetPasswordPage,
  firstLoginPage,
  updatePasswordPage,
  updateEmailPage,
}

export enum adminPortalProductManagementPages {
  createPage,
  deletePage,
  exportCataloguePage,
  updatePage,
  importPage,
  variantUpdatePage,
  viewPage,
}

export enum adminPortalCategoryManagementPages {
  createPage,
  viewPage,
  updatePage,
}

enum adminPortalAccountManagementPageTitles {
  loginPage = 'Admin Portal Login Page',
  profilePage = 'Admin Portal Profile Page',
  deleteAccountPage = 'Admin Portal Delete Account Page',
  deactivateAccountPage = 'Admin Portal Deactivate Account Page',
  createUserPage = 'Admin Portal Create Account Page',
  updateAccountPage = 'Admin Portal Update Account Page',
  accountsPage = 'Admin Portal Accounts Page',
  forgetPasswordPage = 'Admin Portal Forget Password Page',
  firstLoginPage = 'Admin Portal First Login Page',
  updatePasswordPage = 'Admin Portal Update Password Page',
  updateEmailPage = 'Admin Portal Update Email Page',
}

enum adminPortalProductManagementPageTitles {
  createPage = 'Admin Portal Create Product Page',
  deletePage = 'Admin Portal Delete Product Page',
  exportCataloguePage = 'Admin Portal Export Product Catalogue Page',
  updatePage = 'Admin Portal Update Product Page',
  importPage = 'Admin Portal Import Page',
  variantUpdatePage = 'Admin Portal Update Variant Page',
  viewPage = 'Admin Portal View Product Page',
}

enum adminPortalCategoryManagementPageTitles {
  createPage = 'Admin Portal Add Category Page',
  viewPage = 'Admin Portal View Categories Page',
  updatePage = 'Admin Portal Update Category Page',
}

interface adminPortalPageTitleStrings {
  accountManagement: {
    loginPage: string;
    profilePage: string;
    deleteAccountPage: string;
    deactivateAccountPage: string;
    createUserPage: string;
    updateAccountPage: string;
    accountsPage: string;
    forgetPasswordPage: string;
    firstLoginPage: string;
    updatePasswordPage: string;
    updateEmailPage: string;
  };
  productManagement: {
    createPage: string;
    deletePage: string;
    exportCataloguePage: string;
    updatePage: string;
    importPage: string;
    variantUpdatePage: string;
    viewPage: string;
  };
  categoryManagement: {
    createPage: string;
    viewPage: string;
    updatePage: string;
  };
  homePage: string;
  logoutPage: string;
}

export const PAGE_TITLES_ADMIN_PORTAL: adminPortalPageTitleStrings = {
  accountManagement: adminPortalAccountManagementPageTitles,
  productManagement: adminPortalProductManagementPageTitles,
  categoryManagement: adminPortalCategoryManagementPageTitles,
  homePage: 'Admin Portal Home Page',
  logoutPage: 'Admin Portal Logout Page',
};
