import React, { ChangeEventHandler, InputHTMLAttributes } from 'react';

interface IOptionProps {
  value: any;
  label: string;
}

interface IInputSelectProps extends InputHTMLAttributes<HTMLSelectElement> {
  id: string;
  label: string;
  name: string;
  defaultOptionMessage?: string;
  value: any;
  options: Array<IOptionProps>;
  onChange: ChangeEventHandler;
  isLabelHorizontal?: boolean;
  disabled?: boolean;
  error?: string;
}

export const InputSelectComponent: React.FC<IInputSelectProps> = ({
  id,
  label,
  value,
  options,
  name,
  onChange,
  defaultOptionMessage,
  isLabelHorizontal,
  disabled,
  required,
  error,
}) => {
  return (
    <div
      className={
        isLabelHorizontal
          ? 'grid grid-cols-[1fr_2fr] gap-x-4 items-center'
          : 'flex flex-col mb-1'
      }
    >
      <div className="justify-self-end">
        {label}
        {required && (
          <span>
            <span className="text-error">*</span>
          </span>
        )}
      </div>
      <select
        id={id}
        name={name}
        value={value ?? 'default'}
        onChange={onChange}
        className="flex-grow py-1.5 px-3 text-base font-medium border-[#e0e0e0] bg-neutral-200 text-neutral-600 focus:border-[#6A64F1] focus:shadow-md w-full rounded-md border outline-none"
        disabled={disabled}
      >
        <option disabled value="default">
          {defaultOptionMessage ?? 'Please Select An Option'}
        </option>
        {options.map(option => {
          return (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
      {error && (
        <div
          className={
            'text-error mt-1 ' + (isLabelHorizontal ? 'col-start-2' : '')
          }
        >
          {error}
        </div>
      )}
    </div>
  );
};
