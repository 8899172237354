import { CustomLink } from 'app/components/CustomLink';
import { PAGE_URL_ECommerce } from 'app/util/constants/pageUrls/ecommerce';
import React from 'react';

interface ISuccessPaymentBoxProps {
  donorName: string;
  dateReceived: string;
  timeReceived: string;
  donationAmount: number;
  paymentMethod: string;
  transactionNumber: string;
}

export const SuccessPaymentBox: React.FC<ISuccessPaymentBoxProps> = props => {
  return (
    <div className="px-6 py-2 mx-5 my-auto text-center border shadow-md md:px-12 lg:px-16 xl:px-24 md:py-4 lg:py-8 xl:py-12 lg:min-w-3xl xl:min-w-4xl bg-neutral-100/75 rounded-3xl border-neutral-200">
      <img
        src="/ecommerce/checkoutPages/successLogo.svg"
        alt=""
        className="relative ml-[15%] md:ml-[27%] -mt-[24%] hidden sm:block"
      />
      <h1 className="my-2 text-3xl lg:text-6xl font-extrabold text-primary">
        Payment Successful!
      </h1>
      <div className="py-5 font-normal text-md text-neutral-600">
        <p>Thank you for your monetary donations to FBSG Mart for Good.</p>
        <p>A confirmation email will be sent to you soon.</p>
      </div>
      <div className="grid lg:grid-cols-12">
        <div className="col-span-8 col-start-3 p-4 bg-white border shadow-md rounded-3xl border-neutral-200 xl:p-8">
          <div className="grid grid-cols-2 gap-4">
            <div className="text-left">
              <p>Donor Name:</p>
            </div>
            <div className="text-right">
              <p>{props.donorName}</p>
            </div>
            <div className="text-left">
              <p>Date Received:</p>
            </div>
            <div className="text-right">
              <p>{props.dateReceived}</p>
            </div>
            <div className="text-left">
              <p>Time Received:</p>
            </div>
            <div className="text-right">
              <p>{props.timeReceived}</p>
            </div>
            <div className="text-left">
              <p>Donation Made:</p>
            </div>
            <div className="text-right">
              <p>
                $ {(Math.round(props.donationAmount * 100) / 100).toFixed(2)}
              </p>
            </div>
            <div className="text-left">
              <p>Payment Method:</p>
            </div>
            <div className="text-right">
              <p>{props.paymentMethod}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex">
        <CustomLink
          to={PAGE_URL_ECommerce.landingPage}
          className="px-4 py-2 mx-auto mt-10 font-semibold text-white rounded-full bg-purple"
        >
          Return to Home Page
        </CustomLink>
      </div>
    </div>
  );
};
