import { useAuthValue } from 'app/AuthContext';
import { InputComponent } from 'app/components/Forms/Input/Loadable';
import { ConfirmationModalComponent } from 'app/components/Modals/ConfirmationModal/Loadable';
import { PAGE_URL_ADMIN_PORTAL } from 'app/util/constants/pageUrls/adminPortal';
import React from 'react';
import { useHistory } from 'react-router-dom';
import axiosInstance from 'utils/axios';

type AdminCreateUserFormProps = {
  className?: string;
};

export const AdminCreateUserForm: React.FC<AdminCreateUserFormProps> = ({
  className,
}) => {
  const { currentUser } = useAuthValue()!;
  const [userDetails, setUserDetails] = React.useState({
    firebaseId: '',
    type: 'FOODBANK',
    jobTitle: '',
    email: '',
    name: '',
    status: 'PENDING',
    uniqueURL: '',
  });
  const [errorMessages, setErrorMessages] = React.useState<any>({});
  const [isAddModalOpen, setIsAddModalOpen] = React.useState<boolean>(false);
  const [isCancelModalOpen, setIsCancelModalOpen] =
    React.useState<boolean>(false);
  const history = useHistory();

  const cancelButtonRef = React.useRef(null);
  //   const API_ENDPOINT = `${process.env.REACT_APP_PROXY_BACKEND_API_URL}/user`;

  React.useEffect(() => {}, [currentUser]);

  const handleFirebaseUserCreation = () => {
    handleCreateUser(userDetails)
      .then(() => {
        setUserDetails(prev => ({ ...prev, email: '' }));
      })
      .then(() => {
        setIsAddModalOpen(false);
        setIsCancelModalOpen(false);
      })
      .then(() => {
        history.push({
          pathname: PAGE_URL_ADMIN_PORTAL.accountManagement.accountsPage,
          state: {
            from: history.location.pathname,
          },
        });
      })
      .catch(err => {
        console.error(err);
        //throw new Error('There was an error setting the document!');
      });
  };

  const handleCreateUser = async data => {
    const token = await currentUser!.getIdToken();
    await axiosInstance
      .post(`/user`, data, {
        headers: {
          'x-admin-access': token,
        },
      })
      .then(res => {})
      .catch(err => {
        console.error('Error when creating', err);
      });
  };

  const updateDatabase = () => {
    setUserDetails(prev => ({ ...prev, email: '' }));
    setIsAddModalOpen(false);
    setIsCancelModalOpen(false);
    history.push({
      pathname: PAGE_URL_ADMIN_PORTAL.accountManagement.accountsPage,
      state: {
        from: history.location.pathname,
      },
    });
  };

  const handleChange = e => {
    const { value, name } = e.target;
    if (name === 'email') {
      setUserDetails(prev => ({ ...prev, firebaseId: value }));
    }
    setUserDetails(prev => ({ ...prev, [name]: value }));
  };

  const validateInputs = () => {
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(userDetails.email)) {
      setErrorMessages({
        emailError: 'The email you have entered is not valid.',
        setEmailError: 'Invalid Email',
      });
      return;
    } else {
      setErrorMessages({
        emailError: '',
        setEmailError: '',
      });
    }
    setIsAddModalOpen(true);
  };

  return (
    <>
      <div className="flex flex-col items-center pt-12">
        <div className="flex flex-col items-start justify-center">
          <div className="flex flex-col items-end">
            <div className="flex flex-row items-center">
              <p className="my-[15px] pr-[6px] text-right text-[14px] font-poppins">
                Email :
              </p>
              <InputComponent
                className="w-[400px] h-[32px] rounded-[2px] pl-[12px] pt-[8px] font-normal font-poppins leading-[20px] bg-neutral-200 border border-neutral-500 text-dark-blue text-[14px]"
                type="email"
                name="email"
                value={userDetails.email}
                id="email"
                onChange={handleChange}
                showError={errorMessages.setEmailError}
                error={errorMessages.emailError}
                placeholder="Enter Email"
              />
            </div>
            <div className="flex flex-row pt-2 pb-4">
              <p className="pt-[6px] pr-[6px] text-right text-[14px] font-poppins">
                User Type :
              </p>
              <select
                className="w-[400px] h-[32px] rounded-[2px] pl-[9px] pt-[2px] font-normal font-poppins leading-[20px] bg-neutral-200 border border-neutral-500 text-dark-blue text-[14px]"
                id="type"
                name="type"
                value={userDetails.type}
                onChange={handleChange}
                placeholder="Choose role"
              >
                <option value="FOODBANK">Food Bank</option>
                <option value="RESEARCH">Research</option>
              </select>
            </div>
          </div>
          <div className="flex gap-2 pl-20">
            <button
              className="h-[32px] rounded-[20px] px-4 text-sm text-white whitespace-nowrap bg-primary"
              children="Add User"
              type="button"
              onClick={() => validateInputs()}
            />
            <button
              className="h-[32px] rounded-[20px] px-4 text-sm text-white whitespace-nowrap bg-neutral-500"
              children="Cancel"
              type="button"
              onClick={() => setIsCancelModalOpen(true)}
            />
          </div>
        </div>
      </div>
      <ConfirmationModalComponent
        showState={isAddModalOpen}
        showStateController={setIsAddModalOpen}
        cancelButtonRef={cancelButtonRef}
        header={'Would you like to add this user?'}
        iconStyles={'w-[22px] h-[22px] text-primary my-1 mr-3'}
        primaryButtonText={'Yes'}
        secondaryButtonText={'Back'}
        primaryButtonStyles={
          'items-end bg-primary w-[72px] h-[32px] rounded-[20px] text-[12px] whitespace-nowrap'
        }
        buttonFunction={() => handleFirebaseUserCreation()}
        secondaryButtonStyles={
          'items-end bg-neutral-500 w-[72px] h-[32px] rounded-[20px] text-[12px] whitespace-nowrap mr-2'
        }
      />
      <ConfirmationModalComponent
        showState={isCancelModalOpen}
        showStateController={setIsCancelModalOpen}
        cancelButtonRef={cancelButtonRef}
        header={'You are cancelling your current edits.'}
        description={`Changes you've made will not be saved.`}
        iconStyles={'w-[22px] h-[22px] text-neutral-600 my-1 mr-3'}
        primaryButtonText={'OK'}
        secondaryButtonText={'Back'}
        primaryButtonStyles={
          'items-end bg-neutral-600 w-[72px] h-[32px] rounded-[20px] text-[12px] whitespace-nowrap'
        }
        buttonFunction={() => updateDatabase()}
        secondaryButtonStyles={
          'items-end bg-neutral-500 w-[72px] h-[32px] rounded-[20px] text-[12px] whitespace-nowrap mr-2'
        }
      />
    </>
  );
};
