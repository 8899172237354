import { AdminDeleteFoodItemForm } from 'app/pages/Admin/Product/Delete/DeleteProductPage/components/AdminDeleteFoodItemForm';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { PAGE_TITLES_ADMIN_PORTAL } from 'app/util/constants/pageTitles/adminPortal';

export function DeleteProductPage() {
  return (
    <>
      <Helmet>
        <title>{PAGE_TITLES_ADMIN_PORTAL.productManagement.deletePage}</title>
        <meta
          name="description"
          content={PAGE_TITLES_ADMIN_PORTAL.productManagement.deletePage}
        />
      </Helmet>
      <span>Delete Food Item</span>
      <AdminDeleteFoodItemForm></AdminDeleteFoodItemForm>
    </>
  );
}
