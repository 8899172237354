import { Input } from 'app/components/Forms/Input';
import React from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from 'utils/axios';

interface IAdminDeleteFoodItemFormProps {
  className?: string;
}

type DeleteObject = {
  productCode: string;
  variantId: string;
  categoryCode: string;
};

export const AdminDeleteFoodItemForm: React.FC<
  IAdminDeleteFoodItemFormProps
> = ({ className }) => {
  const params: { id: string } = useParams();

  const [deleteObject, setDeleteObject] = React.useState<DeleteObject>({
    productCode: '',
    variantId: '',
    categoryCode: '',
  });

  const handleItemDelete = async e => {
    e.preventDefault();
    await axiosInstance.delete(`/product/${deleteObject.productCode}`);
  };

  const handleCategoryDelete = async e => {
    e.preventDefault();
    await axiosInstance.delete(`/category/${deleteObject.categoryCode}`);
  };

  const handleVariantDelete = async e => {
    e.preventDefault();
    await axiosInstance.delete(`/variant/${deleteObject.variantId}`);
  };

  const handleChange = e => {
    const { value, name } = e.target;
    setDeleteObject(prev => ({ ...prev, [name]: value }));
  };

  return (
    <div className="flex items-center justify-center p-12 mx-auto w-full max-w-[550px]">
      <form onSubmit={handleItemDelete}>
        <Input
          title="Product Code"
          type="text"
          name="productCode"
          id="productCode"
          value={deleteObject?.productCode}
          onChange={handleChange}
          placeholder="SKU_100"
        />
        <div>
          <button type="submit">Submit</button>
        </div>
      </form>
      <form onSubmit={handleCategoryDelete}>
        <Input
          title="Category"
          type="text"
          name="categoryCode"
          id="categoryCode"
          value={deleteObject?.categoryCode}
          onChange={handleChange}
          placeholder="Drinks"
        />
        <div>
          <button type="submit">Submit</button>
        </div>
      </form>
      <form onSubmit={handleVariantDelete}>
        <Input
          title="Variant ID"
          type="text"
          name="variantId"
          id="variantId"
          value={deleteObject?.variantId}
          onChange={handleChange}
          placeholder="Variant A"
        />
        <div>
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
  );
};
