import { getProducts, updateManyProducts } from 'app/api/ProductService';
import { Button } from 'app/components/Buttons/Button';
import { ConfirmationModalComponent } from 'app/components/Modals/ConfirmationModal/Loadable';
import { CustomBreadcrumb } from 'app/components/Navigation/BreadCrumbs';
import { Product } from 'app/models/Product';
import { PAGE_TITLES_ADMIN_PORTAL } from 'app/util/constants/pageTitles/adminPortal';
import { PAGE_URL_ADMIN_PORTAL } from 'app/util/constants/pageUrls/adminPortal';
import {
  normaliseVariantId,
  VariantContext,
  VariantIds,
} from 'app/VariantContext';
import { stat } from 'fs';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useParams } from 'react-router-dom';

import { AdminCategoryFilterComponent } from './components/AdminCategoryFilter';
import { AdminItemModalComponent } from './components/AdminItemModal';
import { AdminItemPaginationComponent } from './components/AdminItemPagination';
import { AdminItemTableComponent } from './components/AdminItemTable';
import { AdminItemSearchComponent } from './components/AdminSearchItem';
import { CustomLink } from 'app/components/CustomLink';

export function ProductManagementPage() {
  // required meta
  let { variantId } = useParams<{ variantId: VariantIds }>();

  // modals
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isDeactivateModalOpen, setIsDeactivateModalOpen] =
    useState<boolean>(false);
  const [isActivateModalOpen, setIsActivateModalOpen] =
    useState<boolean>(false);
  const cancelButtonRef = React.useRef(null);

  // data
  const [items, setItems] = useState<Product[]>([]);
  const [selectedItemIds, setSelectedItemsIds] = useState<number[]>([]);

  // pagination
  const [totalPages, setTotalPages] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);

  // queries
  const [categoryCodesSelected, setCategoryCodesSelected] =
    useState<number[]>();
  const [status, setStatus] = useState<'ACTIVE' | 'INACTIVE'>('ACTIVE');
  const [search, setSearch] = useState<{
    type: 'sku_code' | 'name';
    text: string;
  }>({ type: 'name', text: '' });
  const [sort, setSort] = useState<{
    field: 'quantity' | 'price' | 'rank';
    direction: 'asc' | 'desc';
  }>({ field: 'price', direction: 'asc' });

  // Handlers

  // Change the category codes based on the Category Component selected ids
  const categoryChangeHandler = (categoryCodes: number[]) => {
    categoryCodes.length
      ? setCategoryCodesSelected(categoryCodes)
      : setCategoryCodesSelected(undefined);
  };

  // Change the search input based on the Search Component input
  const searchChangeHandler = (newSearch: {
    type: 'name' | 'sku_code';
    text: string;
  }) => {
    setSearch(newSearch);
  };

  // Change the status displayed on the table based on the tab
  const statusChangeHandler = (status: 'ACTIVE' | 'INACTIVE') => {
    setStatus(status);
    setSelectedItemsIds([]); // reset selected
  };

  // Change the page based on the current page emitted by the Pagination Component
  const onPageChange = page => {
    setCurrentPage(page);
  };

  // open the modals based on the action when one of the action buttons are pressed
  const actionHandler = (action: 'ACTIVE' | 'INACTIVE' | 'DELETED') => {
    switch (action) {
      case 'ACTIVE':
        // _updateStatusData.status = 'ACTIVE';
        setIsActivateModalOpen(true);
        break;
      case 'INACTIVE':
        setIsDeactivateModalOpen(true);
        break;
      case 'DELETED':
        setIsDeleteModalOpen(true);
        break;
    }
  };

  const sortChangeHandler = (sort: {
    field: 'quantity' | 'price' | 'rank';
    direction: 'asc' | 'desc';
  }) => {
    setSort(sort);
  };

  // LOAD DATA

  // load items based on filters
  const loadItems = async () => {
    const pageLimit = 15;
    const res = await getProducts(
      {
        variant_ids: [variantId],
        category_codes: categoryCodesSelected?.length
          ? categoryCodesSelected
          : undefined,
        status: status,
        name:
          search.type == 'name' && search?.text?.length
            ? search.text
            : undefined,
        sku_code:
          search.type == 'sku_code' && search?.text?.length
            ? search.text
            : undefined,
      },
      { [`sort_${sort.field}`]: sort.direction },
      { page: currentPage, limit: pageLimit },
    );
    setTotalPages(Math.ceil(res.meta.count / pageLimit));
    setItems(res.data);
  };

  // UPDATE DATA

  // update the status at the backend when the "confirm" button of the modal is pressed
  const updateStatus = async (action: 'ACTIVE' | 'INACTIVE' | 'DELETED') => {
    let _updateStatusData = { status: '' };

    // set the status that we want to update to based on the action
    switch (action) {
      case 'ACTIVE':
        _updateStatusData.status = 'ACTIVE';
        break;
      case 'INACTIVE':
        _updateStatusData.status = 'INACTIVE';
        break;
      case 'DELETED':
        _updateStatusData.status = 'DELETED';
        break;
    }

    // set every modal to false
    setIsActivateModalOpen(false);
    setIsDeactivateModalOpen(false);
    setIsDeleteModalOpen(false);

    await updateManyProducts(selectedItemIds, _updateStatusData);

    // reload the items and reset all the selected ids
    loadItems();

    setSelectedItemsIds([]);
  };

  // load the variant only once
  //   useEffect(() => {
  //     loadVariant();
  //   }, []);

  // reload item for every query change
  useEffect(() => {
    loadItems();
  }, [categoryCodesSelected, currentPage, status, search, variantId, sort]);

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLES_ADMIN_PORTAL.productManagement.viewPage}</title>
        <meta
          name="description"
          content={PAGE_TITLES_ADMIN_PORTAL.productManagement.viewPage}
        />
      </Helmet>
      <CustomBreadcrumb
        data={[
          {
            label: `Variant ${normaliseVariantId(variantId)}`,
          },
          {
            label: 'Product Management',
            link: PAGE_URL_ADMIN_PORTAL.productManagement.viewPage(variantId),
            isCurrentPage: true,
          },
        ]}
      />

      <VariantContext.Provider value={variantId}>
        <div className="flex flex-row justify-between border-y border-solid border-slate border-y-1 p-4 w-full">
          <AdminItemSearchComponent
            onSearchChange={searchChangeHandler}
          ></AdminItemSearchComponent>
        </div>

        <div className="flex flex-row justify-between border-b border-solid border-slate border-y-1 p-4">
          <AdminCategoryFilterComponent
            onSelectedCategoryChange={categoryChangeHandler}
            variantId={variantId}
          ></AdminCategoryFilterComponent>
          <div className="flex flex-col items-end gap-2">
            <div className="flex flex-row gap-2">
              <CustomLink
                to={PAGE_URL_ADMIN_PORTAL.productManagement.createPage(
                  variantId ? variantId : VariantIds.A,
                )}
              >
                <Button className="inline-block items-end !bg-primary !rounded-full whitespace-nowrap">
                  Add Product
                </Button>
              </CustomLink>

              <CustomLink
                to={PAGE_URL_ADMIN_PORTAL.productManagement.exportCataloguePage(
                  variantId ? variantId : VariantIds.A,
                )}
              >
                <Button className="items-end !bg-dark-blue !rounded-full inline-block whitespace-nowrap ">
                  Export Catalogue
                </Button>
              </CustomLink>
            </div>
            <div className="flex flex-row gap-2">
              <Button
                className={
                  'items-end !bg-pink !rounded-full inline-block  whitespace-nowrap ' +
                  (selectedItemIds.length
                    ? '!bg-pink '
                    : '!bg-slate-200 cursor-not-allowed')
                }
                onClick={
                  selectedItemIds.length
                    ? () => actionHandler('DELETED')
                    : () => null
                }
              >
                Delete
              </Button>
              {status === 'ACTIVE' ? (
                <Button
                  className={
                    'items-end !rounded-full inline-block whitespace-nowrap ' +
                    (selectedItemIds.length
                      ? '!bg-orange '
                      : '!bg-slate-200 cursor-not-allowed')
                  }
                  onClick={
                    selectedItemIds.length
                      ? () => actionHandler('INACTIVE')
                      : () => null
                  }
                >
                  Deactivate
                </Button>
              ) : (
                <Button
                  className={
                    'items-end !bg-orange !rounded-full inline-block whitespace-nowrap ' +
                    (selectedItemIds.length
                      ? '!bg-light-green'
                      : '!bg-slate-200 cursor-not-allowed')
                  }
                  onClick={
                    selectedItemIds.length
                      ? () => actionHandler('ACTIVE')
                      : () => null
                  }
                >
                  Activate
                </Button>
              )}
            </div>
          </div>
        </div>

        <div className="flex flex-row border-b border-slate">
          {[
            { value: 'ACTIVE', label: 'Active' },
            { value: 'INACTIVE', label: 'Inactive' },
          ].map(s => (
            <div
              key={s.value}
              className={
                'py-3 px-6 cursor-pointer ' +
                (status === s.value ? 'border-b-2  border-primary' : '')
              }
              onClick={() =>
                statusChangeHandler(s.value as 'ACTIVE' | 'INACTIVE')
              }
            >
              {s.label}
            </div>
          ))}
        </div>

        <div className="p-4">
          {items.length ? (
            <AdminItemTableComponent
              onSortChange={sortChangeHandler}
              onActionClicked={loadItems}
              items={items}
              showActions={true}
              initialSelectedItemIds={selectedItemIds}
              onSelectedItemsChange={newSelectedItemIds =>
                setSelectedItemsIds(newSelectedItemIds)
              }
            ></AdminItemTableComponent>
          ) : (
            <div> There are no items </div>
          )}
        </div>

        <div className="flex flex-row justify-between items-center p-4">
          <div className="underline" onClick={() => setShowModal(true)}>
            {selectedItemIds.length} Items selected
          </div>
          <AdminItemPaginationComponent
            totalPages={totalPages ?? 0}
            onPageChange={onPageChange}
          ></AdminItemPaginationComponent>
        </div>

        <AdminItemModalComponent
          showModal={showModal}
          setShowModal={setShowModal}
          selectedItemIds={selectedItemIds}
          onSelectedItemIdsChange={ids => setSelectedItemsIds(ids)}
        ></AdminItemModalComponent>

        <ConfirmationModalComponent
          showState={isDeleteModalOpen}
          showStateController={setIsDeleteModalOpen}
          cancelButtonRef={cancelButtonRef}
          header={'Are you sure you would like to delete these products?'}
          description={
            "This is irreversible - you would not be able to access this product's information permanently"
          }
          iconStyles={'w-[22px] h-[22px] text-[#FF3A79] my-1 mr-3'}
          primaryButtonText={'Delete'}
          primaryButtonStyles={
            'items-end bg-[#FF3A79] h-[32px] rounded-[20px] text-[12px] whitespace-nowrap px-4'
          }
          buttonFunction={() => updateStatus('DELETED')}
          secondaryButtonText="Back"
          secondaryButtonStyles={
            'items-end bg-neutral-500 w-[72px] h-[32px] rounded-[20px] text-[12px] whitespace-nowrap mr-2'
          }
        />

        <ConfirmationModalComponent
          showState={isDeactivateModalOpen}
          showStateController={setIsDeactivateModalOpen}
          cancelButtonRef={cancelButtonRef}
          header={'Are you sure you would like to deactivate these products?'}
          description={
            'You can still access deactivated products in the product listing'
          }
          iconStyles={'w-[22px] h-[22px] text-orange my-1 mr-3'}
          primaryButtonText={'Deactivate'}
          primaryButtonStyles={
            'items-end bg-orange h-[32px] rounded-[20px] text-[12px] whitespace-nowrap'
          }
          buttonFunction={() => updateStatus('INACTIVE')}
          secondaryButtonText="Back"
          secondaryButtonStyles={
            'items-end bg-neutral-500 w-[72px] h-[32px] rounded-[20px] text-[12px] whitespace-nowrap mr-2'
          }
        />

        <ConfirmationModalComponent
          showState={isActivateModalOpen}
          showStateController={setIsActivateModalOpen}
          cancelButtonRef={cancelButtonRef}
          header={'Are you sure you want to set the items to active?'}
          iconStyles={'w-[22px] h-[22px] text-primary my-1 mr-3'}
          primaryButtonText={'Activate'}
          primaryButtonStyles={
            'items-end !bg-primary w-[72px] h-[32px] rounded-[20px] text-[12px] whitespace-nowrap'
          }
          buttonFunction={() => updateStatus('ACTIVE')}
          secondaryButtonStyles={
            'items-end bg-neutral-500 w-[72px] h-[32px] rounded-[20px] text-[12px] whitespace-nowrap mr-2'
          }
        />
      </VariantContext.Provider>
    </>
  );
}
