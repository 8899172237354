interface ecommercePageUrlStrings {
  landingPage: string;
  shoppingCartPage: string;
  itemPage: string;
  checkoutPage: string;
  loginPage: string;
  logoutPage: string;
  paymentPage: string;
  paymentSuccessPage: string;
  paymentFailurePage: string;
  signUpPage: string;
}

export const PAGE_URL_ECommerce: ecommercePageUrlStrings = {
  landingPage: '/',
  shoppingCartPage: '/cart',
  itemPage: '/item',
  checkoutPage: '/checkout',
  loginPage: '/login',
  logoutPage: '/logout',
  paymentPage: '/payment',
  paymentSuccessPage: '/payment/success',
  paymentFailurePage: '/payment/failure',
  signUpPage: '/signup',
};
