export const isFormValid = (errorMessages: Object) => {
  let isValid = true;
  for (let key in errorMessages) {
    if (errorMessages[key] !== undefined) {
      isValid = false;
      break;
    }
  }
  return isValid;
};
