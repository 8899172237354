/**
 * Asynchronously loads the component for Card
 */

import { lazyLoad } from 'utils/loadable';

export const CardComponent = lazyLoad(
  () => import('./Card/index'),
  module => module.Card,
);

export const ProductCardComponent = lazyLoad(
  () => import('./ProductCard/index'),
  module => module.ProductCard,
);
