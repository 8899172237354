import { ButtonComponent } from 'app/components/Buttons/Button/Loadable';
import { Input } from 'app/components/Forms/Input';
import { USER_PUT_DEACTIVATE_USER_ENDPOINT } from 'app/util/constants/apiEndpoints/UserEndpoints';
import { getAuth } from 'firebase/auth';
import React from 'react';
import axiosInstance from 'utils/axios';

type DeactivateFormProps = {
  className?: string;
};

export const DeactivateForm: React.FC<DeactivateFormProps> = ({
  className,
}) => {
  const auth = getAuth();
  const [userUid, setUserUid] = React.useState<string>('');
  const handleUserDeactivate = async () => {
    let reqBody = {
      uid: userUid,
    };
    return await axiosInstance.post(
      USER_PUT_DEACTIVATE_USER_ENDPOINT(userUid),
      reqBody,
    );
  };

  return (
    <div className="flex items-center justify-center p-12 mx-auto w-full max-w-[550px]">
      <form onSubmit={handleUserDeactivate}>
        <Input
          title="Deactivate User"
          type="password"
          name="oldpassword"
          id="oldpassword"
          value={userUid}
          onChange={e => {
            setUserUid(e.target.value);
          }}
          placeholder="User Uid"
        />
        <div>
          <ButtonComponent type="submit">Submit</ButtonComponent>
        </div>
      </form>
    </div>
  );
};
