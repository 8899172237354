import CryptoJS from 'crypto-js';

// can potentially move this to .env in the future
const SECRET = 'Gambe_I_Simp_For_You';
// TODO: change this when we eventually host somewhere else
const DOMAIN = `${process.env.PUBLIC_URL}/admin/login/first`;

// takes in an admin's email and usertype, encodes it into the url
// generates a useable hyperlink
export const encodeParamsForAdminSignUpPage = (uid: string) => {
  // cryptojs encodes with slashses, which are protected for url
  // it doesn't produce asteriks, so we use that for conversion
  const encodedUid = CryptoJS.AES.encrypt(uid, SECRET)
    .toString()
    .replaceAll('/', '*');
  const hyperlink = DOMAIN + '/' + encodedUid;
  return hyperlink;
};

// given an encoded string, decodes it
export const decodeParam = (encodedString: string) => {
  const modifiedString = encodedString.replaceAll('*', '/');
  return CryptoJS.AES.decrypt(modifiedString, SECRET).toString(
    CryptoJS.enc.Utf8,
  );
};
