import React from 'react';
import { Redirect } from 'react-router-dom';
interface IFailurePaymentBoxProps {}
export const FailurePaymentBox: React.FC<IFailurePaymentBoxProps> = props => {
  const [countdownTime, setCountdownTime] = React.useState<number>(1);
  setTimeout(() => {
    if (countdownTime > 0) {
      setCountdownTime(countdownTime - 1);
    }
  }, 1000);
  return countdownTime > 0 ? (
    <div className="max-w-4xl p-4 mx-5 my-auto bg-white border shadow-md md:p-16 lg:p-16 rounded-3xl border-neutral-200">
      <div className="grid grid-cols-12 gap-4 gap-xl-8">
        <div className="col-span-12 mx-auto md:col-span-5 xl:col-span-4">
          <img src="/ecommerce/checkoutPages/failureLogo.svg" alt="" />
        </div>
        <div className="col-span-12 my-auto md:col-span-7 xl:col-span-8">
          <h1 className="my-2 text-xl font-extrabold tracking-tight text-center md:text-2xl lg:text-3xl xl:text-5xl text-neutral-900 md:text-left">
            Payment Failed
          </h1>
          <p className="my-5 text-lg font-normal text-center md:text-xl lg:text-xl text-neutral-600 md:text-left">
            Please wait while we redirect you to complete your transaction.
          </p>
          <p>Redirecting in {countdownTime}</p>
        </div>
      </div>
    </div>
  ) : (
    <Redirect to="/checkout" />
  );
};
