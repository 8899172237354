import { FilterOutlined } from '@ant-design/icons';
import {
  deleteCategory,
  deleteCategoryImage,
  getCategories,
} from 'app/api/CategoryService';
import { Button } from 'app/components/Buttons/Button';
import { CustomLink } from 'app/components/CustomLink';
import { CustomBreadcrumb } from 'app/components/Navigation/BreadCrumbs';
import { Category } from 'app/models/Category';
import { Product } from 'app/models/Product';
import { CategoryModal } from 'app/pages/Admin/Category/View/CategoryManagementPage/components/CategoryModal';
import { DeleteModal } from 'app/pages/Admin/Category/View/CategoryManagementPage/components/DeleteCategoryModal';
import { UnableDeleteModal } from 'app/pages/Admin/Category/View/CategoryManagementPage/components/UnableDeleteCategoryModal';
import { AdminItemPaginationComponent } from 'app/pages/Admin/Product/View/ProductManagementPage/components/AdminItemPagination';
import { PAGE_TITLES_ADMIN_PORTAL } from 'app/util/constants/pageTitles/adminPortal';
import { PAGE_URL_ADMIN_PORTAL } from 'app/util/constants/pageUrls/adminPortal';
import { normaliseVariantId, VariantIds } from 'app/VariantContext';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useParams } from 'react-router-dom';

enum TableHeader {
  ID = 'ID',
  Category = 'Category',
  Type = 'Type',
  Image = 'Image',
  NumberOfProducts = 'Number Of Products',
  Actions = 'Actions',
}
interface CategoryData extends Category {
  numberOfProducts: number;
}

interface TableContentsProps {
  data: CategoryData;
}

interface TableProps {
  categories: CategoryData[];
}

enum FoodType {
  Food = 'FOOD',
  NonFood = 'NONFOOD',
}

function sanitizeFilterTitle(foodType: string): string {
  if (foodType === FoodType.Food) {
    return 'Food';
  } else {
    return 'Non Food';
  }
}
export function CategoryManagementPage() {
  let history = useHistory();
  let { variantId } = useParams<{ variantId: VariantIds }>();

  // States
  const [filtered, setFiltered] = useState<string>('');
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const filterList = [FoodType.Food, FoodType.NonFood];
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [categories, setCategories] = useState<CategoryData[]>([]);
  const [filteredContent, setFilteredContent] = useState<CategoryData[]>();
  const [isFetchingCategories, setIsFetchingCategories] = useState(true);

  // State for pagination
  const [totalPages, setTotalPages] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);

  // Modal states
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openUnableDeleteModal, setOpenUnableDeleteModal] =
    useState<boolean>(false);
  const [categoryIdBeingDeleted, setCategoryIdBeingDeleted] =
    useState<number>();
  const [categoryImageBeingDeleted, setCategoryImageBeingDeleted] =
    useState<string>('');

  // Category modal states
  const [categoryModalNumberOfProducts, setCategoryModalNumberOfProducts] =
    useState(0);
  const [categoryModalProducts, setCategoryModalProducts] =
    useState<Product[]>();

  // Helper function
  const handleFilter = selectedFilter => {
    if (selectedFilter !== filtered) {
      setFiltered(selectedFilter);
    } else {
      setFiltered('');
    }
  };

  const handleSelectAll = selectedAll => {
    setSelectAll(selectedAll.target.checked);
  };

  const handleModal = selectedCategory => {
    setSelectedCategory(selectedCategory);
    setOpenModal(!openModal);
  };

  const handleData = (categories: Category[]) => {
    return categories.map(category => {
      const products = category.products?.filter(
        product => product.status === 'ACTIVE',
      );
      return {
        ...category,
        products: products,
        numberOfProducts: products?.length ?? 0,
      };
    });
  };

  const handleDelete = () => {
    if (!categoryIdBeingDeleted) {
      return;
    }
    deleteCategory(categoryIdBeingDeleted).then(() => {
      loadCategories();
      setOpenDeleteModal(false);
    });
    deleteCategoryImage(categoryIdBeingDeleted, categoryImageBeingDeleted);
  };

  const loadCategories = useCallback(() => {
    if (variantId !== undefined) {
      getCategories({
        variantIds: [variantId],
      }).then(payload => {
        setCategories(handleData(payload.data));
        setIsFetchingCategories(false);
        setTotalPages(Math.ceil(payload.data.length / 10));
      });
    }
  }, [variantId]);

  // Use effect
  useEffect(() => {
    loadCategories();
  }, [variantId, loadCategories]);

  useEffect(() => {
    if (!isFetchingCategories) {
      // Handle pagination first
      const currentStartIndex = (currentPage - 1) * 10;
      const currentEndIndex = currentStartIndex + 10;
      let currentCategories = categories.slice(currentStartIndex);
      if (currentCategories.length >= 10) {
        currentCategories = categories.slice(
          currentStartIndex,
          currentEndIndex,
        );
      }

      if (filtered !== '') {
        setFilteredContent(
          currentCategories.filter(category => category.nature === filtered),
        );
      } else {
        setFilteredContent(currentCategories);
      }
    }
  }, [filtered, categories, isFetchingCategories, currentPage]);

  // Table components
  const TableHeaders = () => {
    return (
      <thead>
        <tr className="flex flex-row flex-grow items-center bg-[#33424E] text-neutral-200 text-l">
          <th className="flex min-w-[32px] p-4 border border-black/[.06]">
            <input
              type="checkbox"
              className="text-black accent-neutral-200 bg-neutral-200"
              onChange={e => handleSelectAll(e)}
              checked={selectAll}
            />
          </th>
          <th className="flex flex-1 min-w-[177px] bg-[#33424E] p-4 border border-black/[.06]">
            {TableHeader.ID}
          </th>
          <th className="flex flex-1 min-w-[256px] bg-[#33424E] p-4 border border-black/[.06]">
            {TableHeader.Category}
          </th>
          <th className="flex flex-1 min-w-[177px] bg-[#33424E] p-4 border border-black/[.06]">
            {TableHeader.Type}
          </th>
          <th className="flex flex-1 min-w-[120px] bg-[#33424E] p-4 border border-black/[.06]">
            {TableHeader.Image}
          </th>
          <th className="flex flex-1 min-w-[200px] bg-[#33424E] p-4 border border-black/[.06]">
            {TableHeader.NumberOfProducts}
          </th>
          <th className="flex flex-1 min-w-[223px] bg-[#33424E] p-4 border border-black/[.06]">
            {TableHeader.Actions}
          </th>
        </tr>
      </thead>
    );
  };

  const TableContents: React.FC<TableContentsProps> = ({ data }) => {
    const [isChecked, setIsChecked] = useState<boolean>(selectAll);

    const handleClickEdit = () => {
      const editPageUrl = PAGE_URL_ADMIN_PORTAL.categoryManagement.updatePage(
        variantId,
        data.categoryCode.toString(),
      );
      history.push({
        pathname: editPageUrl,
        state: {
          from: history.location.pathname,
        },
      });
    };

    const handleClickDelete = () => {
      if (data.numberOfProducts > 0) {
        setOpenUnableDeleteModal(true);
      } else {
        setOpenDeleteModal(true);
        setCategoryIdBeingDeleted(data.categoryCode);
        setCategoryImageBeingDeleted(data.image ?? '');
      }
    };

    return (
      <tr className="flex flex-row flex-grow items-center text-black/80 border-b border-black/[0.06]">
        <td className="flex min-w-[32px] p-4">
          <input
            type="checkbox"
            className="text-black accent-neutral-200 bg-neutral-200"
            checked={isChecked}
            onChange={() => setIsChecked(!isChecked)}
          />
        </td>
        <td className="flex flex-1 min-w-[177px] p-4">{data.categoryCode}</td>
        <td className="flex flex-1 min-w-[256px] p-4">{data.name}</td>
        <td className="flex flex-1 min-w-[177px] p-4">
          {sanitizeFilterTitle(data.nature)}
        </td>
        <td className="flex flex-1 min-w-[120px] p-4">{data.image}</td>
        <td
          className="flex flex-1 min-w-[200px] p-4 text-primary hover:cursor-pointer"
          onClick={() => {
            handleModal(data.name);
            setCategoryModalNumberOfProducts(data.numberOfProducts);
            setCategoryModalProducts(data.products);
          }}
        >
          {data.numberOfProducts}
        </td>
        <td className="flex flex-1 flex-row gap-4 min-w-[223px] p-4 text-primary">
          <span className="cursor-pointer" onClick={handleClickEdit}>
            Edit
          </span>
          <span className="cursor-pointer" onClick={handleClickDelete}>
            Delete
          </span>
        </td>
      </tr>
    );
  };

  const Table: React.FC<TableProps> = ({ categories }) => {
    return (
      <table
        style={{ overflowX: 'scroll' }}
        className="flex flex-col flex-grow text-sm"
      >
        <TableHeaders />
        <tbody>
          {categories &&
            categories.map((content, index) => (
              <TableContents data={content} key={index} />
            ))}
        </tbody>
      </table>
    );
  };

  // Change the page based on the current page emitted by the Pagination Component
  const onPageChange = page => {
    setCurrentPage(page);
  };

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLES_ADMIN_PORTAL.categoryManagement.viewPage}</title>
        <meta
          name="description"
          content={PAGE_TITLES_ADMIN_PORTAL.categoryManagement.viewPage}
        />
      </Helmet>
      <CustomBreadcrumb
        data={[
          {
            label: `Variant ${normaliseVariantId(variantId)}`,
          },
          {
            label: 'Category Management',
            link: PAGE_URL_ADMIN_PORTAL.categoryManagement.viewPage(variantId),
            isCurrentPage: true,
          },
        ]}
      />
      <main>
        <div className="flex flex-col flex-1">
          <div className="flex border-b border-[#D9D9D9] py-1.5">
            <div className="flex flex-row gap-2.5 items-center flex-1 px-2.5">
              <div className="flex flex-row items-center gap-2.5 h-6">
                <FilterOutlined className="text-neutral-600" />
              </div>
              <div className="flex gap-1.5">
                {filterList &&
                  filterList.map((filter, index) => (
                    <Button
                      key={index}
                      className={`text-neutral-500 border rounded-3xl px-[15px] h-6 ${
                        filtered === filter
                          ? '!bg-[#33424E] text-neutral-200 border-[#33424E]'
                          : '!bg-neutral-200 border-neutral-500'
                      }`}
                      children={
                        <span
                          className="text-xs leading-[1.375rem]"
                          onClick={() => handleFilter(filter)}
                        >
                          {sanitizeFilterTitle(filter)}
                        </span>
                      }
                      type={'submit'}
                    />
                  ))}
              </div>
            </div>
            <div className="mr-8">
              <CustomLink
                to={PAGE_URL_ADMIN_PORTAL.categoryManagement.createPage(
                  variantId,
                )}
                className="text-white border-primary border rounded-3xl !bg-primary py-1 px-4 h-8"
              >
                <span className="text-sm leading-[1.375rem]">Add Category</span>
              </CustomLink>
            </div>
          </div>
          <div className="flex flex-grow p-6">
            {filteredContent && <Table categories={filteredContent} />}
          </div>
          <div className="absolute bottom-6 right-0 flex flex-row justify-end items-center pr-6">
            <AdminItemPaginationComponent
              totalPages={totalPages ?? 0}
              onPageChange={onPageChange}
            ></AdminItemPaginationComponent>
          </div>
        </div>
      </main>
      <CategoryModal
        isModalOpen={openModal}
        category={selectedCategory}
        products={categoryModalProducts}
        closeModal={() => setOpenModal(false)}
      />
      <DeleteModal
        isModalOpen={openDeleteModal}
        closeModal={() => setOpenDeleteModal(false)}
        handleDelete={handleDelete}
      />
      <UnableDeleteModal
        isModalOpen={openUnableDeleteModal}
        closeModal={() => setOpenUnableDeleteModal(false)}
      />
    </>
  );
}
