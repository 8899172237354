import { DidYouKnow } from 'app/components/Ecommerce/DidYouKnow';
import { EcommerceSignupForm } from 'app/components/Forms/EcommerceSignupForm';
import { PAGE_TITLES_ECommerce } from 'app/util/constants/pageTitles/ecommerce';
import React from 'react';
import { Helmet } from 'react-helmet-async';

export function EcommerceSignupPage() {
  return (
    <>
      <Helmet>
        <title>{PAGE_TITLES_ECommerce.signupPage}</title>
        <meta name="description" content={PAGE_TITLES_ECommerce.signupPage} />
      </Helmet>
      <div className="relative bg-primary h-[100vh]">
        {/* Background picture */}
        <div className="hidden absolute lg:flex items-center h-[100vh] right-0 py-4 pr-4">
          <img
            src="ecommerce_signup.png"
            alt="ecommerce_signup"
            className="right-0 h-full z-0"
          />
        </div>
        <div className="flex relative items-center 2xl:justify-center h-full z-50 mx-4 lg:mx-0 lg:pl-44 2xl:pl-0 ">
          <div className="flex flex-col flex-grow bg-white lg:max-w-[573px] lg:h-[748px] text-center py-2 px-8 lg:py-20 lg:px-16 rounded-[10px]">
            <div className="w-full flex flex-col gap-4">
              <div className="flex flex-col">
                <h2 className="text-2xl lg:text-[40px] leading-[64px] font-bold m-0">
                  Start Mart for Good
                </h2>
                <span className="text-base leading-7 m-0 text-neutral-500">
                  Create new account
                </span>
              </div>
            </div>
            <EcommerceSignupForm />
          </div>
          <div className="hidden lg:flex relative z-50 bottom-[-25%] right-[-15%]">
            <DidYouKnow />
          </div>
        </div>
      </div>
    </>
  );
}
