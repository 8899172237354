export enum ExportFormatEnum {
  XLSX,
  CSV,
}

export const ExportFormatToLabelMap = Object.freeze({
  [ExportFormatEnum.XLSX]: 'Microsoft Excel',
  [ExportFormatEnum.CSV]: 'CSV',
});

export enum ExportStatusEnum {
  ACTIVE,
  INACTIVE,
}

export const ExportStatusEnumToLabelMap = Object.freeze({
  [ExportStatusEnum.ACTIVE]: 'Active',
  [ExportStatusEnum.INACTIVE]: 'Inactive',
});

export const ExportStatusEnumToStringMap = Object.freeze({
  [ExportStatusEnum.ACTIVE]: 'ACTIVE',
  [ExportStatusEnum.INACTIVE]: 'INACTIVE',
});
