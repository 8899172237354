import { CustomBreadcrumb } from 'app/components/Navigation/BreadCrumbs';
import { AdminUpdateCategoryForm } from 'app/pages/Admin/Category/Update/UpdateCategoryPage/components/AdminUpdateCategoryForm';
import { PAGE_TITLES_ADMIN_PORTAL } from 'app/util/constants/pageTitles/adminPortal';
import { normaliseVariantId, VariantIds } from 'app/VariantContext';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

export function AdminCategoryUpdatePage() {
  const params: { variantId: VariantIds; categoryId: string } = useParams();
  return (
    <>
      <Helmet>
        <title>{PAGE_TITLES_ADMIN_PORTAL.categoryManagement.updatePage}</title>
        <meta
          name="description"
          content={PAGE_TITLES_ADMIN_PORTAL.categoryManagement.updatePage}
        />
      </Helmet>
      <CustomBreadcrumb
        data={[
          {
            label: `Variant ${normaliseVariantId(params.variantId)}`,
            link: '/admin',
          },
          {
            label: 'Category Management',
            link: `/admin/categories/${params.variantId}`,
          },
          {
            label: 'Edit Category',
            link: `/admin/categories/${params.variantId}/update/${params.categoryId}`,
            isCurrentPage: true,
          },
        ]}
      />
      <AdminUpdateCategoryForm></AdminUpdateCategoryForm>
    </>
  );
}
