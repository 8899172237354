import { ICartItem } from 'app/models/CartItem';
import { atomFamily } from 'recoil';

const localStorageEffect =
  key =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue, _, isReset) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };

export const cartDefaultKey = 'anon';

export const cartState = atomFamily<ICartItem[], string>({
  key: 'cartState',
  default: [],
  effects: userId => [localStorageEffect(`cart-${userId}`)],
});
