import Cookies from 'js-cookie';
import axiosInstance from 'utils/axios';

// to get variant
export const LOCAL_STORAGE_AB_KEY = 'PUSHTELL-AB Test';
// to get uuid v4
export const LOCAL_STORAGE_UUID_KEY = 'UUID-AB Test';
// to get sesion id
export const COOKIES_SESSION_ID = 'sessionID';
// to get user id
export const COOKIES_USER_ID = 'uuid';

// endpoints
const AB_BASE_ENDPOINT = `/abtesting`;
const SESSION_BASE_ENDPOINT = `/session`;

// initially to get the session ID
export const getSessionId = async () => {
  const variantType = localStorage.getItem('PUSHTELL-AB Test');
  const uuidExists = Cookies.get(COOKIES_USER_ID);
  await axiosInstance
    .post(
      SESSION_BASE_ENDPOINT,
      uuidExists
        ? {
            session_variant: variantType?.substring(variantType.length - 1),
            userId: Cookies.get(COOKIES_USER_ID),
          }
        : {
            session_variant: variantType?.substring(variantType.length - 1),
          },
    )
    .then(res => {
      const data = res.data.data;
      Cookies.set(COOKIES_SESSION_ID, data.sessionId);
      Cookies.set(COOKIES_USER_ID, data.userId);
      return data.sessionId;
    })
    .catch(err => {
      console.error(err);
    });
};

interface IDataPacket {
  id: number;
  count: number;
  clicks: number;
}

// sends heartbeat
export const sendDataPacket = async (clicks: number) => {
  let sessionId = Cookies.get(COOKIES_SESSION_ID)!;
  let dataPacket: IDataPacket = {
    id: parseInt(sessionId),
    count: 1,
    clicks: clicks,
  };
  await axiosInstance
    .put(SESSION_BASE_ENDPOINT + '/updateHeartbeatsClicks', dataPacket)
    .catch(err => {
      console.error(err);
    });
};

// for session when user adds to cart
export const sendSessionForCartAddition = async () => {
  await axiosInstance
    .put(SESSION_BASE_ENDPOINT + '/onAddToCart', {
      id: parseInt(Cookies.get(COOKIES_SESSION_ID)!),
    })
    .catch(err => {
      console.error(err);
    });
};

// for session when user completes a donation
export const sendSessionForCompletedDonation = async (email: string) => {
  await axiosInstance
    .put(SESSION_BASE_ENDPOINT + '/onDonation', {
      id: parseInt(Cookies.get(COOKIES_SESSION_ID)!),
      email: email,
    })
    .catch(err => {
      console.error(err);
    });
};

// excel table stuff
export const receiveDonationOrdersData = async (
  startDate: Date,
  endDate: Date,
) => {
  const res = await axiosInstance.post(
    AB_BASE_ENDPOINT + '/allDonationOrders',
    {
      startDate: startDate,
      endDate: endDate,
    },
  );
  return res.data;
};

export const receiveOrderItemsData = async (startDate: Date, endDate: Date) => {
  const res = await axiosInstance.post(AB_BASE_ENDPOINT + '/ordersByProducts', {
    startDate: startDate,
    endDate: endDate,
  });
  return res.data;
};

export const receiveDonorListData = async () => {
  const res = await axiosInstance.get(AB_BASE_ENDPOINT + '/donorList');
  return res.data;
};

export const receiveHeartbeatData = async (startDate: Date, endDate: Date) => {
  const res = await axiosInstance.post(AB_BASE_ENDPOINT + '/sessionData', {
    startDate: startDate,
    endDate: endDate,
  });
  return res.data;
};

export const receiveCalculatedData = async (startDate: Date, endDate: Date) => {
  const res = await axiosInstance.post(AB_BASE_ENDPOINT + '/statistics', {
    startDate: startDate,
    endDate: endDate,
  });
  return res.data;
};

export const receiveFullRecordData = async (startDate: Date, endDate: Date) => {
  const res = await axiosInstance.post(AB_BASE_ENDPOINT + '/fullRecord', {
    startDate: startDate,
    endDate: endDate,
  });
  return res.data;
};
