import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { updateProduct } from 'app/api/ProductService';
import { CustomLink } from 'app/components/CustomLink';
import { ConfirmationModalComponent } from 'app/components/Modals/ConfirmationModal/Loadable';
import { Product } from 'app/models/Product';
import { PAGE_URL_ADMIN_PORTAL } from 'app/util/constants/pageUrls/adminPortal';
import { useVariantValue } from 'app/VariantContext';
import React, { useEffect, useState } from 'react';

export const AdminItemTableComponent: React.FC<{
  onActionClicked?: Function;
  onSelectedItemsChange: Function;
  onSortChange?: Function;
  initialSelectedItemIds: Array<number>;
  items: Array<Product>;
  showActions: boolean;
}> = ({
  onActionClicked,
  onSortChange,
  initialSelectedItemIds,
  onSelectedItemsChange,
  items,
  showActions,
}) => {
  // Variant Id
  let variantId = useVariantValue();

  // item
  const [selectedItemIds, setSelectedItemIds] = useState<number[]>(
    initialSelectedItemIds,
  );
  const [sort, setSort] = useState<
    | {
        field: 'quantity' | 'price' | 'rank';
        direction: 'asc' | 'desc';
      }
    | undefined
  >(); // sort by [quantity, price, rank]

  // meta
  const [actionOnItemId, setActionOnItemId] = useState<number>(); // focus of action
  const [areItemsSelected, setAreItemsSelected] = useState<boolean>(false); // if all items are selected

  //modals
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isDeactivateModalOpen, setIsDeactivateModalOpen] =
    useState<boolean>(false);
  const [isActivateModalOpen, setIsActivateModalOpen] =
    useState<boolean>(false);
  const cancelButtonRef = React.useRef(null);

  // Handlers

  // remove the item from selected items or add it if it doesn't currently exist
  const itemClickedHandler = id => {
    // check if id exists, if it exists, remove it
    const _existingIdIndex = selectedItemIds.indexOf(id);

    let _newSelectedItemIds: number[] = [];
    if (_existingIdIndex !== -1) {
      // remove id
      _newSelectedItemIds = selectedItemIds.filter(val => val !== id);
    } else {
      _newSelectedItemIds = [...selectedItemIds, id];
    }
    setSelectedItemIds(_newSelectedItemIds);
  };

  // selects or deselects all items in the page
  const selectAllHandler = () => {
    const _currentPageItemIDs = items.map(item => item.id);
    let _newSelectedItemIds: Array<number>;

    // remove all the items from the page if there are items selected
    // check if the current page items are inside the selected item ids
    // if it is, remove them
    // if it is not, add them

    if (selectedItemIds.some(id => _currentPageItemIDs.includes(id))) {
      // filter out the ids
      _newSelectedItemIds = selectedItemIds.filter(
        id => !_currentPageItemIDs.includes(id),
      );
    } else {
      _newSelectedItemIds = [...selectedItemIds, ..._currentPageItemIDs];
    }

    setSelectedItemIds(_newSelectedItemIds);
  };

  // Open the confirmation modal based on the action
  const actionHandler = (
    id: number,
    action: 'ACTIVE' | 'INACTIVE' | 'DELETED',
  ) => {
    setActionOnItemId(id); // set the item id of what we are doing an action on

    switch (action) {
      case 'ACTIVE':
        setIsActivateModalOpen(true);
        break;
      case 'INACTIVE':
        setIsDeactivateModalOpen(true);
        break;
      case 'DELETED':
        setIsDeleteModalOpen(true);
        break;
    }
  };

  const sortHandler = (newSort: string) => {
    const field = newSort as 'quantity' | 'price' | 'rank';
    let direction: 'asc' | 'desc' =
      sort?.field === newSort
        ? sort.direction === 'asc'
          ? 'desc'
          : 'asc'
        : 'asc';

    setSort({ field, direction });
  };

  // Update the status based on the action
  const updateStatus = async (action: 'ACTIVE' | 'INACTIVE' | 'DELETED') => {
    let _updateStatusData = { status: '' };

    switch (action) {
      case 'ACTIVE':
        _updateStatusData.status = 'ACTIVE';
        break;
      case 'INACTIVE':
        _updateStatusData.status = 'INACTIVE';
        break;
      case 'DELETED':
        _updateStatusData.status = 'DELETED';
        break;
    }

    // set every modal to false
    setIsActivateModalOpen(false);
    setIsDeactivateModalOpen(false);
    setIsDeleteModalOpen(false);

    await updateProduct(actionOnItemId!, _updateStatusData);

    onActionClicked && onActionClicked();

    setSelectedItemIds(selectedItemIds.filter(_id => _id !== actionOnItemId));
  };

  // Helper functions

  // Check whether all items in the page are selected or not
  const checkAllItemsSelected = () => {
    let areSelected: boolean = true;

    if (!selectedItemIds.length) {
      areSelected = false;
      setAreItemsSelected(areSelected);
      return;
    }
    for (const _pageId of items.map(item => item.id)) {
      if (!selectedItemIds.includes(_pageId)) {
        areSelected = false;
        break;
      }
    }
    // return areSelected;
    setAreItemsSelected(areSelected);
  };

  const checkItemSelected = (id: number) => {
    return selectedItemIds.includes(id);
  };

  // refresh selected
  useEffect(() => {
    setSelectedItemIds(initialSelectedItemIds);
  }, [initialSelectedItemIds]);

  useEffect(() => {
    onSelectedItemsChange(selectedItemIds);
    checkAllItemsSelected();
  }, [selectedItemIds]);

  useEffect(() => {
    if (!items?.length) return;
    checkAllItemsSelected();
  }, [items]);

  useEffect(() => {
    if (!onSortChange || !sort) return;
    onSortChange(sort);
  }, [sort]);

  // Table Row
  const TableRowComponent: React.FC<{
    isSelected: boolean;
    item: Product;
    onItemClicked: Function;
    onActionClicked?: (
      id: number,
      action: 'ACTIVE' | 'INACTIVE' | 'DELETED',
    ) => void;
  }> = ({ item, onActionClicked, isSelected }) => {
    return (
      <>
        <tr
          onClick={() => itemClickedHandler(item.id)}
          className={'border-b border-slate ' + (isSelected && '!bg-slate-100')}
        >
          <td className="pl-2 py-6">
            <input
              type="checkbox"
              checked={!!isSelected}
              onChange={() => {}}
            ></input>
          </td>
          <td>{item.category?.nature === 'FOOD' ? 'Food' : 'Non Food'}</td>
          <td>
            {item.category?.nature === 'FOOD' ? 'F' : 'N'}-
            {item.category?.categoryCode}-{item.id}
          </td>
          <td>{item.category?.name}</td>
          <td>{item.name}</td>
          <td>{item.skuCode}</td>
          <td>{item.price}</td>
          <td>{item.targetQuantity}</td>
          <td>{item.rank}</td>
          {showActions && onActionClicked && (
            <td className="h-full item-center">
              <div className="flex flex-row gap-2">
                <span className="cursor-pointer text-primary">
                  <CustomLink
                    to={PAGE_URL_ADMIN_PORTAL.productManagement.updatePage(
                      variantId,
                      item.id,
                    )}
                    className="text-primary font-normal"
                  >
                    Edit
                  </CustomLink>
                </span>
                {item.status === 'ACTIVE' ? (
                  <span
                    className="cursor-pointer text-primary"
                    onClick={() => onActionClicked(item.id, 'INACTIVE')}
                  >
                    Deactivate
                  </span>
                ) : (
                  <span
                    className="cursor-pointer text-primary"
                    onClick={() => onActionClicked(item.id, 'ACTIVE')}
                  >
                    Activate
                  </span>
                )}

                <span
                  className="cursor-pointer text-primary"
                  onClick={() => onActionClicked(item.id, 'DELETED')}
                >
                  {' '}
                  Delete
                </span>
              </div>
            </td>
          )}
        </tr>
      </>
    );
  };

  return (
    <>
      <table className="w-full text-sm">
        <tr className="bg-dark-blue text-white text-left border-0">
          <th className="pl-2 py-6">
            <input
              type="checkbox"
              onClick={selectAllHandler}
              checked={!!areItemsSelected}
              onChange={() => {}}
            ></input>
          </th>
          <th className="font-normal">Product Type</th>
          <th className="font-normal">Catalog Code</th>
          <th className="font-normal">Category</th>
          <th className="font-normal w-2/12">Product Name</th>
          <th className="font-normal w-1/12">Warehouse SKU Code</th>
          <TableHeader
            value="price"
            title="Set Price"
            onClick={sortHandler}
            sort={sort?.field === 'price' ? sort.direction : undefined}
            className="w-1/12 cursor-pointer"
          ></TableHeader>
          <TableHeader
            value="quantity"
            title="Target Quantity"
            onClick={sortHandler}
            sort={sort?.field === 'quantity' ? sort.direction : undefined}
            className="w-1/8 cursor-pointer"
          ></TableHeader>
          <TableHeader
            value="rank"
            title="Rank"
            onClick={sortHandler}
            sort={sort?.field === 'rank' ? sort.direction : undefined}
            className="cursor-pointer"
          ></TableHeader>
          {showActions && onActionClicked && (
            <th className="font-normal"> Actions</th>
          )}
        </tr>
        {items &&
          items.map(item => (
            <TableRowComponent
              // selectedItemIds={selectedItemIds}
              isSelected={checkItemSelected(item.id)}
              item={item}
              onItemClicked={itemClickedHandler}
              onActionClicked={actionHandler}
            ></TableRowComponent>
          ))}
      </table>
      <ConfirmationModalComponent
        showState={isDeleteModalOpen}
        showStateController={setIsDeleteModalOpen}
        cancelButtonRef={cancelButtonRef}
        header={'Are you sure you want to delete this product?'}
        description={
          "This is irreversible - you would not be able to access this product's information permanently"
        }
        iconStyles={'w-[22px] h-[22px] text-pink my-1 mr-3'}
        primaryButtonText={'Delete'}
        primaryButtonStyles={
          'items-end bg-pink w-[72px] h-[32px] rounded-[20px] text-[12px] whitespace-nowrap'
        }
        buttonFunction={() => updateStatus('DELETED')}
        secondaryButtonStyles={
          'items-end bg-neutral-500 w-[72px] h-[32px] rounded-[20px] text-[12px] whitespace-nowrap mr-2'
        }
      />
      <ConfirmationModalComponent
        showState={isDeactivateModalOpen}
        showStateController={setIsDeactivateModalOpen}
        cancelButtonRef={cancelButtonRef}
        header={'Are you sure you want to set this product to inactive?'}
        iconStyles={'w-[22px] h-[22px] text-orange my-1 mr-3'}
        primaryButtonText={'Deactivate'}
        primaryButtonStyles={
          'items-end bg-orange w-[72px] h-[32px] rounded-[20px] text-[12px] whitespace-nowrap'
        }
        buttonFunction={() => updateStatus('INACTIVE')}
        secondaryButtonStyles={
          'items-end bg-neutral-500 w-[72px] h-[32px] rounded-[20px] text-[12px] whitespace-nowrap mr-2'
        }
      />
      <ConfirmationModalComponent
        showState={isActivateModalOpen}
        showStateController={setIsActivateModalOpen}
        cancelButtonRef={cancelButtonRef}
        header={'Are you sure you want to set the product to active?'}
        iconStyles={'w-[22px] h-[22px] text-light-green my-1 mr-3'}
        primaryButtonText={'Activate'}
        primaryButtonStyles={
          'items-end bg-light-green w-[72px] h-[32px] rounded-[20px] text-[12px] whitespace-nowrap'
        }
        buttonFunction={() => updateStatus('ACTIVE')}
        secondaryButtonStyles={
          'items-end bg-neutral-500 w-[72px] h-[32px] rounded-[20px] text-[12px] whitespace-nowrap mr-2'
        }
      />
    </>
  );
};
interface ITableHeader {
  onClick: (value: string) => void;
  value: string;
  title: string;
  className?: string;
  sort?: 'asc' | 'desc';
}
const TableHeader: React.FC<ITableHeader> = ({
  onClick,
  value,
  title,
  className,
  sort,
}) => {
  return (
    <th className={'font-normal ' + className} onClick={() => onClick(value)}>
      {title}
      {sort &&
        (sort === 'asc' ? (
          <CaretDownOutlined></CaretDownOutlined>
        ) : (
          <CaretUpOutlined></CaretUpOutlined>
        ))}
    </th>
  );
};
