import { LoginForm } from 'app/pages/Admin/User/Login/LoginUserPage/components/LoginForm';
import { PAGE_TITLES_ADMIN_PORTAL } from 'app/util/constants/pageTitles/adminPortal';
import {
  createUserWithEmailAndPassword,
  getAuth,
  reauthenticateWithCredential,
  signInWithEmailAndPassword,
  updatePassword,
} from 'firebase/auth';
import React from 'react';
import { Helmet } from 'react-helmet-async';

export function LoginUserPage() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const changePassword = (currentPassword, newPassword) => {
    const authentication = getAuth();
    const user = authentication.currentUser;
    if (user !== null) {
      reauthenticateWithCredential(user, currentPassword)
        .then(() => {
          updatePassword(user, newPassword).catch(error => {
            console.error(error);
          });
        })
        .catch(error => {
          console.error(error);
        });
    }
  };
  const login = id => {
    const authentication = getAuth();
    const email = 'javin.chuaa@gmail.com';
    const password = 'testing12';
    if (id === 1) {
      // create new user
      createUserWithEmailAndPassword(authentication, email, password);
    } else {
      signInWithEmailAndPassword(authentication, email, password);
    }
  };
  return (
    <>
      <Helmet>
        <title>{PAGE_TITLES_ADMIN_PORTAL.accountManagement.loginPage}</title>
        <meta
          name="description"
          content={PAGE_TITLES_ADMIN_PORTAL.accountManagement.loginPage}
        />
      </Helmet>
      <LoginForm />
    </>
  );
}
