import { getAdminUserDetails } from 'app/api/userService/userapi';
import { LoadingComponent } from 'app/components/LoadingComponent/loadable';
import { LoadingState } from 'app/types/enums/loadingState';
import { PAGE_URL_ADMIN_PORTAL } from 'app/util/constants/pageUrls/adminPortal';
import { PAGE_URL_ECommerce } from 'app/util/constants/pageUrls/ecommerce';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

export enum userTypes {
  FOODBANK = 'FOODBANK',
  RESEARCH = 'RESEARCH',
  NORMAL = 'NORMAL',
}

interface IAuthGuard extends RouteProps {
  Component?: any;
  userTypesRequired: Array<userTypes>;
  children: React.ReactNode;
}

export const AuthGuard: React.FC<IAuthGuard> = ({
  Component,
  userTypesRequired,
  children,
  ...rest
}) => {
  const [loadingState, setLoadingState] = useState<LoadingState>(
    LoadingState.LOADING,
  );

  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  };

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);

  useEffect(() => {
    // get user from local storage
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        //@ts-ignore
        user
          .getIdToken()
          .then(userIdToken => {
            getAdminUserDetails(user.uid, userIdToken).then(res => {
              //   res = { type: 'RESEARCH' }; // temporary test
              if (!res || !res.type) {
                // user was not created in the database
                setLoadingState(LoadingState.ERROR);

                return;
              }

              setLoadingState(
                (userTypesRequired as Array<string>).includes(res.type)
                  ? LoadingState.SUCCESS
                  : LoadingState.REDIRECT,
              );
            });
          })
          .catch(err => {
            setLoadingState(LoadingState.ERROR);
          });
        // The API call fails and we want to re-direct the user out
        // else the loading state will be in an infinite loop
        if (loadingState !== LoadingState.SUCCESS) {
          setLoadingState(LoadingState.ERROR);
        }
      } else {
        setLoadingState(LoadingState.REDIRECT);
      }
    });

    return () => unsubscribe();
  }, []);

  const renderComponent = (loadState: LoadingState) => {
    switch (loadState) {
      case LoadingState.LOADING:
        return <LoadingComponent />;
      case LoadingState.SUCCESS:
        return children;
      case LoadingState.REDIRECT:
        return userTypesRequired.includes(userTypes.FOODBANK) ||
          userTypesRequired.includes(userTypes.RESEARCH) ? (
          <Redirect to={PAGE_URL_ADMIN_PORTAL.accountManagement.loginPage} />
        ) : (
          <Redirect to={PAGE_URL_ECommerce.loginPage} />
        );
      case LoadingState.ERROR:
        return <div> Error Occurred: User In Firebase but not in Postgres</div>;
    }
  };

  return (
    <Route {...rest}>{renderComponent(loadingState)}</Route>
    // <Route
    //   {...rest}
    //   render={props => {
    //     // return <Component {...props} />;
    //     switch (loadingState) {
    //       case LoadingState.LOADING:
    //         return <LoadingComponent />;
    //       case LoadingState.SUCCESS:
    //         return <Component {...props} />;
    //       case LoadingState.REDIRECT:
    //         return userTypesRequired.includes(userTypes.FOODBANK) ||
    //           userTypesRequired.includes(userTypes.RESEARCH) ? (
    //           <Redirect
    //             to={PAGE_URL_ADMIN_PORTAL.accountManagement.loginPage}
    //           />
    //         ) : (
    //           <Redirect to={PAGE_URL_ECommerce.loginPage} />
    //         );
    //       case LoadingState.ERROR:
    //         return (
    //           <div> Error Occurred: User In Firebase but not in Postgres</div>
    //         );
    //     }
    //   }}
    // />
  );
};
