import { Auth, User } from 'firebase/auth';
import React, { useContext } from 'react';

interface IAuthContext {
  currentUser: User | null;
  currentUserId?: string;
  auth: Auth;
}

const AuthContext = React.createContext<IAuthContext | null>(null);

export function AuthProvider({ children, value }) {
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuthValue() {
  return useContext(AuthContext);
}
