import { uploadObject } from 'app/api/AWSService';
import { createCategory } from 'app/api/CategoryService';
import {
  Button,
  CANCEL_BUTTON_CLASSES,
  CONFIRM_BUTTON_CLASSES,
} from 'app/components/Buttons/Button';
import { Input } from 'app/components/Forms/Input';
import { InputSelectComponent } from 'app/components/Forms/InputSelect/Loadable';
import { ConfirmationModalComponent } from 'app/components/Modals/ConfirmationModal/Loadable';
import { ImageUploadComponent } from 'app/pages/Admin/Category/Update/UpdateCategoryPage/components/ImageUpload/imageUpload';
import { PAGE_URL_ADMIN_PORTAL } from 'app/util/constants/pageUrls/adminPortal';
import { VariantIds } from 'app/VariantContext';
import React, { useRef, useState } from 'react';

interface IAddCategoryFormProps {
  className?: string;
  variant: VariantIds;
}

type Category = {
  name: string;
  image: string;
  nature: 'FOOD' | 'NONFOOD';
  variantId: string;
};

export const AddCategoryForm: React.FC<IAddCategoryFormProps> = ({
  className,
  variant,
}) => {
  const cancelButtonRef = useRef(null);
  const [isButtonDisabled, setIsButtonDisabled] =
    React.useState<boolean>(false);
  const [category, setCategory] = useState<Category>({
    name: '',
    nature: 'FOOD',
    image: '',
    variantId: variant,
  });
  const [isCreateModalOpen, setIsCreateModalOpen] =
    React.useState<boolean>(false);
  const [isCancelModalOpen, setIsCancelModalOpen] =
    React.useState<boolean>(false);
  const [imageFile, setImageFile] = useState<any>(undefined);
  const [imageUrl, setImageUrl] = useState('');
  const [imgErrMsg, setImgErrMsg] = useState('');
  const [nameErrMsg, setNameErrMsg] = useState('');

  const clearErrorMsg = () => {
    setNameErrMsg('');
    setImgErrMsg('');
  };

  const validateInput = () => {
    clearErrorMsg();
    let hasNoError = true;
    if (!category.name) {
      setNameErrMsg('Required');
      hasNoError = false;
    }
    if (!imageFile) {
      setImgErrMsg('Required');
      hasNoError = false;
    }
    return hasNoError;
  };

  const handleClickCreate = () => {
    if (!validateInput()) return;
    setIsCreateModalOpen(true);
  };

  const handleSubmit = () => {
    setIsCreateModalOpen(false);
    const data = category;
    if (imageFile) {
      data['image'] = imageFile.name;
    }
    setIsButtonDisabled(true);
    // Call api
    createCategory(data)
      .then(response => {
        const categoryId = response.data.categoryCode;
        if (imageFile) {
          uploadObject(`uploads/category/${categoryId}/`, imageFile).catch(e =>
            console.error('An error occurred uploading file', e),
          );
        }
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        // navigate back to category management page
        window.location.replace(
          PAGE_URL_ADMIN_PORTAL.categoryManagement.viewPage(variant),
        );
        setIsButtonDisabled(false);
      });
  };

  const handleChange = e => {
    const { value, name } = e.target;
    setCategory(prev => ({ ...prev, [name]: value }));
  };

  return (
    <div className="flex flex-col gap-4 items-center justify-center p-12 mx-auto w-full max-w-[550px]">
      <form
        className="font-medium tracking-wide text-black black flex flex-col gap-4"
        onSubmit={handleSubmit}
      >
        <Input
          title="Category Name"
          type="text"
          name="name"
          id="name"
          value={category?.name}
          onChange={handleChange}
          placeholder="Name"
          isLabelHorizontal
          required
          showError
          error={nameErrMsg}
        />
        <InputSelectComponent
          id="nature"
          label="Type:"
          name="nature"
          value={category?.nature}
          options={[
            { label: 'Food', value: 'FOOD' },
            { label: 'Non-Food', value: 'NONFOOD' },
          ]}
          onChange={handleChange}
          isLabelHorizontal
          required
        />
        <ImageUploadComponent
          imageFile={imageFile}
          setImageFile={setImageFile}
          imageUrl={imageUrl}
          setImageUrl={setImageUrl}
          errMessage={imgErrMsg}
        />
        <ConfirmationModalComponent
          showState={isCreateModalOpen}
          showStateController={setIsCreateModalOpen}
          cancelButtonRef={cancelButtonRef}
          header={'Would you like to add this category?'}
          iconStyles={'w-[22px] h-[22px] text-primary my-1 mr-3'}
          primaryButtonText={'Confirm'}
          primaryButtonStyles={CONFIRM_BUTTON_CLASSES}
          buttonFunction={handleSubmit}
          secondaryButtonStyles={CANCEL_BUTTON_CLASSES}
        />
        <ConfirmationModalComponent
          showState={isCancelModalOpen}
          showStateController={setIsCancelModalOpen}
          cancelButtonRef={cancelButtonRef}
          header={'You are canceling your current edits.'}
          iconStyles={'w-[22px] h-[22px] text-primary my-1 mr-3'}
          primaryButtonText={'Confirm'}
          primaryButtonStyles={CONFIRM_BUTTON_CLASSES}
          buttonFunction={() => {
            setIsCancelModalOpen(false);
            // navigate back to category management page
            window.location.replace(
              PAGE_URL_ADMIN_PORTAL.categoryManagement.viewPage(variant),
            );
          }}
          secondaryButtonStyles={CANCEL_BUTTON_CLASSES}
        />

        <div className="w-5/6 sm:w-3/6 grid grid-cols-4 gap-4 py-2">
          <span className="col-span-1"></span>
          <div className="col-span-3">
            <div className="flex w-full gap-6">
              <Button
                children={<>Create</>}
                type={'button'}
                className={CONFIRM_BUTTON_CLASSES}
                disabled={isButtonDisabled}
                onClick={() => handleClickCreate()}
              />
              <Button
                children={<>Cancel</>}
                type={'button'}
                className={CANCEL_BUTTON_CLASSES}
                onClick={() => setIsCancelModalOpen(true)}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
