import { PaymentForm } from 'app/pages/ECommerce/Payment/PaymentPage/components/PaymentForm';
import { PAGE_TITLES_ECommerce } from 'app/util/constants/pageTitles/ecommerce';
import React from 'react';
import { Helmet } from 'react-helmet-async';

export function PaymentPage() {
  return (
    <>
      <Helmet>
        <title>{PAGE_TITLES_ECommerce.paymentPage}</title>
        <meta name="description" content={PAGE_TITLES_ECommerce.paymentPage} />
      </Helmet>
      <h1> payment</h1>
      <PaymentForm />
    </>
  );
}
