import React, { TextareaHTMLAttributes } from 'react';

interface ITextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  error?: string;
  isLabelHorizontal?: boolean;
  placeholder?: string;
  maxLength?: number;
}
export const InputTextAreaComponent: React.FC<ITextAreaProps> = ({
  value,
  onChange,
  label,
  required,
  error,
  isLabelHorizontal,
  placeholder,
  maxLength,
}) => {
  return (
    <>
      <div
        className={
          isLabelHorizontal
            ? 'grid grid-cols-[1fr_2fr] gap-x-4 items-start'
            : 'flex flex-col mb-1'
        }
      >
        {label && (
          <div className="justify-self-end mt-1">
            {label}
            {required && <span className="text-error">*</span>}
            {':'}
          </div>
        )}
        <textarea
          maxLength={maxLength}
          className="py-2.5 px-3 border-[#e0e0e0] w-full rounded-md border outline-none bg-neutral-200 text-neutral-600 focus:border-[#6A64F1] focus:shadow-md"
          value={value}
          name="description"
          onChange={onChange}
          placeholder={placeholder ?? 'Description'}
        ></textarea>
        {error && (
          <>
            <div
              className={
                'text-error mt-1 ' + (isLabelHorizontal ? 'col-start-2' : '')
              }
            >
              {error}
            </div>
          </>
        )}
      </div>
    </>
  );
};
