import {
  CREATE_FEEDBACK,
  LIST_FEEDBACK,
} from 'app/util/constants/apiEndpoints/FeedbackEndpoints';
import axiosInstance from 'utils/axios';

export const createFeedback = async (data: any): Promise<any> => {
  let result;
  try {
    result = await axiosInstance.post(CREATE_FEEDBACK(), data);
    console.log(result);
  } catch (e) {
    console.error('Error Creating Feedback ' + e);
  }
  return;
};

export const listFeedback = async (startDate: Date, endDate: Date) => {
  let result;
  try {
    result = await axiosInstance.post(LIST_FEEDBACK(), {
      startDate: startDate,
      endDate: endDate,
    });
  } catch (e) {
    console.error('Error Getting Feedback ' + e);
  }
  return result.data;
};
