import { ButtonComponent } from 'app/components/Buttons/Button/Loadable';
import { Input } from 'app/components/Forms/Input';
import { USER_DELETE_DELETE_USER_DETAILS_ENDPOINT } from 'app/util/constants/apiEndpoints/UserEndpoints';
import React from 'react';
import axiosInstance from 'utils/axios';

type DeleteFormProps = {
  className?: string;
};

export const DeleteForm: React.FC<DeleteFormProps> = ({ className }) => {
  const [userUid, setUserUid] = React.useState<string>('');
  const handleUserDelete = async () => {
    let reqBody = {
      uid: userUid,
    };
    return await axiosInstance.post(
      USER_DELETE_DELETE_USER_DETAILS_ENDPOINT(userUid),
      reqBody,
    );
  };

  return (
    <div className="flex items-center justify-center p-12 mx-auto w-full max-w-[550px]">
      <form onSubmit={handleUserDelete}>
        <Input
          title="Delete User"
          type="password"
          name="oldpassword"
          id="oldpassword"
          value={userUid}
          onChange={e => {
            setUserUid(e.target.value);
          }}
          placeholder="User Uid"
        />
        <div>
          <ButtonComponent type="submit">Submit</ButtonComponent>
        </div>
      </form>
    </div>
  );
};
