interface ecommercePageTitleStrings {
  landingPage: string;
  shoppingCartPage: string;
  itemPage: string;
  checkoutPage: string;
  loginPage: string;
  logoutPage: string;
  paymentPage: string;
  paymentSuccessPage: string;
  paymentFailurePage: string;
  signupPage: string;
}

export const PAGE_TITLES_ECommerce: ecommercePageTitleStrings = {
  landingPage: 'Mart for Good',
  shoppingCartPage: 'Mart for Good',
  itemPage: 'Mart for Good',
  checkoutPage: 'Mart for Good',
  loginPage: 'Mart for Good',
  logoutPage: 'Mart for Good',
  paymentPage: 'Mart for Good',
  paymentSuccessPage: 'Mart for Good',
  paymentFailurePage: 'Mart for Good',
  signupPage: 'Mart for Good',
};
