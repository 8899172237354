import { Dialog, Transition } from '@headlessui/react';
import { getProducts } from 'app/api/ProductService';
import { Button } from 'app/components/Buttons/Button';
import { Product } from 'app/models/Product';
import React, { Fragment, useEffect, useState } from 'react';

import { AdminItemTableComponent } from '../AdminItemTable';

export const AdminItemModalComponent: React.FC<{
  showModal: boolean;
  setShowModal: (boolean) => void;
  selectedItemIds: number[];

  onSelectedItemIdsChange: (ids: number[]) => void;
}> = ({
  setShowModal,
  showModal,
  selectedItemIds,
  onSelectedItemIdsChange,
}) => {
  const [initialSelectedItems, setInitialSelectedItems] = useState<Product[]>(
    [],
  );
  const [newSelectedItemIds, setNewSelectedItemIds] = useState<number[]>([]);
  const selectedItemsChangeHandler = ids => {
    setNewSelectedItemIds(ids);
  };

  const doneHandler = () => {
    onSelectedItemIdsChange(newSelectedItemIds);
    setShowModal(false);
  };

  useEffect(() => {
    if (!selectedItemIds.length) return;
    getProducts({ ids: selectedItemIds }).then(res => {
      setInitialSelectedItems(res.data);
    });
  }, [selectedItemIds]);

  useEffect(() => {}, [newSelectedItemIds]);

  return (
    <>
      <Transition.Root show={showModal} as={Fragment}>
        <Dialog onClose={setShowModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            {/* Background */}
            <div className="fixed inset-0 bg-neutral-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Dialog.Panel className="w-3/4 h-3/4 relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8">
                <div className="bg-white w-full p-6">
                  <div className="flex flex-row pb-2 w-full justify-between items-center">
                    <div>
                      <h2 className="text-xl font-bold">
                        {newSelectedItemIds.length} Items Selected
                      </h2>
                      <div className="text-sm">
                        {newSelectedItemIds.length < initialSelectedItems.length
                          ? `${
                              initialSelectedItems.length -
                              newSelectedItemIds.length
                            } Unselected`
                          : 'Uncheck to deselect'}
                      </div>
                    </div>

                    <Button
                      className={
                        'inline-block items-end w-[72px] h-[32px] rounded-[20px] text-[12px] whitespace-nowrap ' +
                        (newSelectedItemIds.length < initialSelectedItems.length
                          ? 'bg-dark-blue'
                          : 'bg-neutral-500 cursor-not-allowed hover:!bg-neutral-500')
                      }
                      onClick={
                        newSelectedItemIds.length < initialSelectedItems.length
                          ? doneHandler
                          : () => null
                      }
                    >
                      Done
                    </Button>
                  </div>
                  <div className="w-full">
                    <AdminItemTableComponent
                      showActions={false}
                      items={initialSelectedItems}
                      onSelectedItemsChange={selectedItemsChangeHandler}
                      initialSelectedItemIds={selectedItemIds}
                    ></AdminItemTableComponent>
                  </div>
                </div>
              </Dialog.Panel>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
