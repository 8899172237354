import 'react-datepicker/dist/react-datepicker.css';

import {
  receiveDonationOrdersData,
  receiveDonorListData,
  receiveOrderItemsData,
  receiveHeartbeatData,
  receiveCalculatedData,
  receiveFullRecordData,
} from 'app/api/abService/abapi';
import { ButtonComponent } from 'app/components/Buttons/Button/Loadable';
import { PAGE_TITLES_ADMIN_PORTAL } from 'app/util/constants/pageTitles/adminPortal';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { Helmet } from 'react-helmet-async';
import * as XLSX from 'xlsx';
import { listFeedback } from 'app/api/FeedbackService';

// TODO: abstract this as its own component
// TODO: make excel sheet look nicer
export function AdminPage() {
  // dates for querying data in backend
  const [startDate, setStartDate] = useState(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - 7,
    ),
  );
  const [endDate, setEndDate] = useState(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
    ),
  );
  const [invalidDate, setInvalidDate] = useState(false);

  const validateDates = () => {
    // if start date over end date
    if (startDate > endDate) {
      setInvalidDate(true);
      return false;
    }
    // if end date over today
    if (endDate > new Date()) {
      setInvalidDate(true);
      return false;
    }
    // if same day
    if (startDate.getDate() === endDate.getDate()) {
      setInvalidDate(true);
      return false;
    }
    setInvalidDate(false);
    return true;
  };

  const downloadOrderData = async () => {
    if (!validateDates()) {
      return;
    }
    // helper function
    const wrangleOrdersData = (arr: any[]) => {
      let cleanedOrders: any[] = [];
      arr.forEach(order => {
        // wrangle out products
        const productsArray = order['productNameComposition'];
        let stringOfProducts = '';
        productsArray.forEach(product => {
          stringOfProducts += `${product['name']} (${product['totalAmount']}), `;
        });
        order['productNameComposition'] = stringOfProducts.substring(
          0,
          stringOfProducts.length - 2,
        );

        // wrangle out item composition
        const itemsArray = order['itemIdComposition'];
        let stringOfIds = '';
        itemsArray.forEach(item => {
          stringOfIds += `id:${item['itemId']} (${item['totalAmount']}), `;
        });
        order['itemIdComposition'] = stringOfIds.substring(
          0,
          stringOfIds.length - 2,
        );

        order['donationAmount'] = order['donationAmount'] / 100;

        cleanedOrders.push(order);
      });
      return cleanedOrders;
    };

    await receiveDonationOrdersData(startDate, endDate).then(res => {
      const allOrders = res['data'];
      let ordersArrayA: any[] = [{}];
      let ordersArrayB: any[] = [{}];
      let ordersArrayC: any[] = [{}];
      if (allOrders['A']) {
        ordersArrayA = wrangleOrdersData(allOrders['A']);
      }
      if (allOrders['B']) {
        ordersArrayB = wrangleOrdersData(allOrders['B']);
      }
      if (allOrders['C']) {
        ordersArrayC = wrangleOrdersData(allOrders['C']);
      }
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        workbook,
        XLSX.utils.json_to_sheet(ordersArrayA),
        'Donation Orders - V1',
      );
      XLSX.utils.book_append_sheet(
        workbook,
        XLSX.utils.json_to_sheet(ordersArrayB),
        'Donation Orders - V2',
      );
      XLSX.utils.book_append_sheet(
        workbook,
        XLSX.utils.json_to_sheet(ordersArrayC),
        'Donation Orders - V3',
      );
      XLSX.writeFile(workbook, 'donation_orders.xlsx');
    });
  };

  const downloadOrderItemsData = async () => {
    if (!validateDates()) {
      return;
    }
    await receiveOrderItemsData(startDate, endDate).then(res => {
      const allOrders = res['data'];
      let orderItemsArray: any[] = [];
      for (const [variant, orders] of Object.entries(allOrders)) {
        const ordersObject = orders as Object;
        for (const [id, details] of Object.entries(ordersObject)) {
          let detailsObject = details as Object;
          detailsObject = { variant: variant, id: id, ...detailsObject };
          detailsObject['price'] = detailsObject['price'] / 100;
          detailsObject['totalDonation'] = detailsObject['totalDonation'] / 100;
          orderItemsArray.push(detailsObject);
        }
      }
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        workbook,
        XLSX.utils.json_to_sheet(orderItemsArray),
        'Order Items',
      );
      XLSX.writeFile(workbook, 'order_items.xlsx');
    });
  };

  const downloadDonorData = async () => {
    if (!validateDates()) {
      return;
    }
    await receiveDonorListData().then(res => {
      const allDonors = res['data'];
      let donorDataArray: any[] = [];
      allDonors.forEach(donorObj => {
        // wrangle out top three products
        const topThreeProductsArray: any[] = donorObj['topThreeProducts'];
        let stringOfProducts = '';
        topThreeProductsArray.forEach(product => {
          for (const [name, count] of Object.entries(product)) {
            stringOfProducts += `${name} (${count}), `;
          }
        });
        donorObj['topThreeProducts'] = stringOfProducts.substring(
          0,
          stringOfProducts.length - 2,
        );

        // wrangle out donationOrderIds
        const orderIdsArray: any[] = donorObj['donationOrderIds'];
        let stringOfIds = '';
        orderIdsArray.forEach(orderId => {
          stringOfIds += `${orderId}, `;
        });
        donorObj['donationOrderIds'] = stringOfIds.substring(
          0,
          stringOfIds.length - 2,
        );

        donorObj['lifeTimeValue'] = donorObj['lifeTimeValue'] / 100;
        donorObj['lastDonationAmount'] = donorObj['lastDonationAmount'] / 100;
        donorObj['averageDonationValue'] =
          donorObj['averageDonationValue'] / 100;

        donorDataArray.push(donorObj);
      });
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        workbook,
        XLSX.utils.json_to_sheet(donorDataArray),
        'Donor List',
      );
      XLSX.writeFile(workbook, 'donor_data.xlsx');
    });
  };

  const downloadHeartbeatData = async () => {
    if (!validateDates()) {
      return;
    }
    await receiveHeartbeatData(startDate, endDate).then(res => {
      const heartbeatArray = res['data'];
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        workbook,
        XLSX.utils.json_to_sheet(heartbeatArray),
        'Heartbeat Data',
      );
      XLSX.writeFile(workbook, 'heartbeat_data.xlsx');
    });
  };

  const downloadStatistics = async () => {
    if (!validateDates()) {
      return;
    }
    await receiveCalculatedData(startDate, endDate).then(res => {
      const calculatedData = res['data'];
      calculatedData['averageDonationPrice'] =
        calculatedData['averageDonationPrice'] / 100;
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        workbook,
        XLSX.utils.json_to_sheet([calculatedData]),
        'Calculated Data',
      );
      XLSX.writeFile(workbook, 'calculated_data.xlsx');
    });
  };

  const downloadFeedback = async () => {
    if (!validateDates()) {
      return;
    }
    listFeedback(startDate, endDate).then(res => {
      const data = res['data'].map(val => {
        const date = new Date(val.timestamp);
        const localDate = new Date(
          date.getTime() - date.getTimezoneOffset() * 60000,
        );
        return {
          ...val,
          timestamp: localDate.toUTCString(),
        };
      });
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        workbook,
        XLSX.utils.json_to_sheet(data),
        'Feedback',
      );
      XLSX.writeFile(workbook, 'ecommerce_feedback.xlsx');
    });
  };

  const downloadComboRecord = async () => {
    if (!validateDates()) {
      return;
    }
    console.log('Downloading Full Record Data269');
    // helper function
    const wrangleComboRecord= (arr: any[]) => {
      let cleanedRecords: any[] = [];
      arr.forEach(order => {
        const taskList: [string, string][] = [['productNameComposition', 'name'], 
                                              ['itemIdComposition', 'itemId'], 
                                              ['categoryComposition', 'category'], 
                                              ['priceComposition', 'price']];
        for (const [task, key ] of taskList) {
          const taskArray = order[task];
          let stringOfTasks = '';
          taskArray.forEach(taskItem => {
            stringOfTasks += `${taskItem[key]}: (${taskItem['totalAmount']}), `;
          });
          order[task] = stringOfTasks.substring(0, stringOfTasks.length - 2);
        }

        order['donationAmount'] = order['donationAmount'] / 100;

        cleanedRecords.push(order);
      });
      return cleanedRecords;
    };
    console.log('Downloading Full Record Data293');

    await receiveFullRecordData(startDate, endDate).then(res => {
      const allOrders = res['data'];
      const variant_to_condition: { [var_name: string]: number } = {
        A: 1,
        B: 2,
        C: 3,
      };

      const workbook = XLSX.utils.book_new();
      for (const variant of ['A', 'B', 'C']) {
        let ordersArrayX: any[] = [];
        if (allOrders[variant]) {
          ordersArrayX = wrangleComboRecord(allOrders[variant]);
        }
        XLSX.utils.book_append_sheet(
          workbook,
          XLSX.utils.json_to_sheet(ordersArrayX),
          `Full Record - V${variant_to_condition[variant]}`,
        );
      }
      XLSX.writeFile(workbook, 'fullRecords.xlsx');
      console.log('Downloading Full Record Data316');
    });
  };

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLES_ADMIN_PORTAL.homePage}</title>
        <meta name="description" content={PAGE_TITLES_ADMIN_PORTAL.homePage} />
      </Helmet>
      <div className="flex flex-col max-w-sm w-[200px] mx-1 my-1">
        <div className="my-1">
          <p>Start Date for AB Data:</p>
          <DatePicker
            className="border-2"
            selected={startDate}
            onChange={(date: Date) => setStartDate(date)}
          />
        </div>
        <div className="my-1">
          <p>End Date for AB Data:</p>
          <DatePicker
            className="border-2"
            selected={endDate}
            onChange={(date: Date) => setEndDate(date)}
          />
        </div>
        {invalidDate ? (
          <p className="text-red-400 mb-1">Dates are not valid.</p>
        ) : (
          ''
        )}
        <div className="flex">
          <div className="flex flex-row justify-end pr-1 py-1">
            <ButtonComponent type="button" className="w-32 font-normal text-sm">
              <a onClick={downloadOrderData}>Orders</a>
            </ButtonComponent>
          </div>
          <div className="flex flex-row justify-end p-1">
            <ButtonComponent type="button" className="w-32 font-normal text-sm">
              <a onClick={downloadOrderItemsData}>Products</a>
            </ButtonComponent>
          </div>
          <div className="flex flex-row justify-end p-1">
            <ButtonComponent type="button" className="w-32 font-normal text-sm">
              <a onClick={downloadDonorData}>Donors</a>
            </ButtonComponent>
          </div>
          <div className="flex flex-row justify-end p-1">
            <ButtonComponent type="button" className="w-32 font-normal text-sm">
              <a onClick={downloadHeartbeatData}>Session</a>
            </ButtonComponent>
          </div>
          <div className="flex flex-row justify-end p-1">
            <ButtonComponent type="button" className="w-32 font-normal text-sm">
              <a onClick={downloadStatistics}>Statistics</a>
            </ButtonComponent>
          </div>
          <div className="flex flex-row justify-end p-1">
            <ButtonComponent type="button" className="w-32 font-normal text-sm">
              <a onClick={downloadFeedback}>Feedback</a>
            </ButtonComponent>
          </div>
          <div className="flex flex-row justify-end p-1">
            <ButtonComponent type="button" className="w-32 font-normal text-sm">
              <a onClick={downloadComboRecord}>FullRecord</a>
            </ButtonComponent>
          </div>
        </div>
      </div>
    </>
  );
}
