import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import * as React from 'react';

interface IInputDropdownSelectProps {
  title: string;
  options: { label: string; value: any }[];
  onChange: (selected: any) => void;
  value?: any;
}

export function InputDropdown({
  title,
  onChange,
  options,
  value,
}: IInputDropdownSelectProps) {
  const handleChange = e => {
    const selected = e.target.value;
    onChange(selected);
  };
  return (
    <div className="w-5/6 sm:w-3/6 grid grid-cols-4 gap-4 py-4">
      <div className="text-right col-span-1">{title}</div>
      <div className="col-span-3">
        <Select
          id="select"
          onChange={handleChange}
          style={{ width: '100%', height: '40px' }}
          value={value}
        >
          {options.map(option => {
            return (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            );
          })}
        </Select>
      </div>
    </div>
  );
}
