import { getSessionId, sendDataPacket } from 'app/api/abService/abapi';
import { ICartItem } from 'app/models/CartItem';
import { useAuthValue } from 'app/AuthContext';
import { Header } from 'app/components/Ecommerce/Header';
import { VariantSelectMenu } from 'app/components/Ecommerce/SelectMenu';
import { ABExperiment } from 'app/pages/ABDemoPage/components/ABExperiment';
import { FooterLogoComponent } from 'app/pages/ECommerce/Landing/HomePage/components/FooterLogoComponent';
import { cartDefaultKey, cartState } from 'app/state/shoppingCart/atoms';
import { VariantContext, VariantIds } from 'app/VariantContext';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { v4 as uuidv4 } from 'uuid';
import RateReviewIcon from '@mui/icons-material/RateReview';
import { FeedbackFormModalComponent } from 'app/components/FeedbackFormModal/Loadable';
import Avatar from '@mui/material/Avatar';
import { Button } from 'app/components/Buttons/Button';
export interface IECommerceLayout {
  children: any;
  showHeader?: boolean;
  showFooter?: boolean;
}

export const EcommerceLayout: React.FC<IECommerceLayout> = props => {
  const { currentUser, currentUserId } = useAuthValue()!;
  const [userToken, setUserToken] = useState<string>('');
  const [isFeedbackFormModalOpen, setIsFeedbackFormModalOpen] =
    React.useState(false);
  const [isFeedbackFormFinishedModalOpen, setIsFeedbackFormFinishedModalOpen] =
    React.useState(false);
  const cartItems: ICartItem[] = useRecoilValue(
    cartState(currentUserId || cartDefaultKey),
  );
  const location = useLocation();

  // a not so elegant way to do ab testing to track clicks
  let clicks = 0;

  useEffect(() => {
    // set app tracker for total clicks
    document.addEventListener('click', () => {
      clicks++;
    });

    // wrapper function to call async await functions in useEffect
    const wrapper = async () => {
      await getSessionId();
    };

    wrapper();
  }, []);

  useEffect(() => {
    const interval = setInterval(async () => {
      await sendDataPacket(clicks);
    }, 60000); // 60000 for 1 minute
    clicks = 0;
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (currentUser) {
      currentUser
        .getIdToken()
        .then(userIdToken => {
          setUserToken(userIdToken);
        })
        .catch(err => {
          console.error(err);
        });
    }
  }, [currentUser]);

  const BaseComponent = (
    <div className="flex flex-col w-screen h-screen">
      {props.showHeader && (
        <Header
          cartContent={cartItems.reduce(
            (acc: number, cur: ICartItem) => acc + cur.cartQuantity,
            0,
          )}
        />
      )}
      <main className="mt-[118px] prose">{props.children}</main>
      {/* {props.showFooter && <FooterComponent />} */}
      {props.showFooter && <FooterLogoComponent />}
    </div>
  );
  const FeedBackComponent = (
    <div className="fixed z-[100] bottom-5 right-5">
      {/* <Avatar
        className="bg-primary cursor-pointer"
        style={{ width: '80px', height: '80px', background: '#80B519' }}
      > */}
      <button
        type="button"
        className="h-20 w-20 tracking-wide text-white transition duration-200 rounded-full shadow-md bg-primary hover:bg-orange border-4 border-white"
        onClick={() => setIsFeedbackFormModalOpen(true)}
      >
        <RateReviewIcon
          className="m-1"
          style={{ color: 'white', width: '50px', height: '50px' }}
        />
      </button>

      {/* </Avatar> */}
    </div>
  );

  return (
    <div className="container ">
      <ABExperiment
        name="AB Test"
        variants={[
          {
            name: 'Variant A',
            component: (
              <VariantContext.Provider value={VariantIds.A}>
                <>
                  {BaseComponent}
                  {/* <div className="fixed z-[100] bottom-28 right-5">
                    <VariantSelectMenu selected={0} showAbove />
                  </div> */}
                  {FeedBackComponent}
                </>
              </VariantContext.Provider>
            ),
          },
          {
            name: 'Variant B',
            component: (
              <VariantContext.Provider value={VariantIds.B}>
                <>
                  {BaseComponent}
                  {/* <div className="fixed z-[100] bottom-28 right-5">
                    <VariantSelectMenu selected={1} showAbove />
                  </div> */}
                  {FeedBackComponent}
                </>
              </VariantContext.Provider>
            ),
          },
          {
            name: 'Variant C',
            component: (
              <VariantContext.Provider value={VariantIds.C}>
                <>
                  {BaseComponent}
                  {/* <div className="fixed z-[100] bottom-28 right-5">
                    <VariantSelectMenu selected={2} showAbove />
                  </div> */}
                  {FeedBackComponent}
                </>
              </VariantContext.Provider>
            ),
          },
        ]}
        sessionId={uuidv4()}
        userIdentifier={currentUser ? `user-${userToken}` : `anon-${uuidv4()}`}
      />
      <FeedbackFormModalComponent
        showState={isFeedbackFormModalOpen}
        showStateController={setIsFeedbackFormModalOpen}
        showStateCallBack={() => setIsFeedbackFormFinishedModalOpen(true)}
      />
    </div>
  );
};
