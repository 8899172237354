import './CartItem.css';

import {
  DeleteFilled,
  MinusCircleFilled,
  PlusCircleFilled,
} from '@ant-design/icons';
import { useAuthValue } from 'app/AuthContext';
import { ProductModalComponent } from 'app/components/Ecommerce/Cards/ProductCard/components/ProductModal/Loadable';
import { ICartItem } from 'app/models/CartItem';
import { IUsesCartManipulatorFuncs } from 'app/pages/ECommerce/Shop/ShoppingCartPage/components/ShoppingCart/Cart';
import { cartDefaultKey } from 'app/state/shoppingCart/atoms';
import { useChangeQuantityCart } from 'app/state/shoppingCart/hooks';
import { IMAGE_PRODUCT } from 'app/util/constants/apiEndpoints/ProductEndpoints';
import React from 'react';

interface ICartItemProps extends IUsesCartManipulatorFuncs {
  item: ICartItem;
}

export const CartItem: React.FC<ICartItemProps> = ({
  item,
  addToCart,
  removeFromCart,
}) => {
  const { currentUserId } = useAuthValue()!;
  const changeCartQuantity = useChangeQuantityCart(
    currentUserId || cartDefaultKey,
  );
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const cancelButtonRef = React.useRef(null);

  return (
    <>
      <div className="flex flex-col xs:flex-row sm:flex-row md:flex-row gap-2 sm:gap-3 p-3 border border-neutral-500 rounded-2xl">
        <div className="flex mx-auto h-[150px] w-[150px] aspect-1 max-h-[150px] max-w-[150px]">
          {item.images ? (
            <img
              src={IMAGE_PRODUCT(item.id, item.images[0])}
              alt={item.description}
              className="object-cover"
            />
          ) : null}
        </div>

        <div className="flex flex-col justify-between grow basis-10/12">
          <div className="flex-col grow">
            <div className="flex justify-between text-sm xs:text-lg s:text-lg md:text-lg">
              <h3
                className="font-bold underline cursor-pointer"
                onClick={() => setIsModalOpen(true)}
              >
                {item.name}
              </h3>
              <div className="whitespace-nowrap">
                <p className="text-dark-green text-sm">
                  SGD {(item.cartQuantity * item.price).toFixed(2)}
                </p>
              </div>
            </div>
            <span className="text-xs">SGD {item.price.toFixed(2)}</span>
          </div>
          <div className="flex flex-row items-center justify-between">
            <div className="flex gap-4">
              <MinusCircleFilled
                onClick={() => removeFromCart(item, 1)}
                className="my-auto text-white text-[28px] border-primary bg-primary border rounded-full h-full"
              />
              <input
                type="number"
                className="text-sm text-primary text-center border rounded-xl w-[90px]"
                value={item.cartQuantity}
                onChange={e => {
                  if (e.target.valueAsNumber > 0) {
                    changeCartQuantity(item, e.target.valueAsNumber);
                  }
                }}
              />
              <PlusCircleFilled
                onClick={() => addToCart(item, 1)}
                className="my-auto text-primary text-[28px] border-primary border rounded-full h-full"
              />
            </div>
            <DeleteFilled
              className="text-[18px] text-[#374151]"
              onClick={() => removeFromCart(item, item.cartQuantity)}
            />
          </div>
        </div>
      </div>
      <ProductModalComponent
        title={item.name}
        imgURLs={item.images ? [IMAGE_PRODUCT(item.id, item.images[0])] : []}
        showState={isModalOpen}
        showStateController={setIsModalOpen}
        cancelButtonRef={cancelButtonRef}
        price={item.price ? item.price : 0}
        quantity={1}
        productId={item.id ? item.id : 0}
        addToCart={addToCart}
        product={item}
      />
    </>
  );
};
