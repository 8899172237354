import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';

export const AdminItemPaginationComponent: React.FC<{
  totalPages: number;
  onPageChange: Function;
}> = ({ totalPages, onPageChange }) => {
  const [pages, setPages] = useState<number[]>();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const pageClickHandler = page => {
    setCurrentPage(page);
  };

  // update the number of pages if total pages changes
  useEffect(() => {
    setCurrentPage(1);
    let _newPages: number[] = [];
    for (let i = 0; i < totalPages; i++) {
      _newPages.push(i + 1);
    }
    setPages(_newPages);
  }, [totalPages]);

  // propagate the current page upwards
  useEffect(() => {
    onPageChange(currentPage);
  }, [currentPage]);

  return (
    <>
      <div className="flex flex-row gap-1">
        <div
          className="bg-slate-100 px-3 py-2 border rounded cursor-pointer flex items-center"
          onClick={
            currentPage > 1 ? () => pageClickHandler(currentPage - 1) : () => {}
          }
        >
          <LeftOutlined></LeftOutlined>
        </div>
        {pages &&
          pages.map(page => (
            <>
              <div
                className={
                  'bg-slate-100 px-4 py-2 border rounded cursor-pointer ' +
                  (currentPage === page
                    ? 'border-primary text-primary'
                    : 'border-slate-400')
                }
                onClick={() => pageClickHandler(page)}
              >
                {page}
              </div>
            </>
          ))}
        <div
          className="bg-slate-100 px-3 py-2 border rounded cursor-pointer flex items-center"
          onClick={
            currentPage < totalPages
              ? () => pageClickHandler(currentPage + 1)
              : () => {}
          }
        >
          <RightOutlined></RightOutlined>
        </div>
      </div>
    </>
  );
};
