import { VariantIds } from '../../../VariantContext';

interface adminPortalPageUrlStrings {
  accountManagement: {
    loginPage: string;
    logoutPage: string;
    profilePage: string;
    deleteAccountPage: string;
    deactivateAccountPage: string;
    createUserPage: string;
    updateAccountPage: (uid: string) => string;
    accountsPage: string;
    forgetPasswordPage: string;
    firstLoginPage: string;
    updatePasswordPage: string;
    updateEmailPage: string;
  };
  productManagement: {
    createPage: (variantId: VariantIds) => string;
    deletePage: string;
    exportCataloguePage: (variantId: VariantIds) => string;
    updatePage: (variantId: VariantIds, itemId: number) => string;
    uploadPage: (variantId: VariantIds) => string;
    variantUpdatePage: (id: string) => string;
    viewPage: (variantId: VariantIds) => string;
  };
  categoryManagement: {
    createPage: (variantId: VariantIds) => string;
    viewPage: (variantId: VariantIds) => string;
    updatePage: (variantId: VariantIds, id: string) => string;
  };
  errorPage: string;
  homePage: string;
  dashboard: {
    viewPage: (variantId: VariantIds) => string;
  };
}

export const PAGE_URL_ADMIN_PORTAL: adminPortalPageUrlStrings = {
  accountManagement: {
    accountsPage: '/admin/accounts/',
    loginPage: '/admin/accounts/login',
    logoutPage: '/admin/accounts/logout',
    profilePage: '/admin/accounts/profile',
    deleteAccountPage: '/admin/accounts/delete',
    deactivateAccountPage: '/admin/accounts/deactivate',
    createUserPage: '/admin/accounts/create',
    updateAccountPage: uid => `/admin/accounts/update/${uid}`,
    forgetPasswordPage: '/admin/accounts/forgetpassword',
    firstLoginPage: '/admin/accounts/login/first/:encodedUid',
    updatePasswordPage: '/admin/accounts/profile/password',
    updateEmailPage: '/admin/accounts/profile/email',
  },
  productManagement: {
    createPage: variantId => `/admin/products/${variantId}/create`,
    deletePage: '/admin/products/delete',
    exportCataloguePage: variantId => `/admin/products/${variantId}/export`,
    updatePage: (variantId, itemId) =>
      `/admin/products/${variantId}/update/${itemId}`,
    uploadPage: variantId => `/admin/products/${variantId}/import`,
    variantUpdatePage: id => `/admin/products/variant/update/${id}`,
    viewPage: variantId => `/admin/products/${variantId}/view`,
  },
  categoryManagement: {
    createPage: variantId => `/admin/categories/${variantId}/create`,
    viewPage: variantId => `/admin/categories/${variantId}`,
    updatePage: (variantId, id) =>
      `/admin/categories/${variantId}/update/${id}`,
  },
  homePage: '/admin',
  errorPage: '/admin/404',
  dashboard: {
    viewPage: variantId => `/admin/dashboard/${variantId}`,
  },
};
