import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';

export const ProductPaginationComponent: React.FC<{
  totalPages: number;
  onPageChange: Function;
}> = ({ totalPages, onPageChange }) => {
  const [pages, setPages] = useState<number[]>();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const pageClickHandler = page => {
    setCurrentPage(page);
  };

  // update the number of pages if total pages changes
  useEffect(() => {
    setCurrentPage(1);
    let _newPages: number[] = [];
    for (let i = 0; i < totalPages; i++) {
      _newPages.push(i + 1);
    }
    setPages(_newPages);
  }, [totalPages]);

  // propagate the current page upwards
  useEffect(() => {
    onPageChange(currentPage);
  }, [currentPage, onPageChange]);

  return (
    <>
      <div className="flex flex-row gap-2 justify-center">
        <div
          className="bg-primary text-white cursor-pointer flex flex-col justify-center p-4 rounded"
          onClick={
            currentPage > 1 ? () => pageClickHandler(currentPage - 1) : () => {}
          }
        >
          <LeftOutlined></LeftOutlined>
        </div>
        {pages &&
          pages.map((page, idx) => (
            <div
              key={idx}
              className={
                'bg-primary text-white cursor-pointer flex flex-col justify-center px-4 py-3 rounded ' +
                (currentPage === page ? '!bg-orange' : '')
              }
              onClick={() => pageClickHandler(page)}
            >
              {page}
            </div>
          ))}
        <div
          className="bg-primary text-white cursor-pointer flex flex-col justify-center p-4 rounded"
          onClick={
            currentPage < totalPages
              ? () => pageClickHandler(currentPage + 1)
              : () => {}
          }
        >
          <RightOutlined></RightOutlined>
        </div>
      </div>
    </>
  );
};
