import { CustomBreadcrumb } from 'app/components/Navigation/BreadCrumbs';
import { ExportCatalogueForm } from 'app/pages/Admin/Product/View/ExportProductPage/components/ExportCatalogueForm';
import { PAGE_TITLES_ADMIN_PORTAL } from 'app/util/constants/pageTitles/adminPortal';
import { PAGE_URL_ADMIN_PORTAL } from 'app/util/constants/pageUrls/adminPortal';
import { normaliseVariantId, VariantIds } from 'app/VariantContext';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

export function ExportProductsPage() {
  const params: { variantId: VariantIds } = useParams();
  return (
    <>
      <Helmet>
        <title>
          {PAGE_TITLES_ADMIN_PORTAL.productManagement.exportCataloguePage}
        </title>
        <meta
          name="description"
          content={
            PAGE_TITLES_ADMIN_PORTAL.productManagement.exportCataloguePage
          }
        />
      </Helmet>
      <CustomBreadcrumb
        data={[
          {
            label: `Variant ${normaliseVariantId(params.variantId)}`,
            link: '/admin',
          },
          {
            label: `Product Management`,
            link: PAGE_URL_ADMIN_PORTAL.productManagement.viewPage(
              params.variantId,
            ),
          },
          {
            label: 'Export Porducts',
            link: PAGE_URL_ADMIN_PORTAL.productManagement.exportCataloguePage(
              params.variantId,
            ),
            isCurrentPage: true,
          },
        ]}
      />
      <ExportCatalogueForm variantId={params.variantId} />
    </>
  );
}
