import { useAuthValue } from 'app/AuthContext';
import { CheckoutStepComponent } from 'app/components/Ecommerce/Payment/CheckoutStep/Loadable';
import { Cart } from 'app/pages/ECommerce/Shop/ShoppingCartPage/components/ShoppingCart/Cart';
import { cartDefaultKey, cartState } from 'app/state/shoppingCart/atoms';
import { useAddToCart, useRemoveFromCart } from 'app/state/shoppingCart/hooks';
import { PAGE_TITLES_ECommerce } from 'app/util/constants/pageTitles/ecommerce';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useRecoilValue } from 'recoil';

export function ShoppingCartPage() {
  const { currentUserId } = useAuthValue()!;
  const cartItems = useRecoilValue(cartState(currentUserId || cartDefaultKey));
  const addToCart = useAddToCart(currentUserId || cartDefaultKey);
  const removeFromCart = useRemoveFromCart(currentUserId || cartDefaultKey);

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLES_ECommerce.shoppingCartPage}</title>
        <meta
          name="description"
          content={PAGE_TITLES_ECommerce.shoppingCartPage}
        />
      </Helmet>
      <div className="h-screen" style={{ backgroundColor: '#009100' }}>
        <div
          className="flex flex-col justify-center p-4 bg-repeat lg:p-8 xl:p-12"
          style={{ backgroundColor: '#009100' }}
        >
          <div className="h-full px-4 pb-3 md:py-4 my-5 bg-white/80 rounded-xl lg:p-8 lg:mx-5 xl:p-12 xl:mx-5">
            <div className="grid h-full grid-cols-12 gap-2 md:gap-4 overflow-hidden lg:gap-4 xl:gap-8">
              <div className="row-span-1 col-span-12 md:col-span-4 lg:col-span-3 xl:col-span-2">
                <CheckoutStepComponent stage="Cart" />
              </div>
              <div className="col-span-12 md:order-3 lg:order-2 md:col-span-8 lg:col-span-9 xl:col-span-10">
                <Cart
                  cartItems={cartItems}
                  addToCart={addToCart}
                  removeFromCart={removeFromCart}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
