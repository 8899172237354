import { Experiment, Variant } from '@marvelapp/react-ab-test';
import { HEARTBEAT } from 'app/pages/ABDemoPage/components/ABExperiment/constants';
import React from 'react';

interface IABVariantProps {
  name: string;
  component: React.ReactNode;
}

interface IABExperimentProps {
  name: string;
  variants: IABVariantProps[];
  sessionId: string;
  userIdentifier: string;
}

export const ABExperiment: React.FC<IABExperimentProps> = ({
  name,
  variants,
  sessionId,
  userIdentifier,
}) => {
  // React.useEffect(() => {
  //   const interval = setInterval(async () => {
  //     //await sendDataPacket();
  //   }, HEARTBEAT);
  //   return () => clearInterval(interval);
  // }, []);

  return (
    <Experiment name={name}>
      {variants &&
        variants.map(variant => (
          <Variant name={variant.name} key={variant.name}>
            {variant.component}
          </Variant>
        ))}
    </Experiment>
  );
};
