const PRODUCT_ROOT_URL = '/product';

const CREATE_PRODUCT = () => PRODUCT_ROOT_URL;
const CREATE_MANY_PRODUCTS = () => PRODUCT_ROOT_URL + '/products';
const IMPORT_PRODUCTS = () => PRODUCT_ROOT_URL + '/import';

const GET_PRODUCT = (id: number) => PRODUCT_ROOT_URL + `/${id}`;
const LIST_PRODUCT = () => PRODUCT_ROOT_URL;
const SEARCH_PRODUCT = () => PRODUCT_ROOT_URL + '/search';

const UPDATE_PRODUCT = (id: number) => PRODUCT_ROOT_URL + `/${id}`;
const UPDATE_MANY_PRODUCTS = () => PRODUCT_ROOT_URL + '';

const DELETE_PRODUCT = (id: number) => PRODUCT_ROOT_URL + `/${id}`;

const IMAGE_PRODUCT = (id: number, imageName?: string) =>
  `${process.env.REACT_APP_S3_URL}uploads` +
  (imageName ? `/product/${id}/${imageName}` : `/mart-for-good-logo.jpeg`);

const EXPORT_PRODUCT = () => PRODUCT_ROOT_URL + '/export';

export {
  CREATE_PRODUCT,
  CREATE_MANY_PRODUCTS,
  GET_PRODUCT,
  LIST_PRODUCT,
  UPDATE_PRODUCT,
  UPDATE_MANY_PRODUCTS,
  DELETE_PRODUCT,
  IMPORT_PRODUCTS,
  SEARCH_PRODUCT,
  IMAGE_PRODUCT,
  EXPORT_PRODUCT,
};
