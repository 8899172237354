import { Breadcrumb } from 'antd';
import React from 'react';
import './styles.css';

export interface BreadcrumbsItem {
  label: string;
  link?: string;
  isCurrentPage?: boolean;
}

interface IBreadcrumbsProps {
  data: BreadcrumbsItem[];
}

export const CustomBreadcrumb: React.FC<IBreadcrumbsProps> = ({ data }) => {
  return (
    <div className="p-2">
      <Breadcrumb separator="/">
        {data.map(item => (
          <Breadcrumb.Item
            href={item.link}
            key={item.label}
            className={item.isCurrentPage ? 'current-page' : ''}
          >
            {item.label}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </div>
  );
};
