import axiosInstance from 'utils/axios';

// const USER_ENDPOINT = `${process.env.REACT_APP_PROXY_BACKEND_API_URL}/user`;
export const getAllUsers = async (token: string) => {
  try {
    const res = await axiosInstance.get(`/user`, {
      headers: {
        'x-admin-access': token,
      },
    });
    return res.data;
  } catch (err) {
    console.error(err);
  }
};

export const getAdminUserDetails = async (uid: string, token: string) => {
  try {
    const res = await axiosInstance.get(`/user/${uid}`, {
      headers: {
        'x-admin-access': token,
      },
    });
    return res.data;
  } catch (err) {
    console.error(err);
  }
};

export const createAdminUserDetails = async (token: string, jsonObj: any) => {
  try {
    const res = await axiosInstance.post(`/user`, jsonObj, {
      headers: {
        'x-admin-access': token,
      },
    });
    return res;
  } catch (err) {
    console.error(err);
  }
};

export const updateAdminUserDetails = async (
  uid: string,
  token: string,
  jsonObj: any,
) => {
  try {
    const res = await axiosInstance.put(`/user/${uid}`, jsonObj, {
      headers: {
        'x-admin-access': token,
      },
    });
    return res;
  } catch (err) {
    console.error(err);
  }
};

export const deleteAdminUser = async (uid: string, token: string) => {
  try {
    const res = await axiosInstance.delete(`/user/${uid}`, {
      headers: {
        'x-admin-access': token,
      },
    });
    return res;
  } catch (err) {
    console.error(err);
  }
};

export const deactivateUser = async (uid: string, token: string) => {
  try {
    const jsonObj = {
      deactivate: true,
    };
    const res = await axiosInstance.patch(`/user/${uid}`, jsonObj, {
      headers: {
        'x-admin-access': token,
      },
    });
    return res;
  } catch (err) {
    console.error(err);
  }
};
