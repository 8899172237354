import { DeleteForm } from 'app/pages/Admin/User/Delete/DeleteUserPage/components/DeleteForm';
import { PAGE_TITLES_ADMIN_PORTAL } from 'app/util/constants/pageTitles/adminPortal';
import React from 'react';
import { Helmet } from 'react-helmet-async';

export function DeleteUserPage() {
  return (
    <>
      <Helmet>
        <title>
          {PAGE_TITLES_ADMIN_PORTAL.accountManagement.deleteAccountPage}
        </title>
        <meta
          name="description"
          content={PAGE_TITLES_ADMIN_PORTAL.accountManagement.deleteAccountPage}
        />
      </Helmet>
      <DeleteForm />
    </>
  );
}
