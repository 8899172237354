import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import { InputComponent } from 'app/components/Forms/Input/Loadable';
import React, { useEffect, useState } from 'react';

export const AdminItemSearchComponent: React.FC<{
  onSearchChange: Function;
}> = ({ onSearchChange }) => {
  const [currentType, setCurrentType] = useState<{
    value: string;
    label: string;
  }>({ value: 'name', label: 'Product Name' });
  const [currentText, setCurrentText] = useState<string>();
  const [isTypeOptionsOpen, setIsTypeOptionsOpen] = useState<boolean>(false);

  const types = [
    { value: 'name', label: 'Product Name' },
    { value: 'sku_code', label: 'Warehouse SKU Code' },
  ];

  // Change The Type of The Search and Close the Pop-up Menu
  const changeTypeHandler = type => {
    setCurrentType(type);
    setIsTypeOptionsOpen(false);
  };

  // Change the Search Text
  const changeTextHandler = e => {
    setCurrentText(e.target.value);
  };

  useEffect(() => {
    // Delay emit of data of change in text
    const timeOut = setTimeout(() => {
      onSearchChange({ type: currentType.value, text: currentText });
    }, 500);

    return () => clearTimeout(timeOut);
  }, [currentType, currentText]);
  return (
    <>
      <div className="flex flex-between w-full">
        <div className="flex flex-row grow items-center w-full gap-4">
          <SearchOutlined></SearchOutlined>
          <div className="flex flex-row items-center w-full">
            <div className="px-4 py-1 w-[200px] bg-dark-blue h-full text-white border border-neutral-500 relative">
              <div
                className="flex flex-row justify-between items-center h-full "
                onClick={() => setIsTypeOptionsOpen(true)}
              >
                <span className="text-sm leading-5.5 my-0.5">
                  {currentType.label}
                </span>
                <DownOutlined></DownOutlined>
              </div>
              {isTypeOptionsOpen && (
                <div
                  className={
                    isTypeOptionsOpen
                      ? 'absolute bg-neutral-200 text-black top-0 left-0 w-full '
                      : 'none '
                  }
                >
                  {types.map(type => (
                    <div
                      className={
                        'z-10 shadow-md hover:bg-dark-blue hover:text-white px-4 py-1.5 ' +
                        (currentType.value === type.value
                          ? 'bg-dark-blue text-white'
                          : '')
                      }
                      onClick={() => changeTypeHandler(type)}
                    >
                      {type.label}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="w-1/2">
              <InputComponent
                placeholder="Search..."
                className="rounded-none !p-1 border-neutral-500"
                onChange={changeTextHandler}
              ></InputComponent>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
