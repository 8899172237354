import React, { ChangeEventHandler } from 'react';
import {
  isFileSizeValid,
  isImageBigger,
} from 'app/util/validators/FileValidators';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
interface IImageSectionProps {
  imageFile: any;
  setImageFile: React.Dispatch<any>;
  imageUrl: string;
  setImageUrl: React.Dispatch<string>;
  errMessage?: string;
}

// the actual image upload component
export const ImageUploadComponent: React.FC<IImageSectionProps> = ({
  imageFile,
  setImageFile,
  imageUrl,
  setImageUrl,
  errMessage,
}) => {
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(
    errMessage,
  );

  const onImageUploadHandler = async e => {
    const file = e.target.files[0];

    // check if the image is at least 100 x 100 pixels and Max File Size is 16mb
    if (!isFileSizeValid(file, 1600000)) {
      setErrorMessage('Choose an image that is smaller than 16mb');
      return;
    }

    if (!(await isImageBigger(file, 100, 100))) {
      setErrorMessage('Choose a larger image than 100px * 100px');
      return;
    }
    const uploadedImage = e.target.files[0];
    setImageFile(uploadedImage);
    setImageUrl(URL.createObjectURL(uploadedImage));
  };

  const onImageDeleteHandler = () => {
    setImageFile(undefined);
    setImageUrl('');
  };

  const UploadComponent: React.FC<{ onUpload: ChangeEventHandler }> = ({
    onUpload,
  }) => {
    return (
      <>
        <div className="bg-slate-100 aspect-[1/1] flex flex-col justify-center text-center cursor-pointer border-dotted border border-slate">
          <div className="flex flex-col">
            <label className="flex flex-col p-4 font-poppins gap-2 cursor-pointer">
              <PlusOutlined className="text-xs"></PlusOutlined>
              <div className="text-xs ">Upload</div>
              {errorMessage && (
                <div className="text-error text-xs">{errorMessage}</div>
              )}
              <input
                type="file"
                accept="image/*"
                className="hidden"
                onChange={onUpload}
              ></input>
            </label>
          </div>
        </div>
      </>
    );
  };

  interface ImageProp {
    imageIndex: number;
    imageUrl: string;
    onDelete: Function;
  }

  const ImageComponent: React.FC<ImageProp> = ({
    onDelete,
    imageIndex,
    imageUrl,
  }) => {
    return (
      <>
        <div className="bg-slate-100 aspect-[1/1] flex flex-col justify-center text-center cursor-pointer border-solid border border-slate">
          <>
            <div
              className="image-container h-full w-full relative p-2"
              onClick={() => onDelete(imageIndex)}
            >
              <img className="h-full w-full object-cover" src={imageUrl} />
              <div className="image-hover absolute top-0 left-0 w-full h-full hidden">
                <DeleteOutlined className="text-white"></DeleteOutlined>
              </div>
            </div>
          </>
        </div>
      </>
    );
  };
  return (
    <>
      <div className="grid grid-cols-[1fr_2fr] gap-x-4 items-center">
        <div className="justify-self-end">
          Image:<span className="text-error">*</span>
        </div>
        <div className="flex flex-col gap-2 w-full">
          <div className="w-24">
            {imageUrl && (
              <ImageComponent
                key={0}
                imageIndex={0}
                imageUrl={imageUrl}
                onDelete={onImageDeleteHandler}
              />
            )}
            {!imageUrl && (
              <UploadComponent
                onUpload={onImageUploadHandler}
              ></UploadComponent>
            )}
          </div>
          {errMessage && <div className="text-[#FF4D4F]">{errMessage}</div>}
          <div>Upload at most 1 picture for the category.</div>
        </div>
      </div>
    </>
  );
};
